import { render, staticRenderFns } from "./cover-pic.vue?vue&type=template&id=746caaec&scoped=true&"
import script from "./cover-pic.vue?vue&type=script&lang=js&"
export * from "./cover-pic.vue?vue&type=script&lang=js&"
import style0 from "./cover-pic.vue?vue&type=style&index=0&id=746caaec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "746caaec",
  null
  
)

export default component.exports