import { render, staticRenderFns } from "./request-comp.vue?vue&type=template&id=ee23abd2&scoped=true&"
import script from "./request-comp.vue?vue&type=script&lang=js&"
export * from "./request-comp.vue?vue&type=script&lang=js&"
import style0 from "./request-comp.vue?vue&type=style&index=0&id=ee23abd2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee23abd2",
  null
  
)

export default component.exports