<template>
    <div id="chat-container">
        <h2 id="logo">Sandesha</h2>
        <menu-comp/>
    </div>
</template>

<script>
import MenuComp from '/src/components/home/head-comp.vue'
export default {
    components:{
        MenuComp
    }
}
</script>

<style scoped>
#chat-container{
    width:100vw;
}
#logo{
    width: 87px;
    height: 33px;
    margin-left: 6px;
    margin-top: 7px;
    
    font-family: 'Sofia';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 33px;
    background: linear-gradient(95.76deg, #1708C2 2.71%, rgba(147, 12, 174, 0.94825) 54.21%, rgba(233, 8, 89, 0.89) 112.18%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
</style>