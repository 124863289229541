<template>
    <div style="margin-top:6px;">
     <h2 style="text-align:center;font-family:'Sofia'">Hobbies Analysis</h2>
     <Bar
     id="my-chart-id"
     :options="chartOptions"
     :data="chartData"
     
   />
    </div>
   </template>
   
   <script>
   import { Bar } from 'vue-chartjs'
   import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
   
   ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
   
   export default {
     name: 'BarChart',
     components: { Bar },
     computed:{
      chartData(){
        return {
           
           datasets: [ { 
             label: 'Hobby Analysis',
             data: this.$store.getters['user/get_hobby_eval1'],
             backgroundColor: [
       'rgba(255, 99, 132, 0.6)',
       'rgba(255, 159, 64, 0.6)',
       'rgba(255, 205, 86, 0.6)',
       'rgba(75, 192, 192, 0.6)',
       'rgba(54, 162, 235, 0.6)',
       'rgba(153, 102, 255, 0.6)',
       'rgba(201, 203, 207, 0.6)'
     ],
     borderColor: [
       'rgb(255, 99, 132)',
       'rgb(255, 159, 64)',
       'rgb(255, 205, 86)',
       'rgb(75, 192, 192)',
       'rgb(54, 162, 235)',
       'rgb(153, 102, 255)',
       'rgb(201, 203, 207)'
     ]
           } ]
         }
      }
     },
     data() {
       return {
         chartOptions: {
           responsive: true
         }
       }
     }
   }
   </script>