
import { getStorage, ref, listAll,getDownloadURL } from "firebase/storage";
import { db } from '/src/firebase.js'
import { collection, query, where, getDocs} from "firebase/firestore";

export default{
    async getSongs({commit},payload){
        try{
            const storage = getStorage();
        const listRef = ref(storage, `songs/${payload.value}`);
        let songs = await listAll(listRef)
        songs.items.forEach(async item=>{
            let result = await item.fullPath.split('/')[2]
            let url =  await getDownloadURL(ref(storage,`songs/${payload.value}/${result}`))
            commit('setSong',{value:url})
        })
        }
        catch(err){
            console.log(err)
        }
    },

    async getImages({commit},payload){
        try{
        const storage = getStorage();
        const listRef = ref(storage, `bgs/${payload.value}`);
        let bgs = await listAll(listRef)
        bgs.items.forEach(async item=>{
            let result = await item.fullPath.split('/')[2]
            let url =  await getDownloadURL(ref(storage,`bgs/${payload.value}/${result}`))
            commit('setPic',{value:url})
        })
        }
        catch(err){
            console.log(err)
        }
    },

    async getQuotes({commit},payload){
        let q = query(collection(db,'quotes'),where('for', '==', payload.value))
        let result = await getDocs(q)
        let quotes = result.docs[0].data()['quote']
        quotes.forEach(quote=>{
            commit('setQuote',{value:quote})
        })
    }
   
}