import PersonalityPage from '../views/personality/personality-test.vue'
import PersonalityLand from '../views/personality/personality-page.vue'
import MyersAbout from '../views/personality/myers-briggs/about-page.vue'
import MyersWhy from '../views/personality/myers-briggs/why-page.vue'
import MyersTest from '../views/personality/myers-briggs/test-page.vue'
import HexacoAbout from '../views/personality/hexaco/about-page.vue'
import HexacoWhy from '../views/personality/hexaco/why-page.vue'
import HexacoTest from '../views/personality/hexaco/test-page.vue'
import NeoAbout from '../views/personality/neo-analysis/about-page.vue'
import NeoWhy from '../views/personality/neo-analysis/why-page.vue'
import NeoTest from '../views/personality/neo-analysis/test-page.vue'
import eysenckAbout from '../views/personality/eysenck/about-page.vue'
import eysenckWhy from '../views/personality/eysenck/why-page.vue'
import eysenckTest from '../views/personality/eysenck/test-page.vue'
import DiscAbout from '../views/personality/disc_analysis/about-page.vue'
import DiscWhy from '../views/personality/disc_analysis/why-page.vue'
import DiscTest from '../views/personality/disc_analysis/test-page.vue'

const routes=[
    {
        name:'personality',
        path:'/personality',
        component:PersonalityPage
    },
    {
        name:'personalityLand',
        path:'/personality-land/:test',
        component:PersonalityLand
    },
    //myers briggs test
    {
    name:"aboutMyers-Briggs",
    path:'/about/Myers-Briggs',
    component:MyersAbout
},
{
    name:'whyMyers-Briggs',
    path:'/why/Myers-Briggs',
    component:MyersWhy
},
{
    name:'testMyers-Briggs',
    path:'/test/Myers-Briggs',
    component:MyersTest
},
//hexaco analysis
{
    name:"aboutHEXACO-Analysis",
    path:'/about/HEXACO-Analysis',
    component:HexacoAbout
},
{
    name:'whyHEXACO-Analysis',
    path:'/why/HEXACO-Analysis',
    component:HexacoWhy
},
{
    name:'testHEXACO-Analysis',
    path:'/test/HEXACO-Analysis',
    component:HexacoTest
},
// neo analysis
{
    name:"aboutNEO-Analysis",
    path:'/about/NEO-Analysis',
    component:NeoAbout
},
{
    name:'whyNEO-Analysis',
    path:'/why/NEO-Analysis',
    component:NeoWhy
},
{
    name:'testNEO-Analysis',
    path:'/test/NEO-Analysis',
    component:NeoTest
},
//eysenck analysis
{
    name:"aboutEysenck-Analysis",
    path:'/about/Eysenck-Analysis',
    component:eysenckAbout
},
{
    name:'whyEysenck-Analysis',
    path:'/why/Eysenck-Analysis',
    component:eysenckWhy
},
{
    name:'testEysenck-Analysis',
    path:'/test/Eysenck-Analysis',
    component:eysenckTest
},
//disc analysis
{
    name:"aboutDisc-Analysis",
    path:'/about/Disc-Analysis',
    component:DiscAbout
},
{
    name:'whyDisc-Analysis',
    path:'/why/Disc-Analysis',
    component:DiscWhy
},
{
    name:'testDisc-Analysis',
    path:'/test/Disc-Analysis',
    component:DiscTest
}
]
export default routes;