<template>
    <div id="container">
        <div id="header">
            <h2>{{ Personality }}</h2>
        </div>
<about-comp :about="about"/>
<history-comp :history="history"></history-comp>
<factor-comp :factors="factors"></factor-comp>
    </div>
</template>

<script>
import AboutComp from '/src/components/personality/about-comp.vue'
import HistoryComp  from '/src/components/personality/history-comp.vue'
import FactorComp from '/src/components/personality/factors-comp.vue'
export default {
    components:{AboutComp,HistoryComp,FactorComp},
    data(){
        return{
            personality:'',
            factors:{
        //behavior is their behavior and others is what they want from others and roles will be what role they desire
        'Dominance':{
        'about':'Someone who ranks highly in dominance and is identified as a "D" personality type is likely to be direct, assertive, independent, and decisive. They are intense competitors that thrive with ambitious goals and challenges, preferring action over analysis when they need to complete a task. When they state their opinion or ask questions, they mean business.Dominant D-types are also generally comfortable with conflict, and may push harder than other more passive personality styles to assert their will and take control of a situation, which can help other, more reserved people work toward personal growth.',
        types:{
            
        }
    }
    ,
    
        'Inducement':{
        'about':'People who are high in "Influence" and are identified as I-personalities often like fun. They are confident, engaging and extremely approachable. These individuals love social settings and value connecting with others, are always looking to expand their network and social circles and enjoy spending time with new people. They get excited to explore fresh ideas and begin new projects, and are likely to bounce around between what they are working on. I’s like to multi-task and be in on lots of things at once.People who score highly in influence are usually informal, warm, and welcoming. They have a sincere interest in the feelings of others and many of their strengths lie in their ability to connect with others.I-type personalities are likely to enjoy being around a large group of people. They tend to work well with other openminded, social people and are likely to thrive when theyre a part of a collaborative team. Their openness and social awareness is considered charming to those who interact with them.',
        types:{
    
        }
    }
    ,
    
        'Submission':{
        'about':'Those who display a lot of steadiness and are identified as S-personalities tend to be naturally reserved people who look for similarly supportive, consistent, and loyal individuals in their relationships. Many of S-types strengths lie in their patient people-skills; they are known for being sympathetic to others perspectives, as well as having strong active listening abilities, which contribute to the calm, steady environments and situations they help create. This steady quality makes the S’s excellent in situations that call for diplomatic skills, cooperation, and judgement of character.While an S-personality might wait for someone else to initiate a relationship, they are very dependable for maintaining relationships once they have been established. That being said, since they prefer investing heavily in a small group of people, their circles of friends are typically small, and extremely tight-knit. They may also invest a great deal of time in getting to know their co-workers. S’s are fiercely loyal and will work hard to keep close relationships with the people they have come to value over time. ',
        types:{
          
        }
    }
    ,
    
        'Compliance':{
        'about':"C-personalities are extremely analytical, and gravitate towards process, structure, and rules. C’s are intensely skeptical and use logic to objectively make decisions, rather than being swayed by emotions. Their strengths are likely to lie in their ability to think through their decisions. If the data informs new logic, a C-personality is capable of being flexible and changing their minds quickly. Their ability to think deeply about issues helps make each C-type a great problem-solver. They are often inventors and seek accurate solutions to the problems and projects they are entrusted to. Because they are naturally observant, C-type personalities are likely to ask a lot of questions and seek clear answers. Though they often prefer to work alone rather than in a group, they tend to work well with someone who is able to respond to questions in detail, support claims with evidence, and is a natural problem-solver. They may enjoy being a part of a team that gives them autonomy, while helping to resolve problems with logic and accuracy.",
    
        types:{

        }
    }
    },
           history:`The DISC assessment is a ‘four-quadrant’ tool that an individual can use to learn about their natural and adapted behaviors and the behaviors of others. Many organizations, large and small, use this tool to help employees and staff develop better communication and self-awareness skills. Frequently, DISC is used to create a more unified culture within whole organizations. Many organizations also use it with a leadership development capacity.
All DISC tests and assessments are based on the research and the DISC Theory of William Moulton Marston, Ph.D. (1893-1947).In 1928, Marston published his book titled Emotions of Normal People and he introduced his DISC theory. In this book, he classified four categories of human behavior which we now know as the DISC Personality Types:Dominance (D-Style)

Inducement (Influence or i-Style)

Submission (Steadiness or S-Style)

Compliance (Conscientious C-Style)

While Marston saw that a person had a primary ‘style’, he viewed a person as a ‘color-wheel’ in that the colors of each style blended into each other. Essentially, a person’s behavior was made up of a little bit of every style.

Marston’s work was influenced by his contemporaries such as Carl Jung and Sigmund Freud who focused on abnormal behavior. However, Marston sought to generalize if people exhibit classifiable normal behaviors.

It’s important to note that Marston did not develop an assessment. 

Fun fact; in addition to his work on DISC and understanding personality, Maston also created the lie detector test, and introduced “Wonder Woman” into the DC comic strip universe.

Walter Clarke and The Activity Vector Analysis (AVA)
The history of DISC Testing begins with Walter V. Clarke; 20-years after Marston published Emotions of Normal People.
Clarke built a test for personnel selection called the Activity Vector Analysis (AVA). The AVA was designed as a ‘short personality measure’ and used as a hiring selection tool for various industries.

To create the assessment, Clarke identified a list of 81 adjectives that are commonly used in describing someone. He then asked participants to check the specific words (from his list of adjectives) that they felt best describe themselves. 

After collecting and analyzing the data on this instrument, he discovered that the four factors produced from the data (aggressive, sociable, stable, and avoidant). If you think this sounded a lot like DISC, we would agree.

Clarke concluded that the data could be best explained by Marston’s model of human behavior. However, Clarke’s AVA wasn’t focused entirely on Marston’s DISC Theory, he also used the work of Prescott Lecky to create his test.

The Self Discription Assessment
In 1951, John Cleaver joined Walter Clarke Associates and over time developed his own assessment, which he called Self Discription (notice how the word ‘description is spelled). 

In 1956, Cleaver left Walter Clarke Associates to form J.P. Cleaver Co. Like Clarke, Cleaver intended to use his assessment as a way to select a person for a certain job based on their behavior.

Cleaver’s assessment began like the AVA as an adjective checklist, but it evolved into a 24, four-adjective, forced-choice ‘question’ instrument. 

The Personal Profile System (PPS) and DISC®
In the 1970s, John Geier, a faculty member in the University of Minnesota’s Department of Health Sciences, used Cleaver’s Self Discription to create the original Personal Profile System® (PPS).
Geier formed a company called Performax (which is now owned by Wiley).

PPS used the same 24 four-adjective ‘questions’ and items that appeared in the original Self Discription. Geier’s main contribution was not so much in the area of instrument development, but in furthering the understanding of the 15 basic patterns discovered by Clarke. Geier collected pattern descriptions through clinical interviews with hundreds of people. By extracting behavioral information from those interviews, he provided richer descriptions of these 15 patterns that had come to be known as the Classical Profile Patterns. The DISC Classic products still use these classic profile patterns to this day.
Assessment 24×7 and Dr. Tony Alessandra
In 1998 Dr. Tony Alessandra wrote the book The Platinum Rule. This book, argues that the “Golden Rule” is not always the best way to approach people. Rather, we should adopt the Platinum Rule: “Do unto others as “they’d” like done unto them”. In other words, find out what makes people tick and go from there.

o help people understand how to “do unto others as “they’d” like done unto them”, Dr. Tony Alessandra created an assessment company and his own version of DISC.

            `,
  
    about:`The DISC assessment is based on the work of William Moulton Marston, a psychologist who created the DISC theory. The DISC model centers on four DISC factors known as Dominance (D), Influence (I), Steadiness (S), and Conscientiousness (C).

Each person has their own set of values, beliefs and perspectives. We may not always agree, but there are tools that can make stressful interactions easier. It comes down to understanding and employing empathy in your everyday behavior. Empathy is the ability to share and understand another person’s feelings. To employ empathy is to be able to view another perspective in an authentic way.`,
        }
    },
    computed:{
        Personality(){
            return this.personality;
        }
    },
    mounted(){
this.personality=localStorage.getItem('test');
    }
}
</script>

<style scoped>
#container{
    width:100vw;
    overflow-x: hidden;
    height: 100vh;
}
#header{
    width:100vw;
    height:60px;
    background-image: linear-gradient(to right, #102216, #156039, #03ca8b);
}
#header h2{
    color: white;
    line-height: 60px;
    font-size: 38px;
    font-family: 'Sofia';
    text-align:center;
}
</style>