<template>
    <div id="suggest-container">
        <h2 id="logo">Sandesha</h2>
        <search-bar></search-bar>
        <div class="noRes" v-if="Suggestions.length <=0 ">
            No Suggestions
        </div>
        
        <div id="contacts" v-else>
            <suggestion-comp v-for="suggestion,index in Suggestions" :key="index" :image="suggestion.image" 
            :username="suggestion.username"  />
   </div>
   <spinner-comp v-if="Loader"/>
    </div> 
</template>

<script>
import SearchBar from '/src/components/contacts/search-comp.vue'
import SuggestionComp from '/src/components/contacts/suggestion-comp.vue'
import SpinnerComp from '/src/components/home/spin-loader.vue'
export default {
    components:{
        SearchBar,
        SuggestionComp,
        SpinnerComp
       
    },
    computed:{
        Suggestions(){
            
            return this.$store.getters['user/get_suggestions'];
        },
        Loader(){
            return this.$store.getters['user/getLoader']
        }
    },
   async mounted(){
    this.$store.commit('user/setLoader',{value:true})
        await this.$store.dispatch('user/getSuggestions')
        this.$store.commit('user/setLoader',{value:false})
    }
}
</script>

<style scoped>
#suggest-container{
    overflow-y:auto;
    position: relative;
    width:100vw;
    height:90vh;
}
#suggest-container::-webkit-scrollbar{
    display: none;
    visibility: hidden;
}
@import url('https://fonts.googleapis.com/css?family=Sofia');
#logo{
width: 87px;
height: 33px;
margin-left: 6px;
margin-top: 7px;
font-family: 'Sofia';
font-style: normal;
font-weight: 400;
font-size: 21px;
line-height: 33px;
/* identical to box height */


background: linear-gradient(95.76deg, #1708C2 2.71%, rgba(147, 12, 174, 0.94825) 54.21%, rgba(233, 8, 89, 0.89) 112.18%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#contacts{
    margin-top:10px;
}
.noRes{
    text-align: center;
    font-size:32px;
    font-weight: 350;
    font-family: Arial, Helvetica, sans-serif;
    margin-top:25vh;
    animation:animation ;
    animation-direction: alternate-reverse;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-play-state: running;
}
@keyframes animation{
    from{
        color:black
    }
    to{
        color:rgba(233, 8, 89, 1);

    }
}
</style>