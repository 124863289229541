<template>
    <div id="assistant">
        <h1 id="dost">Dost</h1>
        <!--will add animation for every new chat added-->
        <img :src="require('/src/assets/dost.png')" alt="dost" id="bot-image">
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
#assistant{
width: 100vw;
height: 73px;
background: #E90859;
}
#dost{
    float:left;
    color:white;
    font-family: 'Sofia';
    font-weight:400;
    font-size:34px;
    margin-left:4vw;
    line-height: 73px;
}
#bot-image{
    float:right;
    margin-right:6vw;
    margin-top:14px;
}
.animate{
    animation: shake;
    animation-duration: 2s;
    animation-iteration-count: 4;
}
@keyframes shake {
    0%, 100% {transform: translateX(0);} 
    10%, 30%, 50%, 70%, 90% {transform: translateX(-10px);} 
    20%, 40%, 60%, 80% {transform: translateX(10px);} 
 } 
</style>