<template>
    <div id="container">
        <div id="header">
            <h2>{{ Personality }}</h2>
            <h3>Why should I take this test?</h3>
            <p>The NEO Personality Inventory Test can be used for job-placement, self-reflection, and understanding, and for learning how to more effectively interact with other team members at work. If a job demands specific character traits and personality types, then you can use the NEO-PI-R to show your potential employer that you have these sort-after characteristics.

                In addition, the NEO Personality Inventory can be used to understand the characteristics of those around you. For example, sometimes sporting teams use personality tests to help the team member learn about one another. The NEO-PR-I can be a great tool to bring team members together and can be used to foster a productive teamwork environment.
                
                Another reason for taking the NEO Personality Inventory test is for the purposes of self-reflection. This test can provide you with a way to classify your various personality traits bringing your awareness to aspects of your personality you may otherwise not be aware of. For instance, if you are regularly required to present speeches in front of people as part of your job role, but have always felt nervous about doing so, knowing that you are by nature an introvert may help you understand where the nervousness is coming from.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            personality:''
        }
    },
    computed:{
        Personality(){
            return this.personality;
        }
    },
    mounted(){
this.personality=localStorage.getItem('test');
    }
}
</script>

<style scoped>
#container{
    width:100vw;
    overflow-x: hidden;
    height: 100vh;
}
#header{
    width:100vw;
    height:60px;
    background-image: linear-gradient(to right, #102216, #156039, #03ca8b);
}
#header h2{
    color: white;
    line-height: 60px;
    font-size: 38px;
    font-family: 'Sofia';
    text-align:center;
}
h3{
    text-align: center;
    font-weight: 400;
}
p{
    padding-left:1vw;
    padding-right:1vw;
    font-size:20px
}
</style>