export default{
    set_add_members(state,payload){
        state.add_members.push(payload.value);
        
    },
    set_remove_members(state,payload){
        state.add_members= state.add_members.filter(val=>{
            return val.name != payload.value;
        })

    },
    set_add_name(state,payload){
        state.add_name = payload.value;
    },
    set_add_description(state,payload){
        state.add_description=payload.value;
    },
    set_add_image(state,payload){
        state.add_image=payload.value;
    },
    set_curr_comp(state,payload){
        state.curr_comp=payload.value;
    },
    setParticipants(state,payload){
        state.participants.push(payload.value)
    },
    setLoader(state){
        state.loader = !state.loader;
    }
}