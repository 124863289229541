<template>
    <div id="the-image" @dblclick="deleteImage">
        <img :src="image.image" :alt="image.image" id="image">
        <div id="info">
            <b-icon icon="heart-fill" class="icon-1" v-if="State" @click="likeImage"></b-icon>
            <b-icon icon="heart" class="icon-1" v-else @click="likeImage"></b-icon>
            <h3 class="likes" @click="toggle">{{ Likes }} likes</h3>
        </div>
        <div id="tharkee" v-if="Tharak">
         <b-icon icon="x-square-fill" style="float:right;margin-right:10px;margin-top:10px;font-size:24px;" @click="toggle"></b-icon>
         
        </div>
    </div>
</template>


<script>
export default {
props:['image','user'],
data(){
    return{
        likeState:false,
        likes:0,
        showLikees:false
    }
},
computed:{
    State(){
        return this.likeState
    },
    Likes(){
        return this.likes;
    },
    Tharak(){
        return this.showLikees;
    }
},
methods:{
    toggle(){
        this.showLikees=!this.showLikees;
    },
    deleteImage(){
        // delete this image but only from the users collection because once something is uploaded on net it stays on net
      this.$store.commit('user/setDelImage',{value:true});
      this.$store.commit('user/setImgDel',{value:this.image})  
    },
    
    async likeImage(){
          if(this.likeState == false){
            this.likeState=true;
            this.likes=this.likes+1;
          }
          else{
this.likeState=false;
if(this.likes>0)
this.likes=this.likes-1;
else
this.likes=0;
          }
          await this.$store.dispatch('user/likeImage',{value:this.image,action:this.likeState,user:this.user})
    }
},
mounted(){
    this.likes = this.image.likeCount;
    let user = localStorage.getItem('user');
    let liked = this.image.likes.filter(val=>{
        return val == user;
    })
    this.likeState=liked.length>0;
}    
}
</script>

<style scoped>
#the-image{
    width: 100vw;
}
#image{
    width:96vw;
    margin-left:2vw;
    height:350px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.56);
    border-radius:14px;
    margin-bottom:15px;
}
.icon-1{
    margin-left:5vw;
    font-size:28px;
    margin-top:-2px;
    color:rgb(244, 3, 64);
    float:left;
}

.likes{
    float:left;
    margin-left:5vw;
    margin-top:-2px;
}
#tharkee{
    position: absolute;
    width:80vw;
    height:60vh;
    top:10vh;
    left:10vw;
    background: rgba(255, 255, 255, 0.442);
    border-radius: 15px;
    box-shadow:2px 2px 4px rgba(0, 0, 0, 0.688);
    z-index: 1
}
</style>