v<template>
    <div id="header">
       <div id="col1">
        <img :src="image" alt="DP" id="dp" v-if="image">
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSd6k0U8FTrKucw1yfjUy6JPyzjsKurvW2xg&usqp=CAU" alt="DP" id="dp" v-else>
       </div>
       <div id="col2">
        <h3 id="name">{{ name }}</h3>
       <h5 id="status">typing...</h5>
       </div>
      <div id="col3">
        <b-icon icon="telephone-fill" class="menu" id="call" @click="toggleMenu" ></b-icon> 
        <b-icon icon="camera-video-fill" class="menu" id="video" @click="toggleMenu" style="margin-left:35px;"></b-icon> 
      </div>
       <div id="content" v-if="state">
<div class="elem" @click="forward('profile')">
    <b-icon class="icon" icon="people-fill" style="width: 30px; height: 30px;top:18px;"></b-icon>
    <h2 class="text">Description</h2>
</div>
<div class="elem" @click="forward('requests')">
    <b-icon class="icon" icon="volume-mute-fill" style="width: 30px; height: 30px;top:18px;"></b-icon>
    <h2 class="text">Mute</h2>
</div>
<div class="elem" @click="forward('new-group')">
    <b-icon class="icon" icon="brush-fill" style="width: 30px; height: 30px;top:18px;"></b-icon>
    <h2 class="text">Change Color</h2>
</div>
<div class="elem">
    <b-icon class="icon" icon="shield-fill" style="width: 30px; height: 30px;top:18px;"></b-icon>
    <h2 class="text">Hide chat</h2>
</div>
</div>   
       </div>
</template>


<script>
export default {
props:['grpid'],
    methods:{
        toggleMenu(){
            this.$store.commit('group/setMenu')
        }
        },
        data(){
            return{
                image:"",
                name:'',
            }
        },
async mounted(){
    let data = await this.$store.dispatch('group/getGroup',{value:this.grpid})
        data = JSON.parse(data);
        this.image = data.wallpaper;
        this.name = data.name
}
}
</script>

<style scoped>
#header{
width:100vw;
height: 60px;
left: 0px;
top: 0px;
overflow-x:hidden;
overflow-y:hidden;
background: rgba(255, 255, 255, 0.15);
box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
display: grid;
grid-template-columns: 10vw 40vw 50vw;
}
#dp{
width: 50px;
height: 50px;
border-radius: 50px;
margin-top:5px;
margin-left:5px;
}
#name{
width: 40vw;
line-height:40px;
overflow: hidden;
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 20px;
line-height: 23px;
color: #000000;
margin-left:27px;
margin-top:10px;
}
#status{
width: 30vw;
line-height:20px;
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 15px;
line-height: 18px;
margin-left:28px;
margin-top:-5px;

/* identical to box height */


color: rgba(0, 0, 0, 0.63);

}
.menu{
width: 24px;
height: 24px;
}
#content{
    top:20.75px;
    width:45vw;
    margin-left:52vw;
    margin-top:26px;
    height:300px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.518);
    background: white;
    padding-top:20px;
    z-index:1;
    position: absolute;
}
#col3{
    padding-top:20px;
    padding-left:20vw;
}
#toggle{
    float: right;
    margin-right:4px;
}
</style>