<template>
    <div id="container">
        <div id="header">
            <h2>{{ Personality }}</h2>
        </div>
        <about-comp :about="about"/>
<history-comp :history="history"></history-comp>
<factor-comp :factors="factors"></factor-comp>
    </div>
</template>


<script>
import AboutComp from '/src/components/personality/about-comp.vue'
import HistoryComp  from '/src/components/personality/history-comp.vue'
import FactorComp from '/src/components/personality/factors-comp.vue'
export default {
    components:{AboutComp,HistoryComp,FactorComp},
    data(){
        return{
            personality:'',
            factors:{
                'Extraversion-Introversion':{
                    'about':'Swiss psychoanalyst Carl Jung (1875-1961) suggested that extraversion-introversion levels depend upon the focus of an individual’s psychic energy. In extraverts, he believed that this energy was directed outwards - towards other people - resulting in more social interactions. Meanwhile, introverts’ psychic energy is projected inwards, leading them to indulge in inner-focussed, less sociable, activities (Jung, 1921).Eysenck believed that extraversion is linked to levels of brain activity, or cortical arousal. Extraverts experience lower levels of cortical arousal, resulting in them seeking arousal from external stimuli. Higher arousal levels in introverts leads them to avoid stimuli which may lead to a further increase in arousal (Eysenck, 1979).',
                    'types':{
                        'Extraversion':'Individuals with high levels of extraversion engage more in social activities. They tend to be more talkative, outgoing and feel more at ease in groups. Extraverts enjoy being the focus of attention and often accumulate a larger social network of friends and associates.Eysenck believed that extraversion is linked to levels of brain activity, or cortical arousal. Extraverts experience lower levels of cortical arousal, resulting in them seeking arousal from external stimuli. Higher arousal levels in introverts leads them to avoid stimuli which may lead to a further increase in arousal (Eysenck, 1979).',
                        'Introversion':'Introverts tend to be quieter, shying away from large social gatherings, and they may feel uncomfortable engaging with strangers. Instead, they maintain smaller groups of close friends and are more likely to enjoy contemplative exercises.'
                    }
                },
                'Neuroticism-Emotional Stability':{
                    'about':'Neuroticism is also characterised by perfectionism, and a tendency to feel dissatisfied, angry or frustrated with others when their desires are not fulfilled, or when their expectations are not met.According to biopsychological theory (Gray, 1970), neuroticism is positively correlated with the behavioral inhibition system (BIS), which influences behavior with a view to avoiding negative outcomes, such as punishment.',
                    'types':{
                        'Neuroticism-Emotional High':'Individuals scoring highly on neuroticism measures tend to experience higher levels of stress and anxiety. They worry about relatively insignificant matters, exaggerating their significance and feeling unable to cope with life stressors. A focus on negative aspects of a situation, rather than the positives, can lead to a person to adopt a disproportionately negative outlook. They may feel envious or jealous of others who they feel are in a more advantaged position.',
                        'Neuroticism-Emotional Low':'A person with a low neuroticism score will generally experience more emotional stability. They feel more able to cope with stressful events and set less stringent demands of themselves.'
                    }
                },
                'Psychoticism-Normality':{
                    'about':'Psychoticism was a late addition to Eysenck’s theory of personality, and was included in 1976 whilst Hans was working with his wife, Sybil Eysenck (Eysenck and Eysenck, 1976). This third dimension of personality ranges from normality (low psychoticism) to high psychoticism.Eysenck suggested that psychoticism was influenced by biological factors, and was correlated with levels of hormones such as testosterone.',
                    'types':{
                        'Psychoticism-Normality High':'Individuals with higher psychoticism scores are more likely to engage in irresponsible or miscalculated behavior. They may also contravene accepted social norms and be motivated by a need for immediate gratification, regardless of its consequences.high levels of traits such as psychoticism reduce a person’s responsiveness to conditioning, meaning that they do not adopt the social norms that one may learn through reward and punishment. As a result, the theory suggests that individuals may be more prone to criminal behavior as they seek to fulfill their own interests whilst violating the rules of behavior accepted by others.',
                        'Psychoticism-Normality Low':'Low score in this domain of the test means that the personality of the individual will be normal'
                    }
                },
            },
            about:`The PEN model is a biological theory of personality developed by influential psychologist Hans Eysenck (1916-1997). The model focusses on three broad personality factors: psychoticism, extraversion and neuroticism (PEN).
            Eysenck believed that biological factors, including cortical arousal and hormone levels, along with environmental factors, such as behavior learned through conditioning, influence a person’s score on these personality dimensions.
            Hans Jürgen Eysenck was born in Berlin in 1916 to parents Eduard and Ruth, who were both actor by profession. In 1934, he left Germany amidst the Nazis’ rise to power, eventually settling in England. He studied for a PhD at University College, London, under the controversial educational psychologist, Sir Cyril Burt. Completing his studies in 1940, he began working during the Second World War at Mill Hill Emergency Hospital. After the war, he accepted a position at the newly-founded psychology department at the Institute of Psychiatry.

Eysenck made a significant contribution to the field of psychology, publishing around 80 books as well as writing hundreds of articles. He was also the founding editor of the influential journal Personality and Individual Differences.

Eysenck’s work drew criticism for suggesting that biological or genetic factors influence personality and an individual’s susceptibility to engage in criminal behavior.

He died in London in 1997 aged 81. At the time, he was the most frequently cited author in psychology journals.`,
            history:`Eysenck was the first person who related the personality as biological constrain. Eysenck developed his theory based on both biological and psychological perspective of personality. Eysenck believed that biological factors, includes cortical provocation and hormone levels, along with ecological factors, such as behaviors erudite through conditioning, persuade a person’s personality proportions. Hans Jürgen Eysenck was born in Berlin in 1916 to parents Eduard and Ruth, who were both actor by vocation. In 1934, he left Germany amidst the Nazis’ rise to power, in due course he settled in England. He premeditated for a PhD at University College, London, under the contentious educational psychologist, Sir Cyril Burt. He accomplished his studies in 1940; he started working during the Second World War at Mill Hill Emergency Hospital. After the war, he accepted a position at the newly-founded psychology department at the Institute of Psychiatry.`
        }
    },
    
    computed:{
        Personality(){
            return this.personality;
        }
    },
    mounted(){
this.personality=localStorage.getItem('test');
    }
}
</script>

<style scoped>
#container{
    width:100vw;
    overflow-x: hidden;
    height: 100vh;
}
#header{
    width:100vw;
    height:60px;
    background-image: linear-gradient(to right, #102216, #156039, #03ca8b);
}
#header h2{
    color: white;
    line-height: 60px;
    font-size: 38px;
    font-family: 'Sofia';
    text-align:center;
}
</style>