<template>
    <div class="container">
       <div class="header-container">
        <img :src="Cover" :alt="Name" class="header-image" v-if="Cover != ''"/>

       <img src=" https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/app-images%2Fnodp.png?alt=media&token=31f71de3-e54a-41ec-ae1c-adc43f840d9f" :alt="Name" class="header-image" v-else>
         <div class="header">
           <h1 class="main-heading">{{ Name }}</h1>
           <span class="tag" id="tag1">{{ Gender }}</span>
           <span class="tag" id="tag2">Producer</span>
         
         </div> <!-- END header -->
       </div>
       
       <div class="overlay-header"></div>
       
       <div class="body">
        <img :src="Image" :alt="Name" class="body-image"  v-if="Image !='' "/>
       <img src="https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/app-images%2Fnodp.png?alt=media&token=31f71de3-e54a-41ec-ae1c-adc43f840d9f" :alt="Name" class="body-image" v-else>

       <span class="body-stats">Contacts <span> {{ Contacts }} </span></span>
       <span class="body-stats">Communities <span> {{ Communities }} </span></span>
         <div class="u-clearfix"></div>
         <div class="body-info" style="margin-top:12px;">
           <p>
            {{ Status }}
            <span>
              <b-icon icon="pencil-square" style="float:right;margin-right:10px;"></b-icon>
            </span>
           </p>
           
         </div>
         <div class="card u-clearfix">
           <span class="card-heading">Likes <span><b-icon icon="pencil-square" style="float:right;margin-right:10px;"></b-icon></span></span>
           <ul class="card-list">
             <li class="user-item" v-for="like in Likes" :key="like">{{ like }}</li>
           </ul>
         </div>
         <div class="card u-clearfix">
          <span class="card-heading">Dislikes <span><b-icon icon="pencil-square" style="float:right;margin-right:10px;"></b-icon></span></span>
          <ul class="card-list">
            <li class="user-item" v-for="dislike in Dislikes" :key="dislike">{{ dislike }}</li>
          </ul>
        </div>
        <div class="card u-clearfix">
          <span class="card-heading">Hobbies</span>
          <ul class="card-list"> 
           <div class="hob-item" v-for="hobby in Hobbies" :key="hobby.hobby" @click="getHobImage(hobby.hobby)">
            <img :src="hobby.image" :alt="hobby.hobby">
            <h3>{{ hobby.hobby }} <span><b-icon icon="pencil-square" style="float:right;margin-right:10px;margin-top:5px"></b-icon></span></h3>
           </div>
           </ul>
        </div>
      </div>
      
       </div>
  
  </template>
  
  <script>
  //ye last mei implement karna hai
  export default {
     data(){
         return{
             userName:localStorage.getItem('userName'),
             dp:localStorage.getItem('profile'),
             status:localStorage.getItem('status'),
             gender:localStorage.getItem('gender'),
             likes:localStorage.getItem('likes').trim().split(),
             dislikes:localStorage.getItem('dislikes').trim().split(),
             hobbies:localStorage.getItem('hobbies').trim().split(),
             state:false,
             cover:localStorage.getItem('cover'),
             hobbies_map:{
              "Antiquing":'https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fantique.png?alt=media&token=b68d2bf6-acea-40bd-870b-2cba2189ee6c',
              "Aquarium Keeping":'https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Facquarium.png?alt=media&token=cd454538-9e71-4bbb-a70f-fe2822488320',
              "Archery":'https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Farchery.png?alt=media&token=40722c48-f92f-4942-86c5-c8d4ba23c3f6',
              "Asrtology":'https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fastrology.png?alt=media&token=16a3dd24-0537-4264-a427-c04249f034c6',
              "Astronomy":'https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fastronomy.webp?alt=media&token=90b6a236-3df5-4ada-a104-fee8b99f8640',
              "Baking":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fbaking.png?alt=media&token=0c3fc5cc-4916-4cb0-94e8-bdc1bf2ab110",
              "Basketball":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fbasketball.png?alt=media&token=e3911288-e426-43ef-9689-784fee4542ec",
              "Beekeeping":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fbeekeping.png?alt=media&token=1d7e79d5-cf4d-4431-9c0a-3a1acfb3895a",
              "Biking":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fbiking.png?alt=media&token=f61d5679-cf67-4c41-b07a-e5d60dc3b00f",
              "Billiards":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fbilliards.png?alt=media&token=ea8680ac-1f17-43a5-b2fe-4b889021f233",
              "Bird watching":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fbirdwatching.png?alt=media&token=dbc1f93b-36a4-44b2-84cf-1c2f2f763a49",
              "Blogging":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fblog.png?alt=media&token=c4ea6a7d-1151-4fac-b6b0-4ac8ce3447a3",
              "Bodybuilding":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fbodybuilding.png?alt=media&token=f87328b7-ef57-43b7-8bd2-a882e5f08680",
              "Bowling":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fbowling.png?alt=media&token=2a3bc836-32dd-4562-8ac7-7554b48d5f82",
              "Brewing beer":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fbrewingbeer.png?alt=media&token=687a5834-23f4-456c-9f8c-8af9ac4edd9f",
              'Cake decoration':"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fcakedecoration.png?alt=media&token=a60ff821-fd66-4a3e-b0cf-1042489131bf",
              "Calligraphy":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fcalligraphy.png?alt=media&token=0d066b8f-7239-4c0d-9c97-546a86a4738e",
              "Camping":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fcamping.png?alt=media&token=25abd34b-35ee-4bca-b8ed-ce96ebbcf286",
              "Candle Making":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fcandlemaking.png?alt=media&token=4d25896e-dbab-4cc0-893f-dffca74ab4b7",
              "Canyoneering":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fcanyoeering.png?alt=media&token=103bbbb0-4b40-4fca-b6b7-e0c8a768ffb3",
              "Cricket":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fcricket.png?alt=media&token=c1c98c08-eab9-4195-a392-7e0ff75612cc",
              "Gambling":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fgambling.png?alt=media&token=6927ca19-ce03-4a0f-948f-7544bfa81145",
              "Chess":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fchess.png?alt=media&token=bf9edd55-8f6b-4a89-8b69-af06ea8735be",
              "Adult Coloring":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fadultcoloring.png?alt=media&token=2112aca4-ef8d-47fb-8768-d0a207f34637",
              "Cooking":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fcooking.png?alt=media&token=db8388de-4459-4822-af3d-18641973e5c4",
              "Dancing":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fdancing.png?alt=media&token=acd73044-3e95-42d5-9b52-0e828a7b5e39",
              "Drawing":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fdrawing.png?alt=media&token=2fd0cb07-258d-4cc3-8db4-ee2f79aee93c",
              "Fishing":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Ffishing.png?alt=media&token=dd645eff-a0a9-4b0a-ac62-7d9cce4400af",
              "Football":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Ffootball.png?alt=media&token=f6168e43-aa13-40f9-af09-e25a6424554a",
              "Fitness":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Ffitness.png?alt=media&token=15654262-915d-4d97-a33d-47df4273645b",
              "Gokarting":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fgokarting.png?alt=media&token=04ec41b5-53ea-4f8f-82eb-1f10c6fe428f",
              "Golf":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fgolf.png?alt=media&token=c7b90dc3-ae2c-4b15-a906-02791abb75fb",
              "Graffiti Art":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fgrafiti.png?alt=media&token=0a24d434-fb13-4392-ae7e-b10cf55c4e93",
              "Hiking":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fhiking.png?alt=media&token=5dc8b9ca-81d4-4f05-93dc-6fb549bb0a44",
              "Interior Design":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Finteriordesign.png?alt=media&token=b91ce24d-6d1d-424d-ac47-96e23ac65153",
              "Ice Skating":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Ficeskating.png?alt=media&token=0e50b722-d401-483e-93a4-8688172e65fa",
              "Jewelry making":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fjewelerymaking.png?alt=media&token=3e47ea43-b20c-48e4-8667-93eacee17f95",
              "Kayaking":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fkayaking.png?alt=media&token=c2a81116-6c2c-47f7-92df-8b5963aa3224",
              "Knitting":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fknitting.png?alt=media&token=09e392c8-17a0-4a26-9523-07e1dbc86d9c",
              "Magic":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fmagic.png?alt=media&token=546d77d5-e4d0-4507-a8ec-b6a3ef068663",
              "Martial Arts":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fmartialarts.png?alt=media&token=67991fa4-dc7b-432e-abff-efcdbe620f13",
              "Meditation":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fmeditation.png?alt=media&token=3360f045-c910-4925-981c-c5d61a2e3b7a",
              "Mixology":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fmixology.png?alt=media&token=e1d580f6-72de-410f-b03c-ecdfe627905b",
              "Mountaineering":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fmountaineering.png?alt=media&token=f0907736-2c64-4c26-accb-7c8bcbcaeb4d",
              "Music":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fmusic.png?alt=media&token=7cf8e84f-fbaa-48e3-a7b6-1f8fe3b1c0ce",
              "Networking":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fnetworking.png?alt=media&token=bbc5ca6e-0452-4e71-8d21-ecdf46c9925c",
              "Origami":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Forigami.png?alt=media&token=fea355c6-e7bf-4bcb-a90b-42ae542c9174",
              "Painting":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fpainting.png?alt=media&token=e6e809ad-73df-4761-8cc5-a37555ff2ce3",
              "Shooting":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fshooting.png?alt=media&token=e1b0fb11-8d5d-4747-b516-97f1c19ef79c",
              "Photography":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fphotography.png?alt=media&token=06e83bd1-e613-4200-bd85-202cb8cdfc1b",
              "Trading":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Ftrading.png?alt=media&token=10dbd74b-c709-4739-83de-b284ecde4232",
              "Pottery":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fpottery.png?alt=media&token=cabc8476-87e5-474b-a916-cbc65e20d999",
               "Rappelling":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Frappeling.png?alt=media&token=a270e650-f86a-4557-95f9-b2996614b690",
               "Rapping":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Frapping.png?alt=media&token=75b09317-adcb-4995-bd43-2f3bd2a014b8",
               "Reading":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Freading.png?alt=media&token=d2b9381f-b9d1-40fc-86ba-ab3ac6b53387",
               "Beatboxing":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fbeatboxing.png?alt=media&token=a90e967f-906b-430e-a543-c198c77ac51e",
               "Rock climbing":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Frockclimbing.png?alt=media&token=5bb41269-f464-470d-9a63-93d85a1e28a0",
               "Running":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Frunning.png?alt=media&token=812aed5f-2554-4cae-999a-c4344872274e",
               "Sailing":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fsailing.png?alt=media&token=92fc3675-c0e0-4fb2-a361-d3570cb227c3",
               "Scuba Diving":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fscubadiving.png?alt=media&token=dd208bcc-8bb0-4bd1-a22e-88efe646d490",
               "Self care":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fselfcare.png?alt=media&token=47da9020-c0da-4125-9855-2119133ec443",
               "Sking":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fsking.png?alt=media&token=d3dd2644-b030-41b1-a515-c784db731187",
               "Skateboarding":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fskateboarding.png?alt=media&token=2bf17b65-8bda-4f9c-b498-be07c38967d4",
               "Standup":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fstandup.png?alt=media&token=80c3dbfe-4482-486a-86c6-0ad97e80b09d",
               "Surfing":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fsurrfing.png?alt=media&token=156c6c1e-a7d8-4adb-af66-c4e68bb2fe08",
               "Swimming":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fswimming.png?alt=media&token=69b482a0-a7b8-44aa-b646-51d03cb254b6",
               "Tennis":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Ftennis.png?alt=media&token=047e6075-66bb-4800-919a-54e08f4ffd0e",
               "Anime&Manga":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fanime.png?alt=media&token=9aa611a3-de15-47f9-b446-51893164a603",
               "Travelling":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Ftravelling.png?alt=media&token=0a418c33-b58c-4869-ba7f-453e6388c82d",
               "UFC":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fufc.png?alt=media&token=dc59d281-0788-4097-b1d9-e973e7c9aa84",
               "Video Gaming":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fvideogaming.png?alt=media&token=d0f46f72-961f-4644-ba28-29ea7e19bc24",
               "Netflix&Chill":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fnetflix_chill.png?alt=media&token=edfee671-58ee-4fac-ab3c-ac3abffa2f12",
               "Wine tasting":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fwinetasting.png?alt=media&token=2e1c3e0b-0082-424f-a43b-1139fddc27a9",
               "Writing":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fwriting.png?alt=media&token=703ae435-a773-4540-8a41-ad1031c60176",
               "Yoga":"https://firebasestorage.googleapis.com/v0/b/sandesha-7e622.appspot.com/o/hobbies%2Fyoga.png?alt=media&token=c1a3b94b-cc35-4592-8c4c-14e30ef180a2"
         }
            }
     },
     computed:{
      Cover(){
        return this.cover;
      },
       State(){
          return this.state;
       },
      Name(){
         return this.userName;
      },
      Image(){
         return this.dp;
      },
      Status(){
         return this.status;
      },
      Gender(){
         return this.gender;
      },
      Contacts(){
     return localStorage.getItem('contacts').split(',').length;
    },
    Communities(){
     return localStorage.getItem('communities').split(',').length;
    },
      Likes(){
         return this.likes;
      } ,
      Dislikes(){
         return this.dislikes;
      },
      Hobbies(){
        let final_arr=[]
        let shauk=this.hobbies[0].split(',')
        shauk.forEach(hob=>{
          final_arr.push({'hobby':hob,'image':this.hobbies_map[hob]})
        })
       return final_arr
      }  
     },
     methods:{
      async getHobImage(val){
        this.$store.commit('user/emptyHobImages')
        this.$router.push({name:'hobImages'})
        await this.$store.dispatch('user/getHobby',val)
        
      }
     }
  }
  </script>
  
  <style scoped>
  
  .container {
    box-shadow: 0 0 50px rgba(0,0,0,.3);
  overflow-y:auto ;
    overflow-x: hidden;
    width: 100vw;
    margin:0px;
    padding:0px;
  left:0;
  right:0;
  }
  
  .header-container {
    position: relative;
  }
  
  .header-image {
    left: 0;
    position: fixed;
    top: 0;
    width:100vw;
    height:700px;
    -webkit-animation: zoomEffect 35s infinite;
            animation: zoomEffect 35s infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
  
  @-webkit-keyframes zoomEffect {
    0% {
      -webkit-transform: scale(1) translateX(0);
              transform: scale(1) translateX(0);
    }
    100% {
      -webkit-transform: scale(1.2) translateX(-60px) translateY(-20px);
              transform: scale(1.2) translateX(-60px) translateY(-20px);
    }
  }
  
  @keyframes zoomEffect {
    0% {
      -webkit-transform: scale(1) translateX(0) translateY(0);
              transform: scale(1) translateX(0) translateY(0);
    }
    100% {
      -webkit-transform: scale(1.2) translateX(-60px) translateY(-20px);
              transform: scale(1.2) translateX(-60px) translateY(-20px);
    }
  }
  
  .header {
    color: #fff;
    padding: 15px;
    height: 300px;
  }
  
  .header > svg {
    cursor: pointer;
  }
  
  .main-heading {
    color: #fff;  
    font-size: 32px;
    font-weight: 400;
    top: 20px;
    left:20px;
    margin-top:25px;
    position: absolute;
  }
  
  .tag {
    background-color: rgba(17, 17, 17, 0.585);
    border-radius: 20px;
    font-size: 16px;
    padding: 4px 10px;
    position: absolute;
    text-transform: uppercase;
  }
  #tag1{
  left:20px;
  }
  #tag2{
  left:90px;
  }
  .stats {
    margin-top: 35px;
  }
  
  .stat-module {
    display: inline-block;
    font-size: 12px;
    margin-right: 20px;
    text-transform: uppercase;
  }
  
  .stat-number {
    display: inline-block;
    font-weight: 600;
    margin-left: 4px;
  }
  
  .overlay-header {
    background-color: #eee;
    height: 100px;
    margin: -50px 0 0 -25%;
    transform: rotate(-10deg);
    width: 150%;
    z-index: 0;
  }
  
  .body {
    background-color: #eee;
    color: #555;
    margin-top: -50px;
    padding: 0 15px 15px;
    position: relative;
  }
  
  .body-image {
    width: 110px;
    height: 110px;
    border-radius: 110px;
    box-shadow: 5px 10px 75px rgba(0, 0, 0, .4);
    float: left;
    margin: -90px 0 20px;
    position: relative;
    z-index: 2;
  }
  
  .body-action-button {
    background-color: #383838;
    border-radius: 50%;
    box-shadow: 1px 2px 12px rgba(0,0,0,.4);
    cursor: pointer;
    height: 40px;
    position: absolute;
    right: 15px;
    top: -97px;
    width: 40px;
  }
  
  .body-stats {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    float: left;
    margin: -5px -0 0 30px;
    position: relative;
    text-transform: uppercase;
    width: 20%;
  }
  
  .body-stats > span {
    display: inline-block;
    font-weight: 900;
    margin-top: 8px;
  }
  
  .body-info {
    clear: left;
    position: relative;
    max-height: 300px;
    height: fit-content;
    overflow: hidden;
    overflow: hidden;
    transition: all 600ms ease-out;
  }
  
  .body-more {
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(238,238,238,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(238,238,238,1) 100%);
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(238,238,238,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#eeeeee',GradientType=0 );
    cursor: pointer;
    margin: -57px auto 0px;
    padding: 20px 0 20px;
    position: relative;
    text-align: center;
  }
  
  .body-more span {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 1px 1px 5px rgba(0,0,0,.3);
    display: inline-block;
    height: 6px;
    margin-right: 2px;
    width: 6px;
  }
  
  .card {
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .15);
    margin-top: 15px;
    padding: 10px;
  }
  
  .card-heading {
    font-size: 18px;
    text-transform: uppercase;
  }
  
  .user-item{
    color:black;
    font-size:16px;
  }
  
  .card-list {
    list-style-type: none;
    margin: 10px 0 0;
    overflow-x: scroll;
    padding: 0;
    white-space: nowrap;
  }
  
  .card-list::-webkit-scrollbar-track
  {
     -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
     border-radius: 2px;
     background-color: #F5F5F5;
  }
  
  .card-list::-webkit-scrollbar
  {
     height: 3px;
     background-color: #F5F5F5;
  }
  
  .card-list::-webkit-scrollbar-thumb
  {
     border-radius: 10px;
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
     background-color: #ddd;
  }
  
  .card-list li {
    display: inline;
    margin-left: 10px;
  }
  
  .card-list li:first-child {
    margin-left: 0;
  }
  
  .hob-item{
    width:82vw;
    height:350px;
    overflow: hidden;
    margin-left:5px;
    margin-right:5px;
  }
  .hob-item img{
    width:82vw;
    height:300px;
    border-radius:10px;
  }
  .hob-item h3{
    font-size:20px;
    font-weight:350;
    font-family: 'inherit';
    text-align: center;
    height:29px;
    margin-top:5px;
    overflow: hidden;
  }
  </style>