<template>
  <div id="container">
    <img :src="image" alt="name" id="image">
  <h3>{{ name }}</h3>
<b-icon icon="x-circle-fill" class="icon" @click="remove"></b-icon>
  </div>
</template>

<script>
export default {
  props:['name','image','id'],
  methods:{
    remove(){
      this.$store.commit('group/set_remove_members',{value:this.name})
    }
  }
}
</script>

<style scoped>
#container{
  position: relative;
width: 80px;
height: 98px;
max-width: 80px;
margin-top:7px;
margin-bottom:7px;
background: #FFFFFF;
}
#image{
  position: absolute;
width: 60px;
height: 60px;
left: 5px;
top: 0px;
border-radius: 100px;
}
h3{
position:absolute;
width: 37px;
height: 26px;
left: 4px;
top: 62px;
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 14px;
color: #000000;
/* identical to box height */
color: #000000;
}
.icon{
width:20px;
height:20px;
position: absolute;
left:50px;
top: 67.92%;
color: #E90859;
}
</style>