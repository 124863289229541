<template>
    <div id="container" @click="clicked">
    <b-img  :src="image" id="image" rounded="circle" alt="motabhai"></b-img>
    <h2 id="username">{{ name }}</h2>
    <b-icon icon="check-circle-fill" id="icon" v-if="show"></b-icon>
    </div>
</template>

<script>
export default {
    props:['name','image','id'],
    data(){
        return{
            state:false
        }
    },
    computed:{
        show(){
            return this.state;
        }
    },
    methods:{
        clicked(){
            if(this.state==false)
            {
                this.state=true;
                this.$store.commit('group/set_add_members',{value:{"name":this.name,"image":this.image,"id":this.id}})
            }
            else{
                this.state=false;
                this.$store.commit('group/set_remove_members',{value:this.name})
            }
        }
    }
}
</script>

<style scoped>
#container{
position: relative;
width: 100vw;
height:80px;
overflow: hidden;
border-bottom:1px solid rgba(0, 0, 0, 0.54);
padding-left:1vw;
padding-right:1vw;
}
#image{
width: 65px;
height: 65px;
margin-top:7.5px;
margin-left:5px;
}
#username{
position: absolute;
width:50vw;
height:5vh;
left:85px;
top:20px;
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 28px;
line-height: 33px;
/* identical to box height */


color: #000000;
}
#chat-header{
position: absolute;
width: 65vw;
max-height:7vh;
left: 25vw;
top: 10vh;
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 15px;
line-height: 16px;
color: #000000
}
#icon{
    position: absolute;
width: 90px;
height: 35px;
left: 83vw;
top: 2vh;

font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 18px;
line-height: 21px;
/* identical to box height */


color:#E90859;
}
</style>