<template>
    <div id="container">
        <b-img id="dp"  :src="image"  rounded="circle" :alt="username"></b-img>
    <h1 id="username">{{ username }}</h1>
    <h3 id="bio">
        {{ bio.slice(0,40) }}
    </h3>
    <div id="message">{{ status }}.....</div>
    <b-button id="remove" class="text shadow-none" @click="remove">Remove</b-button>
        </div>
</template>


<script>
// will be modifying this based on the state of the request whehter it is approved or is still pending
export default {
  props:['username','image','bio','status'],
  methods:{
    remove(){
      this.$store.dispatch('user/unsend',{value:this.username})
    }
  }
}
</script>

<style scoped>
#container{
  height: 128px;
position: relative;
width: 100vw;
margin-top:6px;
margin-bottom:1vh;
background: #FFFFFF;
border-bottom:1px solid rgba(0, 0, 0, 0.57);
    }
#dp{
position: relative;
width: 80px;
height: 80px;
margin-left:2vw;
margin-top:2.78vh;
border-radius: 100px;
}
#username{
position: absolute;
width: 124px;
height: 28px;
left:27vw;
top:3.18vh;
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 24px;
line-height: 28px;
overflow: hidden;
color: #000000;
}
#bio{
position: absolute;
width: 65vw;
max-height: 30px;
left: 27vw;
top:55px;
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 16px;
overflow: hidden;
color: #000000;
}
#message{
position: absolute;
width: 30vw;
height: 4vh;
left: 26vw;
top:87px;
min-height: 36px;
font-size:20px;
font-weight: 350;

}
#remove{
position: absolute;
width: 30vw;
height: 4vh;
left: 64vw;
min-height: 36px;
top:87px;
padding:0;
background: #E90859;
border-radius: 6px;
}
</style>