<template>
   <div>
    <h1>{{ msg }}</h1>
    <img :src="require('./hermes.png')" alt="spinner" id="image">
   </div>
</template>
<script>
export default {
    props:['msg']
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Sofia');
#image{
    position: relative;
width: 150px;
height: 150px;
margin-left: 100px;
margin-top: 100px;
animation: bounce 0.5s;
            animation-direction: alternate;
            animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
            animation-iteration-count: infinite;
}
@keyframes bounce {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        transform: translate3d(0, 200px, 0);
    }
}
</style>