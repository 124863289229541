<template>
<div id="container">
    <div id="header">
        <h2>{{ Hobby }}</h2>
        
        <b-icon id="add" icon="plus-lg" @click="open"></b-icon>
    </div>

    <input type="file" ref="file" style="display:none" @change="previewImage" accept="image/*">
    <spinner-comp v-if="Loading"/>
    <div id="images" v-else style="margin-top:10px;">
        <div class="noRes" v-if="Images.length == 0 ">
            No Images
        </div>
        <div id="img-container" v-else>
         <img-comp v-for="image in Images" :key="image" :image="image"/>
        </div>
    </div>
    <del-comp v-if="Delete"/>
</div>    
</template>

<script>
import SpinnerComp from '/src/components/home/spin-loader.vue'
import ImgComp from '/src/components/user/img-comp-del.vue'
import DelComp from './del-buttons.vue'
import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'
export default {
    components:{SpinnerComp,ImgComp,DelComp},
    data(){
            return {
                img1: '',
                imageData: null,
              
            }
        } ,
    computed:{
        Images(){
            return this.$store.getters['user/getHobImages'];
        },
        Hobby(){
            
            if(localStorage.getItem('Hobby'))
            return localStorage.getItem('Hobby')
            else
          return this.$store.getters['user/getSelectedHobby'];
        },
        Loading(){
            return this.$store.getters['user/getLoader'];
        },
        Delete(){
            return this.$store.getters['user/getDelImage']
        }
       
    },
  methods:{
       open(){
        this.$refs.file.click()
       },
       async previewImage(event) {
  this.uploadValue=0;
  this.img1=null;
  this.imageData = event.target.files[0];
 await this.onUpload()

},
async onUpload(){
  try{
    this.$store.commit('user/setLoader',{value:true})
    this.img1=null;
    if(this.imageData != null)
    {
        let user = localStorage.getItem('user');
        let hobby = localStorage.getItem('Hobby')
      const storageRef=firebase.storage().ref(`users/${user}/${hobby}/${this.imageData.name}`).put(this.imageData);
  storageRef.on(`state_changed`,snapshot=>{
  this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
    },
     error=>{console.log(error.message,)},
  
     ()=>{this.uploadValue=100;
      storageRef.snapshot.ref.getDownloadURL().then(async (url)=>{
          this.img1 =url;
          this.$store.commit('user/setHobImages',{value:this.img1})
          localStorage.removeItem('latest-image')
          let images = localStorage.getItem('img-hobbies').trim().split(',')
          images.push(this.img1)
          localStorage.setItem('img-hobbies',images)
          localStorage.setItem('latest-image',this.img1)
          this.$store.commit('user/setLoader',{value:false})
          await this.$store.dispatch('user/addImage')
        });
      }      
    )
    }

  }
  catch(err){
    console.log("unable to upload to cloud storage")
    
  }
 }
   },
}
</script>

<style scoped>
*{overflow-x:hidden}
#container{
    width:100vw;
    overflow-x: hidden;
}
#header{
    width:100vw;
    height:60px;
    background-image: linear-gradient(to right, #270715, #751f2f, #ca0335);
}
#header h2{
    color: white;
    float: left;
    line-height: 60px;
    margin-left:30px;
    font-size: 38px;
    font-family: 'Sofia';
    text-align:center;
}
#add{
    color: white;
    font-size:28px;
    float:right;
    margin-right: 10px;
    margin-top:20px;
    cursor: pointer;
}
.noRes{
    text-align: center;
    font-size:32px;
    font-weight: 350;
    font-family: Arial, Helvetica, sans-serif;
    margin-top:25vh;
    animation:animation ;
    animation-direction: alternate-reverse;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-play-state: running;
}
@keyframes animation{
    from{
        color:black
    }
    to{
        color:rgba(233, 8, 89, 1);

    }
}


</style>