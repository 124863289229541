<template>
    <div id="container">
       <div id="content-container">
        <div id="banner">
            Mood Uplifters
        </div>
        <div class="item" @click="forward('villain')">
            <div class="left"></div>
            <div class="content">
                <div class="image">
                    <img :src="require('/src/assets/trans/evil.png')" alt="evil">
                </div>
                <h3>Villain</h3>
            </div>
            <div class="right"></div>
        </div>
        <div class="item" @click="forward('hero')">
            <div class="left"></div>
            <div class="content">
                <div class="image">
                    <img :src="require('/src/assets/trans/hero.png')" alt="hero">
                </div>
                <h3>Hero</h3>
            </div>
            <div class="right"></div>
        </div>
        <div class="item" @click="forward('sigma')">
            <div class="left"></div>
            <div class="content">
                <div class="image">
                    <img :src="require('/src/assets/trans/sigma.png')" alt="sigma">
                </div>
                <h3>Sigma</h3>
            </div>
            <div class="right"></div>
        </div>
        <div class="item" @click="forward('alpha')">
            <div class="left"></div>
            <div class="content">
                <div class="image">
                    <img :src="require('/src/assets/trans/alphamale.png')" alt="alpha">
                </div>
                <h3>Alpha</h3>
            </div>
            <div class="right"></div>
        </div>
        <div class="item" @click="forward('motivation')">
            <div class="left"></div>
            <div class="content">
                <div class="image">
                    <img :src="require('/src/assets/trans/hustle.png')" alt="motivation">
                </div>
                <h3>Motivate</h3>
            </div>
            <div class="right"></div>
        </div>
        <div class="item" @click="forward('peace')">
            <div class="left"></div>
            <div class="content">
                <div class="image">
                    <img :src="require('/src/assets/trans/peace.png')" alt="peace">
                </div>
                <h3>Peace</h3>
            </div>
            <div class="right"></div>
        </div>
        <div class="item" @click="forward('love')">
            <div class="left"></div>
            <div class="content">
                <div class="image">
                    <img :src="require('/src/assets/trans/cupid.png')" alt="cupid">
                </div>
                <h3>Cupid's tune</h3>
            </div>
            <div class="right"></div>
        </div>
        <div class="item" @click="forward('adrenaline')">
            <div class="left"></div>
            <div class="content">
                <div class="image">
                    <img :src="require('/src/assets/trans/adrenaline.png')" alt="adrenaline">
                </div>
                <h3>Adrenaline rush</h3>
            </div>
            <div class="right"></div>
        </div>
        <div class="item" @click="forward('stoned')">
            <div class="left"></div>
            <div class="content">
                <div class="image">
                    <img :src="require('/src/assets/trans/stoned.png')" alt="stoned">
                </div>
                <h3>Stoned</h3>
            </div>
            <div class="right"></div>
        </div>
        <div class="item" @click="forward('uplift')">
            <div class="left"></div>
            <div class="content">
                <div class="image">
                    <img :src="require('/src/assets/trans/uplift.png')" alt="uplift">
                </div>
                <h3>Uplift</h3>
            </div>
            <div class="right"></div>
        </div>
        <div class="item" @click="forward('sexy')">
            <div class="left"></div>
            <div class="content">
                <div class="image">
                    <img :src="require('/src/assets/trans/sensous.png')" alt="sensous">
                </div>
                <h3>Sensuous</h3>
            </div>
            <div class="right"></div>
        </div>
        <div class="item" @click="forward('vibes')">
            <div class="left"></div>
            <div class="content">
                <div class="image">
                    <img :src="require('/src/assets/trans/vibes.png') " alt="vibes">
                </div>
                <h3>Vibes</h3>
            </div>
            <div class="right"></div>
        </div>
       </div>

        <div class="ocean">
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
          </div>
    </div>
</template>

<script>
export default {
    methods:{
        forward(val){
            this.$store.commit('trans/setTrans',{value:val})
            this.$router.push({name:'trans-page'})
        }
    }
}
</script>

<style scoped>

#container{
width:100vw;
}
#content-container{
    width: 100vw;
    height:90vh;
    overflow-x: hidden;
    overflow-y:auto
}
#content-container::-webkit-scrollbar{
    display: none;
}
#banner{
    width: 100vw;
    height: 60px;
    background:rgb(25, 81, 222) ;
    color: white;
    font-family: 'Sofia';
    font-size: 32px;
    text-align: center;
    font-weight: 450;
    line-height: 60px;
    margin-bottom:10px;
}
.item{
    width: 100vw;
    height:80px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.57);
display: grid;
grid-template-columns: 1.5fr 97fr 1.5fr;
margin-top:6px;
margin-bottom:6px;
}
.left{
    width: 1.5vw;
    float: left;
    margin-left:0px;
    height: 80px;
    background: rgb(25, 81, 222);
}
.right{
    width: 1.5vw;
    float: right;
    margin-right:0px;
    height: 80px;
    background: rgb(25, 81, 222);
}
.content{
    width: 97vw;
    height: 80px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 100px auto;
  }
  .content h3{
    line-height: 80px;
    font-family: 'Sofia';
    font-size:28px;
    font-weight: 400;
    max-width:180px;
    overflow: hidden;
    margin-left:-2px;
  }
  .image{
    width:80px;
    height: 80px;
    margin-left:15px;
  }
  .image img{
    width:40px;
    height:40px;
    margin-left:10px;
    margin-top:20px;
  }
  .item:hover .image img{
    animation: shake;
    animation-delay: .1s;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
  }
  .item:hover .content h3{
    color:rgb(25, 81, 222);
    font-weight: 590;
  }
.ocean {
    height: 80px; /* change the height of the waves here */
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
  }
  
  .wave {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23003F7C'/%3E%3C/svg%3E");
    position: fixed;
    width: 200%;
    height:80px;
    animation: wave 7s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
    overflow-y: hidden;
  }
  
  .wave:nth-of-type(2) {
    bottom: 0;
    animation: wave 18s linear reverse infinite;
    opacity: 0.5;
  }
  
  .wave:nth-of-type(3) {
    bottom: 0;
    animation: wave 20s -1s linear infinite;
    opacity: 0.5;
  }
  
  @keyframes wave {
      0% {transform: translateX(0);}
      50% {transform: translateX(-25%);}
      100% {transform: translateX(-50%);}
  }
  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
</style>