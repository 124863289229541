import { db } from '/src/firebase.js'
import { getDoc,doc,arrayUnion} from "firebase/firestore";
import bcrypt from "bcryptjs";
export default{
    async createMessage({commit,dispatch},payload){
      try{
        let user_id = localStorage.getItem('user')
    let date = await dispatch('getDate')
    commit('setMessage',{value:{'title':payload.title,'date':date,'message':payload.message}})
    await db.collection('self-message').doc(user_id).update({
        messages:arrayUnion({'title':payload.title,'date':date,'message':payload.message})
      })
      return true;
    }
   catch(err){
    console.log(err)
    return false;
   }
    },
    async getDate(){
        let today = new Date()
        let month = today.getMonth();
        let day = today.getDate();
      
        let months = {
          1:'January',
          2:'February',
          3:'March',
          4:'April',
          5:'May',
          6:'June',
          7:'July',
          8:'August',
          9:'September',
          10:'October',
          11:'November',
          12:'December',
      
        }
        return `${day}-${months[month]}-${today.getFullYear()}`
      },
    async getMessages({commit,getters}){
        let user_id = localStorage.getItem('user');
        const q = doc(db,'self-message',user_id);
            let user = await getDoc(q)
            let messages = user.data().messages;
            if(messages.length > getters['getMessages'].length)
            messages.forEach(message=>{
                commit('setMessage',{value:message})
            })
            
    },
    async removeMessage({commit,getters},payload){
      let message=getters['getMessages']
      let msg=message[payload.value]
      console.log(msg)
      message=message.filter(val=>{
        return val.title != msg.title;
      })
       commit('removeMsg',{value:payload.value})
       let user_id = localStorage.getItem('user');
       await db.collection('self-message').doc(user_id).update({
        messages:message
      })
    },
    async verify(context,payload){
      let password = payload.value;
      // fetching the user passsworf first
      let user = localStorage.getItem('user');
      let details = await db.collection('users').doc(user).get();
      let hash = details.data().password
      //comparing the password
    let res = await bcrypt.compare(password,hash);
    return res;
    }
}