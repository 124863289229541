<template>
  <trans-loader v-if="Load" />
    <div id="container" v-else>
      <h3 id="header"> {{ Catg }}</h3>
      <p id="desc"> Get in mood with these {{ Catg }} tunes </p>
      <div class="item" v-for="image,index in Images" :key="index" @click="forward(index)"> 
        <img :src="image" :alt="image">
      </div>
    </div>
</template>

<script>
// ismei ek animated loader bhi add kardena while the covers are being loaded
import TransLoader from './trans-loader.vue'
export default {
  components:{TransLoader},
  data(){
    return{
         songs_url:'https://console.firebase.google.com/u/0/project/sandesha-7e622/storage/sandesha-7e622.appspot.com/files/~2Fsongs~2Fvillain',
         images_url:'https://console.firebase.google.com/u/0/project/sandesha-7e622/storage/sandesha-7e622.appspot.com/files/~2Fbackgrounds~2Fvillain',
         songs:[],
         images:[],
         loading:false
        }
  }  ,
  computed:{
    Songs(){
      return this.$store.getters['trans/getSongs'];
    },
    Images(){
      return this.$store.getters['trans/getImages']
    },
    Catg(){
      return this.$store.getters['trans/getTrans'].toUpperCase()
    },
    Load(){
      return this.loading;
    }
  },
  methods:{
    forward(val){
      this.$store.commit('trans/setIndex',{value:val});
      this.$router.push({name:'trans-player'})
    }
  },
  async mounted(){
    this.loading = true;
    if(this.$store.getters['trans/getSongs'].length == 0)
    await this.$store.dispatch('trans/getSongs',{value:this.$store.getters['trans/getTrans']})
    if(this.$store.getters['trans/getImages'].length == 0)
    {
      await this.$store.dispatch('trans/getImages',{value:this.$store.getters['trans/getTrans']})
      await this.$store.dispatch('trans/getQuotes',{value:this.$store.getters['trans/getTrans']})
    }
    this.loading=false;
  },
  
}
</script>


<style scoped>
#container{
  width:100vw;
  height: auto;
  
}
#header{
  width:100vw;
  height:50px;
  text-align: center;
  font-family: 'Sofia';
  color: white;
  font-weight: 400;
  font-size:32px;
  line-height: 50px;
  background: rgb(25, 81, 222);
  margin-bottom:0px;
}
#desc{
  width: 100vw;
  height: 30px;
  margin-top:0px;
  text-align: center;
  line-height: 30px;
  font-family: 'Sofia';
  color: white;
  font-weight: 400;
  background: rgb(25, 81, 222);
}
.item{
  width:96vw;
  height:300px;
  border-radius: 20px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.595);
  margin-left:2vw;
  margin-top:10px;
  margin-bottom:5px;
}
.item img{
  width:96vw;
  height:300px;
  border-radius: 20px;
}
</style>