<template>
    <div id="container">
        <div id="header">
            <h2>Contact Analysis</h2>
            <b-icon id="add" icon="plus-lg" @click="toggle"></b-icon>
        </div>
        <bar-chart></bar-chart>
        <hobbie-chart1></hobbie-chart1>
        <hobbie-chart2></hobbie-chart2>
        <hobbie-chart3></hobbie-chart3>
        <hobbie-chart4></hobbie-chart4>
        <hobbie-chart5></hobbie-chart5>
        <hobbie-chart6></hobbie-chart6>
        <hobbie-chart7></hobbie-chart7>
        <hobbie-chart8></hobbie-chart8>
        <hobbie-chart9></hobbie-chart9>
        <hobbie-chart10></hobbie-chart10>
        <hobbie-chart11></hobbie-chart11>
        <hobbie-chart12></hobbie-chart12>
        <hobbie-chart13></hobbie-chart13>
    </div>
</template>


<script>
import BarChart from '/src/components/user/gender-chart.vue'
import HobbieChart1 from '/src/components/user/hobbies-bubble1.vue'
import HobbieChart2 from '/src/components/user/hobbies-bubble2.vue'
import HobbieChart3 from '/src/components/user/hobbies-bubble3.vue'
import HobbieChart4 from '/src/components/user/hobbies-bubble4.vue'
import HobbieChart5 from '/src/components/user/hobbies-bubble5.vue'
import HobbieChart6 from '/src/components/user/hobbies-bubble6.vue'
import HobbieChart7 from '/src/components/user/hobbies-bubble7.vue'
import HobbieChart8 from '/src/components/user/hobbies-bubble8.vue'
import HobbieChart9 from '/src/components/user/hobbies-bubble9.vue'
import HobbieChart10 from '/src/components/user/hobbies-bubble10.vue'
import HobbieChart11 from '/src/components/user/hobbies-bubble11.vue'
import HobbieChart12 from '/src/components/user/hobbies-bubble12.vue'
import HobbieChart13 from '/src/components/user/hobbies-bubble13.vue'
export default {
    components:{BarChart,HobbieChart1,HobbieChart2,HobbieChart3,
        HobbieChart4,HobbieChart5,HobbieChart6,
        HobbieChart7,HobbieChart8,HobbieChart9,
        HobbieChart10,HobbieChart11,HobbieChart12,HobbieChart13},
data(){
    return{
        genders:[]
    }
},
        async mounted(){
   await this.$store.dispatch('user/contactAnalysis')
   
},

}
</script>

<style scoped>
#container{
    width: 100vw;
}
#header{
    width:100vw;
    height:60px;
    background-image: linear-gradient(to right, #29130c, #63322b, #3e2924);
}
#header h2{
    color: white;
    text-align: center;
    line-height: 60px;
    margin-left:30px;
    font-size: 38px;
    font-family: 'Sofia';
}
</style>