<template>
    <div id="container">
        <div id="header">
            <h2>{{ Personality }}</h2>
        </div>
        <about-comp :about="about"/>
<history-comp :history="history"></history-comp>
<factor-comp :factors="factors"></factor-comp>
    </div>
</template>


<script>
import AboutComp from '/src/components/personality/about-comp.vue'
import HistoryComp  from '/src/components/personality/history-comp.vue'
import FactorComp from '/src/components/personality/factors-comp.vue'
export default {
    components:{AboutComp,HistoryComp,FactorComp},
    data(){
        return{
            personality:'',
            about:`The HEXACO model of personality structure is a six-dimensional model of human personality that was created by Ashton and Lee and explained in their book, The H Factor of Personality,[1] based on findings from a series of lexical studies involving several European and Asian languages. The six factors, or dimensions, include Honesty-Humility (H), Emotionality (E), Extraversion (X), Agreeableness (A), Conscientiousness (C), and Openness to Experience (O). Each factor is composed of traits with characteristics indicating high and low levels of the factor. The HEXACO model was developed through similar methods as other trait taxonomies and builds on the work of Costa and McCrae[2] and Goldberg.[3] The model, therefore, shares several common elements with other trait models. However, the HEXACO model is unique mainly due to the addition of the Honesty-Humility dimension.
            The HEXACO model of personality conceptualizes human personality in terms of six dimensions.

The HEXACO model was developed from several previous independent lexical studies. Language-based taxonomies for personality traits have been widely used as a method for developing personality models. This method, based on the logic of the lexical hypothesis, uses adjectives found in language that describe behaviours and tendencies among individuals. Factor analysis is used on the adjectives to identify a minimal set of independent groups of personality traits.[5]

Research studies based on the lexical hypothesis described above were first undertaken in the English language. Subsequent lexical studies were conducted in other languages and, by comparing the results, six emergent factors were revealed in similar form across all languages tested, including English.[6][7]

Personality is often assessed using a self-report inventory or observer report inventory. The six factors are measured through a series of questions designed to rate an individual on levels of each factor.[8] Ashton and Lee have developed self- and observer report forms of the HEXACO Personality Inventory-Revised (HEXACO-PI-R).[9] The HEXACO-PI-R assesses the six broad HEXACO personality factors, each of which contains four "facets", or narrower personality characteristics. (An additional 25th narrow facet, called Altruism, is also included and represents a blend of the Honesty-Humility, Emotionality, and Agreeableness factors.)`,
            history:`The HEXACO Personality Inventory was developed in 2000 by Canadian psychology professors Kibeom Lee, Ph.D. & Michael C. Ashton, Ph.D. 

The inventory’s foundations stem from research into five core personality traits. These have been a major area of study in psychology by researchers such as Donald Fiske in the 1940s and Dr. Paul Costa and Dr. Robert McCrae in the 1980s.

The “Big 5,” as these core traits are called, served as the theoretical basis upon which Ashton & Lee created the HEXACO Personality Inventory.

However, when Ashton & Lee looked at other languages and cultures, they added the Honesty-Humility factor.2 

Since then, the HEXACO Personality Test has been used widely, and has been translated into numerous languages. `,
 factors:{
        'Honesty-Humility':{
            'about':'Those who score high on this trait seldom break rules, rarely deceive others for personal benefit, aren’t interested in luxury or lavishness, and don’t feel entitled to a high social status. Conversely, those who score low have an inflated sense of self-importance, flatter others to get what they desire, bend rules for personal gain, and are materialistic. ',
        'types':{'Honesty-Humility High':'Seldom break rules, rarely deceive others for personal benefit, aren’t interested in luxury or lavishness, and don’t feel entitled to a high social status.',
        'Honesty-Humility Low':'Have an inflated sense of self-importance, flatter others to get what they desire, bend rules for personal gain, and are materialistic. '}}
    ,
    
        'Emotionality':{about:'Participants with high scores in this domain tend to experience more anxiety, crave more emotional support, feel deeper empathy towards others, and fear physical dangers. Those who score low on emotionality aren’t fearful of physical harm, feel more detached from others, experience little distress, and aren’t inclined to open up to others.',
        types:{
            'Emotionality Low':'Tend to experience more anxiety, crave more emotional support, feel deeper empathy towards others, and fear physical dangers.',
        'Emotionality High':'Aren’t fearful of physical harm, feel more detached from others, experience little distress, and aren’t inclined to open up to others.'
        }}
    ,
    
        'Extraversion':{about:'Individuals who score high on extraversion are confident when leading others, feel energized and motivated when interacting with people, view themselves in a positive light, and thrive in social situations. Those who score low on this domain may believe themselves to be unpopular, feel depleted in social situations, feel uncomfortable when they’re the center of attention, and don’t experience as much outward enthusiasm and optimism. ',
        types:{
            'Extraversion High':'Are confident when leading others, feel energized and motivated when interacting with people, view themselves in a positive light, and thrive in social situations.',
        'Extraversion Low':'May believe themselves to be unpopular, feel depleted in social situations, feel uncomfortable when they’re the center of attention, and don’t experience as much outward enthusiasm and optimism. '
        }}
    ,
    
        'Agreeableness':{about:'People who have high scores on this trait are more inclined to compromise, can manage their temper, judge others with leniency, and can forgive easily. Those with low scores are stubborn and tend to hold grudges against those who have hurt them, criticize others, and become angered easily when they are mistreated. ',
    types:{
        'Agreeableness High':'Are more inclined to compromise, can manage their temper, judge others with leniency, and can forgive easily.',
        'Agreeableness Low':'Are stubborn and tend to hold grudges against those who have hurt them, criticize others, and become angered easily when they are mistreated. '
    }
},
'Conscientiousness':{about:'Individuals with high conscientiousness scores consider decisions carefully, aim for perfection and accuracy, possess discipline when working towards goals, and are organized with their time and surroundings. Those who score low on this domain let errors slide, make decisions impulsively, don’t give as much attention to their time or surroundings, and are deterred by challenging goals or tasks. ',
    types:{
        'Conscientiousness High':'Consider decisions carefully, aim for perfection and accuracy, possess discipline when working towards goals, and are organized with their time and surroundings.',
        'Conscientiousness Low':'Let errors slide, make decisions impulsively, don’t give as much attention to their time or surroundings, and are deterred by challenging goals or tasks. '
    }
},
'Openness to Experience':{about:'People scoring high on this trait are imaginative, drawn towards unconventional people or ideas, immerse themselves in nature and art, and inquire about different knowledge bases. Those with low scores aren’t creative, stray away from radical ideas, aren’t interested in artwork, and don’t experience intellectual curiosity. ',
    types:{
        'Experience High':'Are imaginative, drawn towards unconventional people or ideas, immerse themselves in nature and art, and inquire about different knowledge bases.',
        'Experience Low':'Aren’t creative, stray away from radical ideas, aren’t interested in artwork, and don’t experience intellectual curiosity. '
    }
}
}
        }
    },
    computed:{
        Personality(){
            return this.personality;
        }
    },
    mounted(){
this.personality=localStorage.getItem('test');
    }
}
</script>

<style scoped>
#container{
    width:100vw;
    overflow-x: hidden;
    height: 100vh;
}
#header{
    width:100vw;
    height:60px;
    background-image: linear-gradient(to right, #102216, #156039, #03ca8b);
}
#header h2{
    color: white;
    line-height: 60px;
    font-size: 38px;
    font-family: 'Sofia';
    text-align:center;
}
</style>