<template>
   <div id="grp-container">
    <h2 id="logo">Sandesha</h2>
    <search-bar></search-bar>
<div id="groups" v-if="groups.length>0">
    <group-comp v-for="group in Groups" :key="group.grpId" :name="group.name" :image="group.wallpaper" :grpid="group.id"></group-comp>
</div>
<div class="noRes" v-else>
    No Communities
</div>
<spinner-comp v-if="Loader"/>
   </div>
</template>

<script>
import GroupComp from '/src/components/groups/group-comp.vue'
import SearchBar from '/src/components/groups/search-comp.vue'
import SpinnerComp from '/src/components/home/spin-loader.vue'
export default {
    components:{GroupComp,SearchBar,SpinnerComp},
    data(){
        return{
            groups:[]
        }
    },
    computed:{
        Groups(){
            return this.groups;
        },
        Loader(){
            return this.$store.getters['group/getloader']
        }
    },
    async mounted(){
        this.groups = await this.$store.dispatch('group/fetchGroups')
        console.log(this.groups);
    }
}
</script>

<style scoped>
#grp-container{
    overflow-y:auto;
    position: relative;
    width: 100vw;
    height: 90vh;
}
#grp-container::-webkit-scrollbar{
    display: none;
    visibility: hidden;
}
@import url('https://fonts.googleapis.com/css?family=Sofia');

#logo{
width: 87px;
height: 33px;
margin-left: 6px;
margin-top: 7px;

font-family: 'Sofia';
font-style: normal;
font-weight: 400;
font-size: 21px;
line-height: 33px;
background: linear-gradient(95.76deg, #1708C2 2.71%, rgba(147, 12, 174, 0.94825) 54.21%, rgba(233, 8, 89, 0.89) 112.18%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.noRes{
    text-align: center;
    font-size:32px;
    font-weight: 350;
    font-family: Arial, Helvetica, sans-serif;
    margin-top:25vh;
    animation:animation ;
    animation-direction: alternate-reverse;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-play-state: running;
}
@keyframes animation{
    from{
        color:black
    }
    to{
        color:rgba(233, 8, 89, 1);

    }
}
</style>