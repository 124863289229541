<template>
    <div id="container" >
        <b-img id="dp"  :src="image"  :alt="username"></b-img>
    <h1 id="username" @click="view(username)">{{ username }}</h1>
    <b-button class="button" id="message"  @click="connect" >{{ State }}</b-button>
    <b-button class="button" id="remove"  @click="remove_user">Remove</b-button>
        </div>
</template>


<script>
export default {
  props:{image:{
    
  },username:{},
},
data(){
  return{
    status:false
  }
},
computed:{
  State(){
    if(this.status){
      return 'Sent'
    }
    else{
      return 'Connect'
    }
  }
},
  methods:{
    async connect(){
      this.status = true;
      this.status = await this.$store.dispatch('user/sendRequest',{value:this.username})
    },
    remove_user(){
      this.$store.dispatch('user/removeUser',{value:this.username})
    },
    view(val){
      this.$router.push(`/profile-view/${val}`)
    }
  }
}
</script>

<style scoped>
#container{
  height: 90px;
  position: relative;
  width: 100vw;
  margin-top:1.5vh;
  margin-bottom:1.5vh;
  background: #FFFFFF;
  border-bottom:1px solid rgba(0, 0, 0, 0.57);
  }
  #dp{
    margin-left:6px;
    margin-top:5px;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  }
  #username{
    position: absolute;
    left:27vw;
    height:28px;
    top:2.18vh;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    }
    #message{
    position: absolute;
    width: 30vw;
    height: 35px;
    left: 24vw;
    top:50px;
    margin-left:3vw;
    padding:0;
    background: #E90859;
    border-radius: 6px;
    }
    #remove{
      position: absolute;
      width: 30vw;
      height: 35px;
      left: 64vw;
      top:50px;
      padding:0;
      background: #E90859;
      border-radius: 6px;
      }
      
  .button:hover{
    background: #370014;
    font-weight:500;
    font-size:18px;
  }
</style>