//
//
import { getAuth, signInWithEmailAndPassword,createUserWithEmailAndPassword, deleteUser ,sendPasswordResetEmail  } from "firebase/auth";
import { db } from '/src/firebase.js'
import { doc,setDoc} from "firebase/firestore";
import { collection, query, where, getDocs,getDoc,deleteDoc} from "firebase/firestore";
import { ref, deleteObject,getStorage } from "firebase/storage";
import bcrypt from "bcryptjs";
import router from '../../router/router'
/*

always keep this in mind that the firebase app needs to be initialzed
first and we do so by calling it by importig db
if you try to access any class before intilizing the app it is not going to work
*/
export default {
    // these are the login,signup and authentication actions

    // this is the signup actions
    async signup1(context, payload) {
        //setting the vars temp in localstoraeg
        localStorage.setItem('sign-name',payload.userName)
        localStorage.setItem('sign-mail',payload.mail)
        localStorage.setItem('sign-password',payload.password)
        router.push({ 'name': 'user-details' })
    },
    async signup2({getters}){
        let bio = getters['getStatus']
        let gender = getters['getGender']
        let likes = getters['getLikes']
        let dislikes = getters['getDislikes']
        localStorage.setItem('sign-bio',bio)
        localStorage.setItem('signGender',gender)
        localStorage.setItem('signLikes',likes)
        localStorage.setItem('signDislikes',dislikes)
        router.push({ 'name': 'user-images' })
    },
    async signup({commit,dispatch},payload){
        router.push({name:'creating-user'})
        commit('setLoader', { value: true });
        let hobbies = payload.value
        let bio = localStorage.getItem('sign-bio')
        let username = localStorage.getItem('sign-name')
        let usermail = localStorage.getItem('sign-mail')
        let userpassword = localStorage.getItem('sign-password')
        let userLikes = localStorage.getItem('signLikes')
        let userDislikes = localStorage.getItem('signDislikes')
        let profilePic = localStorage.getItem('signprofileImage')
        let coverPic = localStorage.getItem('signcoverImage')
        let gender = localStorage.getItem('signGender')
        await dispatch('clearStorage')
        // trying to create user if anythin goes wrong will delte data
        try{
            const auth = getAuth();
            var hashedPassword  = await bcrypt.hash(userpassword,13)
            const user = await createUserWithEmailAndPassword(auth, usermail, userpassword)
            await setDoc(doc(db, "users", `${user._tokenResponse.localId}`), {
                "username": username,
                "password": hashedPassword,
                "mail": usermail,
                "gender":gender,
                'bio': bio,
                'likes': userLikes,
                'hates': userDislikes,
                'hobbies': hobbies,
                'coverImage':coverPic,
                'profileImage':profilePic,
                 // this will be timed messages
                'blocked':[],
                'contacts':0,
                'communities':[]
            });
            await setDoc(doc(db,'friends',`${user._tokenResponse.localId}`),{});
            await setDoc(doc(db,'suggestions',`${user._tokenResponse.localId}`),{});
            await setDoc(doc(db,'requests',`${user._tokenResponse.localId}`),{
                sent:[],
                received:[]
            });
            await setDoc(doc(db,'notifications',`${user._tokenResponse.localId}`),{});
            await setDoc(doc(db,'notes',`${user._tokenResponse.localId}`),{});
            await setDoc(doc(db,'personality',`${user._tokenResponse.localId}`),{});
            await setDoc(doc(db,'self-message',`${user._tokenResponse.localId}`),{});
            await setDoc(doc(db,'hobbies',`${user._tokenResponse.localId}`),{});
            await setDoc(doc(db,'bot',`${user._tokenResponse.localId}`),{});
            commit('setLoader', { value: false });
            router.push({ 'name': 'login' })
        }
        catch(err){
              // will roll back and delte the user images and auth and other things if created
              // deleting the auth
            console.log('error creating user')
              let authResult = await dispatch('deleteAuth')
              
              // deleting the user
              const q = query(db(collection,'users'),where('username', '==' , username))
              let users = await getDocs(q)
              if(users.length>0){
                let id = users.docs[0].id
                await deleteDoc(doc(db, "users", id));
                // deleting other docs if user exists
               await deleteDoc(doc(doc,'friends',id));
               await deleteDoc(doc(doc,'suggestions',id));
               await deleteDoc(doc(doc,'requests',id));
               await deleteDoc(doc(doc,'notifications',id));
               await deleteDoc(doc(doc,'notes',id));
               await deleteDoc(doc(doc,'personality',id));
                router.push({name:'signup'})
              }
              
              // deleting the images
              let storage=await dispatch('deleteStorage',{profile:profilePic,cover:coverPic})
              console.log(storage)
              console.log(authResult)
              console.log()
              commit('setLoader', { value: false });
              console.log(err)
        }
    },
    
    async clearStorage(){
        localStorage.removeItem('sign-bio')
        localStorage.removeItem('sign-name')
        localStorage.removeItem('sign-mail')
        localStorage.removeItem('sign-password')
        localStorage.removeItem('signLikes')
        localStorage.removeItem('signDislikes')
        localStorage.removeItem('signprofileImage')
        localStorage.removeItem('signcoverImage')
        localStorage.removeItem('signGender')
    },
    // this will be used to check if username is already taken
    async checkUser(context, payload) {
        try {
            const q = query(collection(db, "users"), where("username", "==", payload.name));
            let user = await getDocs(q);
            if (user.docs.length == 0)
                return true;
            else
                return false;
        } catch (err) {
            // need to handle error here
            return false;
        }
    },

    // this will check if the mail is already taken or not
    async checkEmail(context, payload) {
        try {
            const q = query(collection(db, "users"), where("mail", "==", payload.email));
            let user = await getDocs(q);
            if (user.docs.length == 0)
                return true;
            else
                return false;
        } catch (err) {
            // need to handle error here
            return false;
        }
    },

    // this is the login action
    async login(context, payload) {
        const auth = getAuth();
        try {
            //this is just a simple check and will be used in some other cases when user wants to change password
            const user = await signInWithEmailAndPassword(auth, payload.email, payload.password);
            localStorage.setItem('token', user._tokenResponse.idToken);
            localStorage.setItem('expires', user._tokenResponse.expiresIn);
            localStorage.setItem('user', user._tokenResponse.localId);
            localStorage.setItem('refresehToken', user._tokenResponse.refreshToken);
            context.commit('setUser', {
                idToken: user._tokenResponse.idToken,
                refreshToken: user._tokenResponse.refreshToken,
                localId: user._tokenResponse.localId,
                expiresIn: user._tokenResponse.expiresIn
            })
            // before production add image to the local storage while login
            let expires = +user._tokenResponse.expiresIn * 1000; //converting to miliseconds
            let expirationDate = new Date().getTime + expires;
            //storing this in local storage
            localStorage.setItem('expiratonDate', expirationDate);
            context.commit('setAuth', { value: true });
            try{
                await context.dispatch('setUser')
            }
            catch(err){
                console.log(err)
            }
            router.push('home')
            return { "status": 'OK' };
        } catch (err) {
            if (err.code === 'auth/wrong-password') {
                return { "status": "password" }
            } else {
                return { "status": "system" }
            }
            // need to handle error here
        }
    },
    // this will return the coressponding email to the username
    async getMail(context, payload) {
        try {
            const q = query(collection(db, "users"), where("username", "==", payload.userName));
            let mail = await getDocs(q);
            if (mail.size >= 1) {
                return { 'status': "OK", "mail": mail.docs[0].data()['mail'] }
            } else {
                return { "status": "No user found", "mail": "" }
            }
        } catch (err) {
            this.$log.error(`failed to fetch user ${payload.userName} mail :${err}`);
            // in here raise the system fault notification!
        }
    },
    // this function is for autologin
    async autoLogin(context) {
        try {
            let expiry = +localStorage.getItem('expiratonDate') - new Date().getTime();
            if (expiry <= 0) {
                context.dispatch('logout');
                return false;
            } else {
                let token = localStorage.getItem('token');
                let Uid = localStorage.getItem('user');
                let refreshToken = localStorage.getItem('refresehToken');
                let expiresIn = localStorage.getItem('expires');
                let expires = +expiresIn * 1000; //converting to miliseconds
                let expirationDate = new Date().getTime + expires;
                //storing this in local storage
                localStorage.setItem('expiratonDate', expirationDate);
                context.commit('setUser', {
                    idToken: token,
                    refreshToken: refreshToken,
                    localId: Uid,
                    expiresIn: expiresIn
                })
                context.commit('setAuth', { value: true });

                router.push({
                    name: 'home'
                })
                return true;

            }
        } catch (err) {
            return false;
        }

    },
    // this function is for auotlogout
    // this is the logout function
    // make sure that you set all the states to intial values in production
    async logout(context) {
        localStorage.clear();
        localStorage.removeItem('token');
        localStorage.removeItem('expires');
        localStorage.removeItem('user');
        localStorage.removeItem('refresehToken');
        localStorage.removeItem('expiratonDate');
        localStorage.removeItem('likes')
        localStorage.removeItem('gender')
        localStorage.removeItem('cover')
        localStorage.removeItem('hobbies')
        localStorage.removeItem('status')
        localStorage.removeItem('image')
        localStorage.removeItem('userName')
        localStorage.removeItem('communities')
        localStorage.removeItem('profile')
        localStorage.removeItem('dislikes')
        localStorage.removeItem('contacts')
        context.commit('auth/setUser', {
            idToken: '',
            refreshToken: '',
            localId: '',
            expiresIn: ''
        })
        context.commit('auth/setAuth', { value: false });
        context.commit('auth/setUsername',{value:''});
        context.commit('auth/setStatus',{value:''});
        context.commit('auth/setProfileImage',{value:''});
        context.commit('auth/resetMail',{value:''});


    },
    //this function will set the user details in the local storage
    async setUser(){
        let user = localStorage.getItem('user')
        const userRef = doc(db, "users", user);
        let credentials = await getDoc(userRef);
        let data = credentials.data();
        localStorage.setItem('userName',data.username)
        localStorage.setItem('profile',data.profileImage)
        localStorage.setItem('status',data.bio)
        localStorage.setItem('gender',data.gender)
        localStorage.setItem('likes',data.likes)
        localStorage.setItem('dislikes',data.hates)
        localStorage.setItem('hobbies',data.hobbies)
        localStorage.setItem('cover',data.coverImage)
        let contacts = await db.collection('friends').doc(localStorage.getItem('user')).get();
        localStorage.setItem('contacts',contacts.data().list)
        localStorage.setItem('communities',data.communities)
        // setting up the user details in the local browser details
        
    },
    // this function is for updating the user data
    async updateUser(context,payload,dispatch){
        let userName = context.getters['getUsername']
        let bio = context.getters['getStatus']
        let gender = context.getters['getGender']
        let likes = context.getters['getLikes']
        let dislikes = context.getters['getDislikes']
         let interests = payload.interests
         const q = query(collection(db, "users"), where("username", "==", userName));
            let user = await getDocs(q);
            const docId = user.docs[0].id

        try {
            await db.collection('users').doc(docId).update({
                bio:bio,
                likes:likes,
                hates:dislikes,
                hobbies:interests,
                gender:gender,
                image:context.getters.getProfileImage
            })

            router.push({name:'login'})
        } catch (err) {
            // need to handle error here
            this.$log.error(`failed to update user credentials:${err}`);
           await dispatch('rollBack')
            router.push({name:'profile'})

            // in this case we will delete the document and rewrite the user to signup page

        }

        //update the fetched document
        
    },
    // this is a rollback function to ensure atomicity
    // this rollback is giving error fix during production
    async rollBack(dispatch){
        let authStatus = await dispatch('deleteAuth')
        let firebaseStatus = await dispatch('deleteFirebase')
        let storageStatus = await dispatch('deleteStorage')

        if (authStatus !== ''){
            this.$log.error(authStatus)
        }
        if(firebaseStatus !== ''){
            this.$log.error(firebaseStatus)
        }
        if(storageStatus !== ''){
            this.$log.error(storageStatus)
        }
    },
    async deleteAuth(){
        const auth = getAuth();
        const user = auth.currentUser;
         try{
            let res = await deleteUser(user)
            if(res)
            return 'sucessfully deleted the auth user';
            else
            return `failed to delete auth for ${auth.currentUser}`;
         }
catch(err){
    return err
}
    },
    async deleteFirebase(context){
         let user = context.getters.getUsername
         try{
            const q = query(collection(db, "users"), where("username", "==", user));
            let res = await deleteDoc(q);
            if(res)
            return true
            else
            return `need to delete the user data for ${user}`

         }
         catch(err){
            return `need to delete the user data for ${user}`
         }

    },
    async deleteStorage(context,payload){
        const storage = getStorage();
        try{
            // Create a reference to the file to delete
        const profileRef = ref(storage, payload.profile);

        let res = await deleteObject(profileRef)
         const coverRef = ref(storage, payload.cover);

         let res_ = await deleteObject(coverRef)
          if(res_ && res){
             return true;
          }
          else{
             return `delete image ${payload.profile} and ${payload.cover}`
          }
        }
        
        catch(err){
            return `delete image ${payload.profile} and ${payload.cover}`
        }
    },
    async userHome(context){
       context.commit('setUsername',{value:localStorage.getItem('userName')})
       context.commit('setStatus',{value:localStorage.getItem('status')})
       context.commit('setProfileImage',{value:localStorage.getItem('image')})
    },
    async sendMail(context){
        let mail = context.getters.getResetMail
        try{
            let auth = getAuth()
            // the thing is that these mails are getting forwarded to spams sambhog!!
            let res = await sendPasswordResetEmail(auth,mail)
            console.log(res)
            return true;
        }
        catch(err){
            console.log(err)
            this.$log.error('failed to send reset mail',err);
            return false;
        }
    }
}

 