<template>
  <div id="container">
    <keep-alive>
        <component :is="Holder"></component>
    </keep-alive>
    <div id="toggler">
      <div class="toggle-item" :class="[Holder == 'UserChat'?'active':'']" @click="toggle('UserChat')" >Chats</div>
      <div class="toggle-item" :class="[Holder == 'GroupsPage'?'active':'']" @click="toggle('GroupsPage')" >Communities</div>
      <div class="toggle-item" :class="[Holder == 'ConnectionsPage'?'active':'']" @click="toggle('ConnectionsPage')">Connections</div>
      <div class="toggle-item" :class="[Holder == 'SuggestionsPage'?'active':'']" @click="toggle('SuggestionsPage')">Suggestions</div>
      <div class="toggle-item" :class="[Holder == 'UserPage'?'active':'']" @click="toggle('UserPage')">User</div>
      <div class="toggle-item" :class="[Holder == 'NotificationPage'?'active':'']" @click="toggle('NotificationPage')">Notfication</div>
      <div class="toggle-item" :class="[Holder == 'NotesPage'?'active':'']" @click="toggle('NotesPage')">Notes</div>
    </div>
  </div>
</template>

<script>
import UserChat from '../user/user-chats.vue'
import ConnectionsPage from '../contacts/friends-page.vue'
import UserPage from '../user/profile-page.vue'
import NotesPage from '../landing/notes-page.vue'
import SuggestionsPage from '../contacts/suggestions-page.vue'
import GroupsPage from '../group/groups-page.vue'
import NotificationPage from '../landing/notifications-page.vue'
export default {
    components:{
        UserChat,
        ConnectionsPage,
        UserPage,
        NotesPage,
        SuggestionsPage,
        GroupsPage,
        NotificationPage
    },
    computed:{
        Holder(){
            return this.holder;
        }
    },
    methods:{
        toggle(val){
            this.holder = val;
        }
    },
    data(){
        return{
            holder:'UserChat'
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Sofia');
#container{
    width: 100vw;
    overflow-x:hidden;
    overflow-y:auto;
}
#container::-webkit-scrollbar{
    display: none;
    visibility: hidden;
}
#toggler{
    position: fixed;
    bottom: 0px;
width: 100vw;
z-index: 0;
height: 8vh;
background: rgb(219, 7, 96);
box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.541);
display: flex;
flex-direction: row;
overflow-y: hidden;
overflow-x: auto;
padding-left:3vw;
padding-right:3vw;
}
#toggle::-webkit-scrollbar{
    display: none;
}
.toggle-item{
    font-size:22px;
    font-weight: 450;
    font-family: 'Sofia';
    line-height: 60px;
    margin-left:2vw;
    margin-right:2vw;
    color:white
}
.active{
    animation: activate;
    animation-duration: .4s;
    animation-fill-mode: forwards;
}
@keyframes activate{
    from{
        font-size:22px;
    font-weight: 450;
    }
    to{
        font-size:34px;
        font-weight: 600;
    }
}
</style>