<template>
    <div id="container">
        <b-img id="dp"  :src="image"  rounded="circle" :alt="username" v-if="image"></b-img>
        <b-img id="dp"  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSd6k0U8FTrKucw1yfjUy6JPyzjsKurvW2xg&usqp=CAU"  rounded="circle" :alt="username" v-else></b-img>
    <h1 id="username" @click="message">{{ name }}</h1>
    <b-button id="message" class="text shadow-none" @click="message" >Message</b-button>
    <b-button id="remove" class="text shadow-none" @click="remove">Mute</b-button>
        </div>
</template>


<script>
export default {
  props:['name','image','grpid'],
  methods:{
    message(){
      this.$router.push(`/group/${this.grpid}`)
    },
  }
}
</script>

<style scoped>
  #container{
height: 90px;
position: relative;
width: 100vw;
margin-top:1.5vh;
margin-bottom:1.5vh;
background: #FFFFFF;
border-bottom:1px solid rgba(0, 0, 0, 0.57);
}
#dp{
  margin-left:6px;
  margin-top:5px;
width: 80px;
height: 80px;
border-radius: 80px;
}
#username{
position: absolute;
left:27vw;
height:28px;
top:1.18vh;
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 24px;
line-height: 28px;
color: #000000;
}
#message{
position: absolute;
width: 30vw;
height: 35px;
left: 24vw;
top:50px;
margin-left:3vw;
padding:0;
background: #E90859;
border-radius: 6px;
}
#remove{
position: absolute;
width: 30vw;
height: 35px;
left: 64vw;
top:50px;
padding:0;
background: #E90859;
border-radius: 6px;
}

</style>