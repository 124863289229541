<template>
<keep-alive>
    <component :is="Curr_Comp"></component>
</keep-alive>
</template>

<script>
import AddMembers from '/src/components/newGroup/add-contact.vue'
import AddDetails from '/src/components/newGroup/group-details.vue'
export default {
    components:{
        AddMembers,
        AddDetails
    },
    computed:{
        Curr_Comp(){
            return this.$store.getters['group/get_curr_comp']
        }
    },

}
</script>

<style scoped>

</style>