
import { db } from '/src/firebase.js'
import { doc,setDoc,arrayUnion,updateDoc} from "firebase/firestore";
import router from '../../router/router'
export default{

    async createGrp({dispatch},payload){
        let image = payload.image;
        let grpName = payload.name;
        let grpDescription = payload.description;
        let members = payload.members
        let notif = members;
        members=members.concat(localStorage.getItem('user'))
        let strength = payload.strength;
        let admin = localStorage.getItem('user');
        try{
            let id = admin+Date.now()
           await setDoc(doc(db,'groups',id),{
                wallpaper:image,
                name:grpName,
                description:grpDescription,
                members:members,
                strength:strength,
                admin:admin,
                images:[],
                videos:[],
                files:[],
                chats:'',
                notifications:[`${localStorage.getItem('userName')} created this group`]
            });
            await dispatch('notify',{mems:notif,text:`${localStorage.getItem('userName')} added you to ${grpName}`});//this will notify the user
            await dispatch('updateUser',{grp:id,mems:members})
            let grps = JSON.parse(localStorage.getItem('grpsData'))
            grps.push({id:id,name:grpName,wallpaper:image})
            localStorage.setItem('grpsData',JSON.stringify(grps));
            // update local storage as well
            router.push(`/group/${id}`)
        }
        catch(err){
            console.log(err)
        }
    },
async notify({dispatch},payload){
  let mems = payload.mems;
  let msg = payload.text;
  mems.forEach(async mem=>{
    try{
      let date = await dispatch('getDate')
      await db.collection('notifications').doc(mem).update({
        "connections":arrayUnion({'image':localStorage.getItem('profile'),'text':msg,'date':{'day':date.day,'month':date.month}})
      })
      console.log('sent notifiaction!')
      }
      catch(err){
        console.log(err)
        console.log('failed to sent notification!')
      }

  })
},
async getDate(){
    let today = new Date()
    let month = today.getMonth();
    let day = today.getDate();
  
    let months = {
      1:'January',
      2:'February',
      3:'March',
      4:'April',
      5:'May',
      6:'June',
      7:'July',
      8:'August',
      9:'September',
      10:'October',
      11:'November',
      12:'December',
  
    }
    return {'day':day,'month':months[month]}
  },
async updateUser(context,payload){
    let mems = payload.mems;
    let group = payload.grp;
    //updating the user Doc
   mems.forEach(async mem=>{
    try{
        const sender_ref = doc(db, "users", mem);
        await updateDoc(sender_ref, {
            communities: arrayUnion(group)
      });
        console.log('user collection updated sucessfuly')
    }
    catch(err){
        console.log('failed to update user collection!')
    }
   })
},
    async getParticipants({commit}){
        let userId = localStorage.getItem('user');
        let data = await db.collection('friends').doc(userId).get();
        let friends = data.data().list
        friends.forEach(async friend=>{
             let friend_data = await db.collection('users').doc(friend).get();
             commit('setParticipants',{value:{'name':friend_data.data().username,'image':friend_data.data().profileImage,'id':friend}})
        })
    },
    async getGroup({dispatch},payload){
      // check if grp data is present in teh cache;
      let search = payload.value
      let cacheData = localStorage.getItem(`group-${search}`)
      if(cacheData){
        // this means that we will get data for the group
          return cacheData;
      }
      // will read the data and store it in cache
      else{
           try{
            
            let dat = await db.collection('groups').doc(search).get()
           if(dat){
            let data = dat.data()
            // getting the mem info
            let admin = data.admin;
            let members = data.members;
            admin = await dispatch('getProfile',{value:admin});
            admin = {'name':admin.username,'image':admin.profileImage};
            let mems =[]
            for(let i=0;i<members.length;i++){
              if(members[i]!=data.admin){
                let user_data = await dispatch('getProfile',{value:members[i]})
              mems.push({'name':user_data.username,'image':user_data.profileImage})
              }
            }
           let groupData = {'grpId':search,'name':data.name,'wallpaper':data.wallpaper,'members':mems,'admin':admin,'description':data.description,'notifications':data.notificatio,'strength':data.strength}
           localStorage.setItem(`group-${search}`,JSON.stringify(groupData)) 
           return localStorage.getItem(`group-${search}`)
          }
           }
           catch(err){
            console.log(err)
           }
      }
    },
    async getProfile(context,payload){
         let prof = await db.collection('users').doc(payload.value).get()
         if(prof)
         return prof.data()
         else
         return ''
    },
    async fetchGroups({dispatch,commit}){
      if(localStorage.getItem('grpsData')){
        let cache = JSON.parse(localStorage.getItem('grpsData'))
      if(cache.length>0)
      return cache;
      }
      if(localStorage.getItem('communities') == '')
      return []
      commit('setLoader')
      let coms = localStorage.getItem('communities').split(',');
      let communities = []
      for(let i=0;i<coms.length;i++){
        let community = `group-${coms[i]}`
        let cacheData = localStorage.getItem(`group-${community}`)
      if(cacheData){
        communities.push({id:coms[i],name:cacheData.name,wallpaper:cacheData.wallpaper});
      }
      try{
            
        let dat = await db.collection('groups').doc(coms[i]).get()
       if(dat){
        let data = dat.data()
        // getting the mem info
        let admin = data.admin;
        let members = data.members;
        admin = await dispatch('getProfile',{value:admin});
        admin = {'name':admin.username,'image':admin.profileImage};
        let mems =[]
        for(let i=0;i<members.length;i++){
          if(members[i]!=data.admin){
            let user_data = await dispatch('getProfile',{value:members[i]})
          mems.push({'name':user_data.username,'image':user_data.profileImage})
          }
        }
       let groupData = {'grpId':coms[i],'name':data.name,'wallpaper':data.wallpaper,'members':mems,'admin':admin,'description':data.description,'notifications':data.notificatio,'strength':data.strength}
       localStorage.setItem(`group-${coms[i]}`,JSON.stringify(groupData)) 
       communities.push({id:coms[i],name:data.name,wallpaper:data.wallpaper});
      }
       }
       catch(err){
        console.log(err)
       }
      }   commit('setLoader')
      localStorage.setItem('grpsData',JSON.stringify(communities))
      return communities;
    }
}