import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default{
    namespaced:true,
    state(){
        return{
           suggestions:[],
           requests:{
            received:[],
            sent:[]
           },
           friends:[],
           friends_end:false,
           last_friend:'',
           notifications:[],
           loader:false,
           selectedHobby:'',
           hobImages:[],
           delImage:false,
           imgDelete:'',
           friends_res:[],
           gender_eval:'',
           hobby_eval:''
        }
    },
    mutations,
    getters,
    actions
}