<template>
    <div id="container">
        <b-icon icon="arrow-left" id="next" style="width:50px;height:30px;" @click="goBack"></b-icon>
        <div id="alert" v-if="Alert">
            {{ Message }}
        </div>
        <input type="file" ref="input1"
        style="display: none"
        @change="previewImage" accept="image/*" > 
        <img  :src="img1" v-if="imageData != null" alt="wallpaper" id="image">
        <img :src="require('/src/assets/profile1.jpg')" alt="wallpaper" id="image" @click="click1" v-else>
        <div class="input">
          <input type="text" v-model="groupName" placeholder="Group Name">
          
        </div>
        <div class="input">
            <input type="text" v-model="groupDescription" placeholder="Group description">
        </div>
        <h4 id="mem">Members</h4>
       <div id="members">
        <group-members v-for="member in Members" :key="member.name" :name="member.name" :image="member.image" :id="member.id"></group-members>
       </div>
 

    <b-icon icon="check" id="submit" @click="create"></b-icon>

</div>
</template>

<script>
import GroupMembers from './chip-large.vue'
import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'
export default {
    components:{
        GroupMembers
    },
    data(){
        return{
            groupName:'',
            groupDescription:"",
            alert:false,
            message:' Missing Details',
            img1: '',
            imageData: null,
            default:'/src/assets/profile1.jpg'
        }
    },
    methods:{
        goBack(){
            this.$store.commit('group/set_curr_comp',{value:'AddMembers'})
        },
        async create(){
            let addedMems = this.$store.getters['group/get_add_members']
            if(this.groupName=='' || this.groupDescription=='')
            {
                  this.alert=true;
            }
            else if(addedMems.length==0){
                    this.message = 'Add members'
                    this.alert=true;
            }
            else{
                let mems = this.$store.getters['group/get_add_members']
               let ids = []
                mems.forEach(mem=>{
                    ids.push(mem.id)
                })
           await this.$store.dispatch('group/createGrp',{image:this.img1,name:this.groupName,description:this.groupDescription,members:ids,strength:ids.length});
            }
        },
        click1() {
  this.$refs.input1.click()   
},
previewImage(event) {
  this.uploadValue=0;
  this.img1=null;
  this.imageData = event.target.files[0];
 this.onUpload()
},
onUpload(){
  try{
    this.img1=null;
    if(this.imageData != null)
    {
      const storageRef=firebase.storage().ref(`groupWallpaper/${this.imageData.name}`).put(this.imageData);
  storageRef.on(`state_changed`,snapshot=>{
  this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
    }, error=>{console.log(error.message)},
  ()=>{this.uploadValue=100;
      storageRef.snapshot.ref.getDownloadURL().then((url)=>{
          this.img1 =url;
          localStorage.setItem('grpWallpaper',this.img1)
        });
      }      
    )
    }
    else{
      localStorage.setItem('grpWallpaper','')
    }

  }
  catch(err){
    console.log("unable to upload to cloud storage")
    this.$log.error(`${err} in the profile pic comp`)
  }
 },

    },
    computed:{
        Members(){
            return this.$store.getters['group/get_add_members']
        },
        Alert(){
            return this.alert;
        },
        Message(){
            return this.message;
        }
    }
}
</script>

<style scoped>
#container{
    width:100vw;
    overflow: hidden;
}
#image{
position: relative;
 width:128px;
height: 130px;
border-radius:50%  ;
box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.368);
left: 30vw;
top: 15px;
margin-bottom:10px;
align-content: center;
align-self: center;

}
#icon{
    position: absolute;
width:40px;
height:40px;
top:20vh;
left:30vw;
color: #00000074;
}
.input{
    position: relative;
    margin-top:20px;
    margin-bottom:20px;
}
input{
    width:86vw;
    margin-left:7vw;
    border:none;
    border-bottom: 1px solid black;
    outline:none;
    font-size:21px;
    font-family:'Times New Roman', Times, serif;
}
input:focus{
    border:none;
    border-bottom: 2px solid #E90859;
    outline:none;
     font-size:21px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
#next{
    position: absolute;
left: 2.79%;
top: 2.43%;

}
#mem{
    margin-left:14px;
    font-weight:300;
    
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color:rgba(0, 0, 0, 0.59)
}
#members{
    width:90vw;
    position: relative;
    z-index: 0;
    margin-top:15px;
    padding-top:4px;
    margin-bottom:20px;
    display:grid;
    grid-template-columns: repeat(5,auto);
}
#submit{
   width:60px;
   height: 60px;
   border-radius: 100px;
   margin-left:80%;
   background-color:#E90859;
   color:white;
   box-shadow: 2px 2px 4px black;
   position: fixed;
   bottom:30px;
}
#alert{
    margin-top:30px;
    z-index:1;
    border:10px;
    background: rgb(222, 1, 82);
    height:40px;
    width:40vw;
    margin-left:30vw;
    box-shadow:2px 2px 4px rgba(0, 0, 0, 0.571);
    line-height: 50px;
    text-align: center;
    color:white;
    font-size:21px;
    font-weight:450;
    text-align:center;
    animation:horizontal-shaking;
    animation-duration: 1s;
    animation-fill-mode: backwards;
}
@keyframes horizontal-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
   }
</style>