<template>
   <div id="container">
      <div class="item">
         <div class="left"></div>
         <div class="content" @click="forwards('userProfile')">
            <b-icon class="icon" icon="person-circle" style="width: 40px; height: 40px;margin-top:30px;"></b-icon>
            <h2>Profile view</h2>
         </div>
         <div class="right"></div>
      </div>
      <div class="item">
         <div class="left"></div>
         <div class="content" @click="forwards('editDetails')">
            <b-icon class="icon" icon="pencil-square" style="width: 40px; height: 40px;margin-top:30px;"></b-icon>
            <h2>Change Details</h2>
         </div>
         <div class="right"></div>
      </div>
      <div class="item">
         <div class="left"></div>
         <div class="content">
            <b-icon class="icon" icon="bar-chart-fill" style="width: 40px; height: 40px;margin-top:30px;"></b-icon>
            <h2>Personality Analysis</h2>
         </div>
         <div class="right"></div>
      </div>
      <div class="item">
         <div class="left"></div>
         <div class="content">
            <b-icon class="icon" icon="eye-slash-fill" style="width: 40px; height: 40px;margin-top:30px;"></b-icon>
            <h2>Blocklist</h2>
         </div>
         <div class="right"></div>
      </div>
      <div class="item" @click="forwards('SelfMessage')">
         <div class="left"></div>
         <div class="content">
            <b-icon class="icon" icon="peace-fill" style="width: 40px; height: 40px;margin-top:30px;"></b-icon>
            <h2>Self messages</h2>
         </div>
         <div class="right"></div>
      </div>
      <div class="item" @click="forwards('botPage')">
         <div class="left"></div>
         <div class="content">
            <b-icon class="icon" icon="chat-square-dots-fill" style="width: 40px; height: 40px;margin-top:30px;"></b-icon>
            <h2>Dost</h2>
         </div>
         <div class="right"></div>
      </div>
      <div class="item" @click="forwards('contactAnalysis')">
         <div class="left"></div>
         <div class="content">
            <b-icon class="icon" icon="person-badge-fill" style="width: 40px; height: 40px;margin-top:30px;"></b-icon>
            <h2>Contact Analysis</h2>
         </div>
         <div class="right"></div>
      </div>
   </div>
</template>

<script>
export default {
   methods:{
      forwards(val){
         this.$router.push({name:val})
      },
      path(){
         this.$router.push(`/profile-view/${localStorage.getItem('userName')}`)
      }
   }
}
</script>

<style scoped>
#container{
   width: 100vw;
   height: 90vh;
   overflow-x: hidden;
   overflow-y: auto;
}
#container::-webkit-scrollbar{
   display: none;
}
#header{
   width:100vw;
   height: 50px;
   background: rgb(255, 1, 94);
}
#header h3{
   color:white;
   text-align: center;
   font-family: 'Sofia';
   line-height: 50px;
   font-size:32px;
   font-weight: 500;
}
.item{
   width:100vw;
   height:100px;
   box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.541);
   display:grid;
   grid-template-columns: 1.5vw 97vw 1.5vw;
   margin-top:5px;
   margin-bottom:10px;
}
.left{
   float: left;
   margin-left:0px;
   height:100px;
   width:1.5vw;
   background:rgb(255, 1, 94); 
}
.right{
   float: right;
   margin-right:0px;
   height:100px;
   width:1.5vw;
   background:rgb(255, 1, 94); 
}
.content{
   width: 97vw;
   height: 100px;
}
.content:hover .icon{
   animation: shake;
   animation-duration: 0.5s;
   animation-fill-mode: forwards;
}
.content:hover .content h2{
   color:rgb(255, 1, 94); 
}
.icon{
   float: left;
   margin-top:15px;
   margin-left:15px;
}
.content h2{
   float:left;
   font-size:27px;
   font-family: 'Sofia';
   font-weight: 500;
   margin-left:15px;
   line-height: 100px;
}
@keyframes shake {
   0% { transform: translate(1px, 1px) rotate(0deg); }
   10% { transform: translate(-1px, -2px) rotate(-1deg); }
   20% { transform: translate(-3px, 0px) rotate(1deg); }
   30% { transform: translate(3px, 2px) rotate(0deg); }
   40% { transform: translate(1px, -1px) rotate(1deg); }
   50% { transform: translate(-1px, 2px) rotate(-1deg); }
   60% { transform: translate(-3px, 1px) rotate(0deg); }
   70% { transform: translate(3px, 1px) rotate(-1deg); }
   80% { transform: translate(-1px, -1px) rotate(1deg); }
   90% { transform: translate(1px, 2px) rotate(0deg); }
   100% { transform: translate(1px, -2px) rotate(-1deg); }
 }
</style>