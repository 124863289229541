// make sure to protect routes before production
import Vue from 'vue'
import Router from 'vue-router'
import LandRoutes from './landing-routes'
import PersonalityRoutes from './personality-routes'
import AuthRoutes from './auth-routes'
import SelfMessage from './self-message'
import TransRoutes from './trans-routes'
import UserRoutes from './user-routes'
import ChatRoutes from './chatRoutes'
import GroupRoutes from './groupRoutes'
Vue.use(Router)
var allRoutes=[]
allRoutes = allRoutes.concat(LandRoutes,PersonalityRoutes,AuthRoutes,SelfMessage,TransRoutes,UserRoutes,ChatRoutes,GroupRoutes)
export default new Router({
    mode: 'history',
    routes:allRoutes  
})