export default{
    get_suggestions(state){
        return state.suggestions;
    },
    get_requests(state){
        return state.requests;
    },
    getFriends(state){
        return state.friends;
    },
    getNotifications(state){
        return state.notifications
    },
    getLoader(state){
        return state.loader;
    },
    getfriendsEnd(state){
        return state.friends_end;
    },
    getlastFriend(state){
        return state.last_friend;
    },
    getSelectedHobby(state){
        return state.selectedHobby;
    },
    getHobImages(state){
        return state.hobImages
    },
    getDelImage(state){
        return state.delImage;
    },
    getImgDell(state){
        return state.imgDelete;
    },
    getFriendsRes(state){
        return state.friends_res;
     },
     get_gender_eval(state){
        return state.gender_eval;
     },
     get_hobby_eval1(state){
        let Keys = Object.keys(state.hobby_eval);
        let data = state.hobby_eval
        let return_val={}
        for(let i=0;i<6;i++)
         return_val[Keys[i]]=data[Keys[i]]
           return return_val;
        
     }
     ,
     get_hobby_eval2(state){
        let Keys = Object.keys(state.hobby_eval);
        let data = state.hobby_eval
        let return_val={}
        for(let i=6;i<12;i++)
         return_val[Keys[i]]=data[Keys[i]]
           return return_val;
        
     }
     ,
     get_hobby_eval3(state){
        let Keys = Object.keys(state.hobby_eval);
        let data = state.hobby_eval
        let return_val={}
        for(let i=12;i<18;i++)
         return_val[Keys[i]]=data[Keys[i]]
           return return_val;
        
     }
     ,
     get_hobby_eval4(state){
        let Keys = Object.keys(state.hobby_eval);
        let data = state.hobby_eval
        let return_val={}
        for(let i=18;i<24;i++)
         return_val[Keys[i]]=data[Keys[i]]
           return return_val;
        
     }
     ,
     get_hobby_eval5(state){
        let Keys = Object.keys(state.hobby_eval);
        let data = state.hobby_eval
        let return_val={}
        for(let i=24;i<30;i++)
         return_val[Keys[i]]=data[Keys[i]]
           return return_val;
        
     },
     get_hobby_eval6(state){
        let Keys = Object.keys(state.hobby_eval);
        let data = state.hobby_eval
        let return_val={}
        for(let i=30;i<36;i++)
         return_val[Keys[i]]=data[Keys[i]]
           return return_val;
        
     },
     get_hobby_eval7(state){
        let Keys = Object.keys(state.hobby_eval);
        let data = state.hobby_eval
        let return_val={}
        for(let i=36;i<42;i++)
         return_val[Keys[i]]=data[Keys[i]]
           return return_val;
        
     },
     get_hobby_eval8(state){
        let Keys = Object.keys(state.hobby_eval);
        let data = state.hobby_eval
        let return_val={}
        for(let i=42;i<48;i++)
         return_val[Keys[i]]=data[Keys[i]]
           return return_val;
        
     },
     get_hobby_eval9(state){
        let Keys = Object.keys(state.hobby_eval);
        let data = state.hobby_eval
        let return_val={}
        for(let i=48;i<54;i++)
         return_val[Keys[i]]=data[Keys[i]]
           return return_val;
        
     },
     get_hobby_eval10(state){
        let Keys = Object.keys(state.hobby_eval);
        let data = state.hobby_eval
        let return_val={}
        for(let i=54;i<60;i++)
         return_val[Keys[i]]=data[Keys[i]]
           return return_val;
        
     },
     get_hobby_eval11(state){
        let Keys = Object.keys(state.hobby_eval);
        let data = state.hobby_eval
        let return_val={}
        for(let i=60;i<66;i++)
         return_val[Keys[i]]=data[Keys[i]]
           return return_val;
        
     },
     get_hobby_eval12(state){
        let Keys = Object.keys(state.hobby_eval);
        let data = state.hobby_eval
        let return_val={}
        for(let i=66;i<72;i++)
         return_val[Keys[i]]=data[Keys[i]]
           return return_val;
        
     },
     get_hobby_eval13(state){
        let Keys = Object.keys(state.hobby_eval);
        let data = state.hobby_eval
        let return_val={}
        for(let i=72;i<75;i++)
         return_val[Keys[i]]=data[Keys[i]]
           return return_val;
        
     }
}