<template>
    <div id="container">
        <div id="header">
            <h2>Self Messages</h2>
            <b-icon id="add" icon="plus-lg" @click="toggle"></b-icon>
        </div>
        <verify-comp v-if="Verified"/>
        <div v-else>
            <div class="noRes" v-if="Messages.length <=0 ">
                No Self messages
            </div>
            <message-comp v-else />
            <create-message v-if="CreateMessage" />
        </div>
    </div>
</template>


<script>
import MessageComp from './message-comp.vue'
import CreateMessage from './create-message.vue'
import VerifyComp from './add-password.vue'
export default {
    components:{MessageComp,CreateMessage,VerifyComp},
    data(){
        return{
            message:[]
        }
    },
    computed:{
        Messages(){
            return this.$store.getters['self/getMessages']
        },
        CreateMessage(){
            return this.$store.getters['self/getCreate'];
        },
        Verified(){
            return this.$store.getters['self/getVerified']
        }
    },
    methods:{
            toggle(){
                this.$store.commit('self/setCreate')
            }
        },
    async mounted(){
        this.$store.commit('self/toggleVerify')
          this.$store.dispatch('self/getMessages')
    }
}
</script>

<style scoped>
#container{
    width: 100vw;
}
#header{
    width:100vw;
    height:60px;
    background-image: linear-gradient(to right, #0f0c29, #302b63, #24243e);
}
#header h2{
    color: white;
    float: left;
    line-height: 60px;
    margin-left:30px;
    font-size: 38px;
    font-family: 'Sofia';
}
#add{
    color: white;
    font-size:28px;
    float:right;
    margin-right: 10px;
    margin-top:20px;
    cursor: pointer;
}
.noRes{
    text-align: center;
    font-size:32px;
    font-weight: 350;
    font-family: Arial, Helvetica, sans-serif;
    margin-top:25vh;
    animation:animation ;
    animation-direction: alternate-reverse;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-play-state: running;
}
@keyframes animation{
    from{
        color:black
    }
    to{
        color:rgb(44, 6, 183);

    }
}
</style>