import SelfMessage from '../views/self-messages/message-page.vue'
import DetailedMessage from '../views/self-messages/message-detail.vue'
const routes=[
    {
        name:'SelfMessage',
        path:'/self-message',
        component:SelfMessage
    },{
        name:'DetailedMessage',
        path:'/detailed-message',
        component:DetailedMessage
    },
]

export default routes;