<template>
    <div id="container">
        <b-img :src="require('/src/assets/login-logo.png')" fluid center alt="app logo"></b-img>
        <gender-comp/>
        <bio-comp/>
        <likes-comp/>
        <dislikes-comp/>
        <div id="button" @click="forward">Next</div>
    </div>
</template>

<script>
import BioComp from '/src/components/profile/details-1.vue'
import GenderComp from '/src/components/profile/gender-comp.vue'
import LikesComp from '/src/components/profile/likes-comp.vue'
import DislikesComp from '/src/components/profile/hate-comp.vue'
export default {
    components:{GenderComp,LikesComp,DislikesComp,BioComp},
    methods:{
        forward(){
           this.$store.dispatch('auth/signup2')
        }
    }
}
</script>


<style scoped>
#container{
width:100vw;
top:0;
left:0
}
#button{
    width:60vw;
    height:45px;
    margin-left:20vw;
    outline:none;
    border:none;
    color:white;
    background: rgb(211, 4, 73);
    border-radius: 10px;
    text-align: center;
    font-size:32px;
    font-weight:550;
    line-height: 45px;
    box-shadow:1px 1px 2px rgba(0, 0, 0, 0.69);
    margin-top:20px;
}
</style>