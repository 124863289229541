<template>
    <div class="wrapper">
      <div class="col" ontouchstart="this.classList.toggle('hover');" v-for="item,index in content" :key="index" @click="switchSong(index)">
        <div class="container">
            <div class="front" :style="item.image">
                <div class="inner-front">
                </div>
            </div>
            <div class="back">
                <div class="inner-back">
                  <p>{{ item.text }}</p>
                </div>
            </div>
        </div>
    </div>
       </div>
  </template>
  
  
  <script>
  export default {
    data(){
    return{
      content:[]
    }
  },
  methods:{
        switchSong(val){
          if(this.$store.getters['trans/getSongs'].length-1 != this.$store.getters['trans/getIndex'])
        this.$store.commit('trans/setIndex',{value:val})
        else
        this.$store.commit('trans/setIndex',{value:0});
        }
  },
  async mounted(){
    let images = this.$store.getters['trans/getImages']
    let quotes = this.$store.getters['trans/getQuotes']
    for(var i=0;i<images.length;i++){
          this.content.push({'image':`background-image: url(${images[i]})`,'text':quotes[i]});
    }
  }
  }
  </script>
  
  <style scoped>

  
  .wrapper{
    width: 100%;
    position: absolute;
    top:390px;
    margin-left:0vw !important;
    height:300px;
    display: flex;
    flex-direction: row;
    overflow-y:hidden ;
    overflow-x: auto;
  padding-left:2vw;
  }
  .wrapper::-webkit-scrollbar{
    display: none;
  }
  
  
  
  .col{
    width: 80vw;
margin-left:1.5vw;
margin-right:1.5vw;
    cursor: pointer;
  }
  
  .container{
    width: 80vw;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
      -webkit-perspective: 1000px;
              perspective: 1000px;
  }
  
  .front,
  .back{
    background-size: cover;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.25);
    border-radius: 10px;
      background-position: center;
      -webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      text-align: center;
      height: 300px;
      width:80vw;
      border-radius: 10px;
      color: #fff;
      font-size: 1.5rem;
  }
  
  .back{
    background: #cedce7;
    background: -webkit-linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
    background: -o-linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
    background: linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
  }
  
  .front:after{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      opacity: .6;
      background-color: #000;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      border-radius: 10px;
  }
  .container:hover .front,
  .container:hover .back{
      -webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  }
  
  .back{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
  }
  
  .inner-front{
      -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
              transform: translateY(-50%) translateZ(60px) scale(0.94);
      top: 50%;
      position: absolute;
      left: 0;
      width: 100%;
      padding: 2rem;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      outline: 1px solid transparent;
      -webkit-perspective: inherit;
              perspective: inherit;
      z-index: 2;
  }
  
  .inner-back{
    -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
            transform: translateY(-50%) translateZ(60px) scale(0.94);
    top: 35%;
    position: absolute;
    left: 0;
    width: 100%;
    padding:.78rem;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    outline: 1px solid transparent;
    -webkit-perspective: inherit;
            perspective: inherit;
    z-index: 2;
    font-family: 'Sofia';
  }
  
  .container .back{
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg);
      -webkit-transform-style: preserve-3d;
              transform-style: preserve-3d;
  }
  
  .container .front{
      -webkit-transform: rotateY(0deg);
              transform: rotateY(0deg);
      -webkit-transform-style: preserve-3d;
              transform-style: preserve-3d;
  }
  
  .container:hover .back{
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }
  
  .container:hover .front{
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }
  
  .front .inner p{
    font-size: 2rem;
    margin-bottom: 2rem;
    position: relative;
  }
  
  .front .inner p:after{
    content: '';
    width: 4rem;
    height: 2px;
    position: absolute;
    background: #C6D4DF;
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -.75rem;
  }
  
  .front .inner span{
    color: rgba(255,255,255,0.7);
    font-family: 'Montserrat';
    font-weight: 300;
  }
  
  
  </style>