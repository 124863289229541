<template>
    <div id="container">
        <div id="header">
            <h2>{{ Personality }}</h2>
        </div>
        <div id="buttons">
            <div class="button" id="btn1" @click="About">About Test</div>
            <div class="button" id="btn2" @click="Why">Why should I take this test?</div>
            <div class="button" id="btn3" @click="Test">Start Test</div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            personality:''
        }
    },
    computed:{
        Personality(){
            return this.personality;
        }
    },
    methods:{
       About(){
        this.$router.push(`/about/${this.$route.params.test}`)
       },
       Why(){
        this.$router.push(`/why/${this.$route.params.test}`)
       },
       Test(){
        this.$router.push(`/test/${this.$route.params.test}`)
       }
    },
    mounted(){
        localStorage.setItem('test',this.$route.params.test)
        this.personality=this.$route.params.test;
    }
}
</script>

<style scoped>
#container{
    width:100vw;
    overflow: hidden;
    height: 100vh;
}
#header{
    width:100vw;
    height:60px;
    background-image: linear-gradient(to right, #102216, #156039, #03ca8b);
}
#header h2{
    color: white;
    line-height: 60px;
    font-size: 38px;
    font-family: 'Sofia';
    text-align:center;
}
.button{
    width:99vw;
    margin-left:0.5vw;
    margin-right:0.5vw;
    height:34vh;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.616);
    border-radius: 20px;
    text-align: center;
    font-family: 'Sofia';
    font-size:34px;
    padding-top:10vh;
    font-weight: 400;
    color:white;
}
#btn1{
    background: #102216;
    margin-top:2px;
}
#btn2{
    background: #156039;
   margin-top:-30px;
}
#btn3{
    background: #03ca8b;
   margin-top:-30px;
}
</style>