import { render, staticRenderFns } from "./add-password.vue?vue&type=template&id=1c0a7538&scoped=true&"
import script from "./add-password.vue?vue&type=script&lang=js&"
export * from "./add-password.vue?vue&type=script&lang=js&"
import style0 from "./add-password.vue?vue&type=style&index=0&id=1c0a7538&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c0a7538",
  null
  
)

export default component.exports