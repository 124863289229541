<template>
    <div id="about">
        <h3 class="headline">About</h3>
        <p>{{ About }} <span @click="toggle">{{ Toggle }}</span></p>
    </div>
</template>

<script>
export default {
    props:['about'],
    data(){
        return{
            para:"",
            btnText:'Show more..'
        }
    },
    computed:{
        About(){
            return this.para;
        },
        Toggle(){
            return this.btnText
        }
    },
    methods:{
        toggle(){
            if(this.btnText=='Show more..')
            this.more();
            else
            this.less();
        },
        more(){
            this.para = this.about;
            this.btnText='Show less..'
        },
        less(){
         this.para = this.about.slice(0,300)
         this.btnText='Show more..'
        }
    },
    mounted(){
        this.para=this.about
        this.less()
    }
}
</script>

<style scoped>
#about{
    width:100vw;
    overflow-x: hidden;
    padding-left:1vw;
    padding-right:1vw;
    margin-top:16px;
}
.headline{
    font-size:27px;
    font-family: 'Sofia';
    font-weight: 350;
    margin-left:1vw;
    color:rgb(0, 132, 123);
    height:fit-content
}
p{
    width:96vw;
    margin-left:1vw;
    margin-right:1vw;
    font-size:19px;
    font-weight:450;
    font-family:sans-serif;
    line-height: 22px;;
    color:black
}
span{
    color:rgb(0, 132, 123);
}
</style>