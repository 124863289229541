<template>
  <keep-alive>
    <div id="container">
      <img :src="Image" :alt="Image" :class="[Play?'img_animate':'']" @click="toggle" >
      <audio :src="Song" style="display=none" autoplay ref="player" :ended="next">
        </audio>
        <div id="controller">
          <b-icon class="icon" id="left" icon="caret-left-fill" @click="previous"></b-icon>
          <b-icon icon="pause-fill" class="icon" id="mid" v-if="Play" @click="toggle"></b-icon>
          <b-icon icon="play-fill" class="icon" id="mid" v-else @click="toggle"></b-icon>
          
          <b-icon icon="caret-right-fill" class="icon" id="right" @click="next"></b-icon>
          <!--add quotes as next step in the UI-->
        </div>
       <quotes-comp/>
    </div>
  </keep-alive>
</template>

<script>
//later on add these two functionality auto play at end of the current slide and change the tone on slide ye vo music player wale app se copy karlena.
// and also add auto switch next when the song ends
import QuotesComp from './trans-text.vue'
export default {
  components:{QuotesComp},
  data(){
    return{
      play:true,
    }
  },
  mounted(){
   try{
    let player = this.$refs.player;
    player.addEventListener('onended',this.next())
   }
   catch(err){
    console.log(err)
   }
  },
    computed:{
        // get these from states based on index
        Image(){
         return this.$store.getters['trans/getCurrImage']
        },
        Song(){
             return this.$store.getters['trans/getCurrSong']
        },
        Play(){
          return this.play
        }
    },
    methods:{
      toggle(){
        this.play = ! this.play;
        var a = this.$refs.player;
      if (a.paused) {
        a.play();
      } else {
        a.pause();
      }
      },
      next(){
        if(this.$store.getters['trans/getSongs'].length-1 != this.$store.getters['trans/getIndex'])
        this.$store.commit('trans/setIndex',{value:this.$store.getters['trans/getIndex']+1})
        else
        this.$store.commit('trans/setIndex',{value:0})
        var a = this.$refs.player;
        if(this.play == true)
        a.play();
        else
        a.pause();
      },
      previous(){
        if(this.$store.getters['trans/getSongs'].length != 0)
        this.$store.commit('trans/setIndex',{value:this.$store.getters['trans/getSongs'].length-1})
        else
        this.$store.commit('trans/setIndex',{value:this.$store.getters['trans/getIndex']-1})
        var a = this.$refs.player;
        if(this.play == true)
        a.play();
        else
        a.pause();
      }
    }
}
</script>

<style scoped>
#container{
  height: 109vh;
    width:100vw !important;
/* Created with https://www.css-gradient.com */
background: linear-gradient(-45deg, #474646cc, #232223d2, #44012ba6, #d1076cbd);
background-size: 400% 400%;
	animation: gradient 10s ease infinite;
    overflow-y: auto;
    overflow-x: hidden;
}
#container::-webkit-scrollbar{
  display: none;
}
img{
  position: absolute;
    width:300px;
    height:300px;
    border-radius: 300px;
    z-index:0;
    margin-top:1.5vh;
    margin-left:10vw;
    box-shadow:8px 8px 14px rgba(24, 24, 24, 0.263);
    
} 

.img_animate{
  animation: animateImage 15.9s infinite linear;
}
@keyframes animateImage {
   
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  #controller{
    position: absolute;
    top:330px;
    padding-left:18vw;
  }
  .icon{
    color:rgba(255, 255, 255, 0.621);
    font-size:42px;
    font-weight: 300;
    margin-left:5vw;
    margin-right:5vw;
  }
 
</style>