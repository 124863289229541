<template>
    <div id="container">
        <b-img id="dp"  :src="image"  rounded="circle" :alt="username"></b-img>
    <h1 id="username">{{ username }}</h1>
    <h3 id="bio">{{ bio.slice(0,40) }}</h3>
    <b-button id="message" class="text shadow-none" @click="accept" >{{ Text }}</b-button>
    <b-button id="remove" class="text shadow-none" @click="remove">Remove</b-button>
        </div>
</template>


<script>
export default {
  props:['username','image','bio',],
  data(){
    return {
      txt:'Accept'
    }
  },
  computed:{
    Text(){
      return this.txt;
    }
  },
  methods:{
    accept(){
      if(this.txt == 'Accept'){
      this.txt = 'Accepted'
      this.$store.dispatch('user/acceptRequest',{value:this.username})  
    }
      
    },
    remove(){
      this.$store.dispatch('user/declineRequest',{value:this.username})
    }
  }
}
</script>

<style scoped>
  #container{
  height: 122px;
position: relative;
width: 100vw;
margin-top:6px;
margin-bottom:1vh;
background: #FFFFFF;
border-bottom:1px solid rgba(0, 0, 0, 0.57);
    }
#dp{
position: relative;
width:22vw;
height:11.75vh;
min-height: 90px;
margin-left:2vw;
margin-top:2.78vh;
border-radius: 100px;
}
#username{
position: absolute;
width: 134px;
height: 30px;
left:27vw;
overflow: hidden;
top:3.18vh;
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 24px;
line-height: 28px;

color: #000000;
}
#bio{
position: absolute;
width: 65vw;
height:fit-content;
max-height: 24px;
left: 27vw;
overflow: hidden;
top:55px;
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 16px;

color: #000000;
}
#message{
position: absolute;
width: 30vw;
height: 4vh;
left: 26vw;
top:78px;
min-height: 36px;
padding:0;
background: #E90859;
border-radius: 6px;
font-weight: 500;
font-size:21px;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
#remove{
position: absolute;
width: 30vw;
height: 4vh;
left: 64vw;
min-height: 36px;
top:78px;
padding:0;
background: #E90859;
border-radius: 6px;
font-weight: 500;
font-size:21px;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
#remove:hover,
#message:hover{
  font-size:24px;
}
</style>