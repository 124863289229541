<template>
    <div id="container">
        <div id="header">
            <h2>{{ Personality }}</h2>
        </div>
<about-comp :about="about"/>
<history-comp :history="history"></history-comp>
<factor-comp :factors="factors"></factor-comp>
<type-comp :types="types_data"></type-comp>
<detail-comp></detail-comp>
    </div>
</template>

<script>
import AboutComp from '/src/components/personality/about-comp.vue'
import HistoryComp  from '/src/components/personality/history-comp.vue'
import TypeComp from '/src/components/personality/types-comp.vue'
import DetailComp from '/src/components/personality/details-comp.vue'
import FactorComp from '/src/components/personality/factors-comp.vue'
export default {
    components:{AboutComp,HistoryComp,TypeComp,DetailComp,FactorComp},
    data(){
        return{
            personality:'',
           history:`Today, the MBTI tool is the most widely used and recognized personality tool in the world. Around two million people complete it every year, but how did it begin?
            It began with Katharine Cook Briggs and her daughter, Isabel Briggs Myers, in the United States in the early mid-20th century. 
Briggs was inspired to research personality type theory when she met Isabel’s future husband, Clarence Myers. She noticed he had a different way of seeing the world. This intrigued her enough to start a literature review to understand different temperaments. 
Carl G Jung published Psychological Types in 1921. Briggs read the English translation (1923) and saw similarities between their ideas. However, Jung’s theories of personal difference were much more developed. 
Briggs and Myers thought Jung’s work was so useful that they wanted to make his ideas accessible to a wider audience.
World War II was a huge influence on the project’s development. Myers believed that if people understood each other better, they’d work together better and there’d be less conflict. The post-war world could be a better place. 
She was determined to find a way to give people access to their psychological type. This led to the idea of a type indicator, and Myers dedicated the rest of her life to its development. 
She spent the next 20 years developing questions and validating the instrument and the theory. The MBTI instrument was first published in 1962.
The Myers-Briggs Company (formerly CPP, Inc.) has published, researched and updated the MBTI instrument since 1975. It has trained practitioners since 1989.
Today, The Myers-Briggs Company continues to follow Myers’ guiding principle: that understanding personality and difference can change the world for the better.
            `,
    factors:{
        'Energy':{
            'about':'How do you direct and receive energy—by focusing on the outside world, interacting with people and taking action, or by focusing on your inner world and reflecting on ideas, memories, and experiences?',
        'types':{'Extraversion':'Gets energy from the outer world of people and experiences. Focuses energy and attention outwards in action.',
        'Introversion':'Gets energy from the inner world of reflections and thoughts. Focuses energy and attention inwards in reflection.'}}
    ,
    
        'Information':{about:'How do you take in information—by focusing on what you perceive using your five senses or by seeing the big picture and looking for relationships and patterns? ',
        types:{
            'Sensing':'Prefers real information coming from the five senses. Focuses on what is real.',
        'Intution':'information coming from associations. Focuses on possibilities and what might be.'
        }}
    ,
    
        'Thinking':{about:'How do you decide and come to conclusions—by logically analyzing the situation or by considering what’s important to the people involved?',
        types:{
            'Thinking':'Steps out of situations to analize them dispassionately. Prefers to make decisions on the basis of objective logic.',
        'Feeling':'Steps into situations to weigh human values and motives. Prefers to make decisions on the basis of values.'
        }}
    ,
    
        'World':{about:'How do you approach the outside world—in a planned, orderly way or a more flexible, spontaneous way? ',
    types:{
        'Judging':'Prefers to live life in a planned and organized manner. Enjoys coming to closure and making a decision.',
        'Perciving':'Prefers to live life in a spontaneous and adaptable way. Enjoys keeping options open.'
    }
}}
    ,
    
    types_data:{
        'analysts':{
            'architect':{
                values:'INTJ',
                about:'Imaginative and strategic thinkers,with a plan for everything'
            },
            'logician':{
                values:'INTP',
                about:'Innovative inventors with an unquenchable thirst for knowledge'
            },
            'commander':{
                values:'ENTJ',
                about:'Bold,imaginative and strong-willed leaders,always finding a way or making one'
            },
            'debater':{
                values:'ENTP',
                about:'Smart and curious thinkers who cannot resist an intellectual challenge'
            }
        },
        'diplomats':{
            'advocate':{
                values:'INFJ',
                about:'Quiet and mystical,yet very inspiring and tireless idealists'
            },
            'mediator':{
                values:'INFP',
                about:'Poetic,kind and altruistic people, always eager to help a good cause'
            },
            'protagonist':{
                values:'ENFJ',
                about:'Charismatic and inspiring leaders,able to mesmerize their listeners'
            },
            'campaigner':{
                values:'ENFP',
                about:'Enthusiastic, creative and socialable free spirits, who can always find a reason to smile'
            }
        },
        'sentinels':{
            'logistician':{
                values:'ISTJ',
                about:'Practical and fact-minded individuals,whose realiablity cannot be doubted'
            },
            'defender':{
                values:'ISFJ',
                about:'Very dedicated and warm protectors, always ready to defend their loved ones.'
            },
            'executive':{
                values:'ESTJ',
                about:'Excellent administrators,unsurpassed at managing things or people'
            },
            'consul':{
                values:'ESFJ',
                about:'Extraordinary caring,social and popular people always eager to help'
            }
        },
        'explorers':{
            'virtuoso':{
                values:'ISTP',
                about:'Bold and practical experimenters,master of all kind of tools'
            },
            'adventurer':{
                values:'ISFP',
                about:'Flexible and charming artists,always ready to explore and experience something new'
            },
            'entrepreneur':{
                values:'ESTP',
                about:'Smart,energetic and very preceptive people,who truly enjoy living on the edge'
            },
            'entertainer':{
                values:'ESFP',
                about:'Spontaneous,energetic and enthusiastic people -life is never boring around them.   '
            }
        }
    },
    about:`The purpose of the Myers-Briggs Type Indicator® (MBTI®) personality inventory is to make the theory of psychological types described by C. G. Jung understandable and useful in people's lives. The essence of the theory is that much seemingly random variation in the behavior is actually quite orderly and consistent, being due to basic differences in the ways individuals prefer to use their perception and judgment.
"Perception involves all the ways of becoming aware of things, people, happenings, or ideas. Judgment involves all the ways of coming to conclusions about what has been perceived. If people differ systematically in what they perceive and in how they reach conclusions, then it is only reasonable for them to differ correspondingly in their interests, reactions, values, motivations, and skills."
In developing the Myers-Briggs Type Indicator [instrument], the aim of Isabel Briggs Myers, and her mother, Katharine Briggs, was to make the insights of type theory accessible to individuals and groups. They addressed the two related goals in the developments and application of the MBTI instrument:
The identification of basic preferences of each of the four dichotomies specified or implicit in Jung's theory.`,
        }
    },
    computed:{
        Personality(){
            return this.personality;
        }
    },
    mounted(){
this.personality=localStorage.getItem('test');
    }
}
</script>

<style scoped>
#container{
    width:100vw;
    overflow-x: hidden;
    height: 100vh;
}
#header{
    width:100vw;
    height:60px;
    background-image: linear-gradient(to right, #102216, #156039, #03ca8b);
}
#header h2{
    color: white;
    line-height: 60px;
    font-size: 38px;
    font-family: 'Sofia';
    text-align:center;
}
</style>