import LoginPage from '../views/login/login-comp.vue';
import SignupPage from '../views/login/signup-comp.vue'
import ForgotPage from '../views/login/forgot-password.vue'
import InterestsPage from '../views/login/interests-page.vue'
import UserDetails from '../views/login/user-details.vue'
import UserImages from '../views/login/user-images.vue'
import CreatingUser from '../views/login/creating-user.vue'
import WelcomePage from '../views/login/welcom-page.vue'
const routes=[{
    name:'forgot',
    path:'/forgot',
    component:ForgotPage
}
,
{
    name: 'login',
    path: '/login',
    component: LoginPage,
   
    
},
{
    name: 'signup',
    path: '/signup',
    component: SignupPage,
   

},
{
    name:'Interests',
    path: '/interests',
    component: InterestsPage
},
{
    name: 'welcome',
    path: '/',
    component: WelcomePage

},
{
    name:'user-details',
    path:'/user-details',
    component:UserDetails
},
{
    name:'user-images',
    path:'/user-images',
    component:UserImages
},{
    name:'creating-user',
    path:'/creating-user',
    component:CreatingUser
    },
]

export default routes