<template>
    <b-input-group size="sm" class="mb-2" id="search">
      <b-form-input type="search" placeholder="Search Communities" id="input" v-model="query" @keypress.enter="search"></b-form-input>
        <b-icon icon="search" id="icon"></b-icon>
    
    </b-input-group>
</template>

<script>
export default {
    data(){
        return{
query:''
        }
    },
    methods:{
       async search(){
            await this.$store.dispatch('user/searchFriends',{value:this.query})
            this.data=''
            this.$router.push({name:'friendsRes'})
        }
    }
}
</script>


<style scoped>
#search{
box-sizing: border-box;
width: 92vw;
height: 41px;
margin-left: 3.65vw;
margin-top:10px;
border: 1px solid rgba(0, 0, 0, 0.5);
border-radius: 10px;
}
#icon{
    margin-left:86.14vw;
    margin-top:10px;
color: rgba(0, 0, 0, 0.57);
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
#input{
box-sizing: border-box;
position: absolute;
width: 86vw;
height: 38px;
border: 1px solid transparent;
border-radius: 10px;
font-size:18px;
}
.form-control:focus {
box-shadow:0px 4px 4px rgba(0, 0, 0, 0);


}
</style>