export default{
    getSongs(state){
        return state.songs;
    },
    getImages(state){
        return state.images;
    },
    getIndex(state){
        return state.index;
    },
    getCurrSong(state){
        return state.songs[state.index]
    },
    getCurrImage(state){
        return state.images[state.index]
    },
    getTrans(state){
        return state.trans;
    },
    getQuote(state){
        return state.quotes[state.index]
    },
    getQuotes(state){
        return state.quotes;
    }
   
}