export default{
    setSong(state,payload){
        state.songs.push(payload.value)
    },
    setPic(state,payload){
        state.images.push(payload.value);
    },
    setIndex(state,payload){
        state.index = payload.value;
    },
    setTrans(state,payload){
        state.trans = payload.value;
    },
    setQuote(state,payload){
        state.quotes.push(payload.value)
    }
   
}