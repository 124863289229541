import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default{
    namespaced:true,
    state(){
        return{
            add_members:[],
            add_name:'',
            add_description:'',
            add_image:'',
            curr_comp:"AddMembers",
            participants:[],
            loader:false
            
            
        }
    },
    mutations,
    getters,
    actions
}