export default{
    get_add_members(state){
        return state.add_members;
    },
    get_add_name(state){
        return state.add_name;
    },
    get_add_description(state){
        return state.add_description;
    },
    get_add_image(state){
        return state.add_image;
    },
    get_curr_comp(state){
        return state.curr_comp;
    },
    getParticipants(state){
        return state.participants;
    },
    getloader(state){
        return state.loader;
    }
    
}