<template>
    <div id="container">
        <div id="header">
            <h2>{{ Hobby }}</h2>
        </div>
        <spinner-comp v-if="Loading"/>
        <div>
            <div class="noRes" v-if="Images.length == 0 ">
                No Images
            </div>
            <div id="img-container" v-else style="margin-top:3vh">
                <img-comp v-for="image in Images" :key="image" :image="image" :user="User"/>
               </div>
        </div>
    </div>
</template>

<script>
import SpinnerComp from '/src/components/home/spin-loader.vue'
import ImgComp from '/src/components/user/img-comp-del.vue'
export default {
    components:{SpinnerComp,ImgComp},
    data(){
        return{
           hobby:'',
           images:[],
           load:false,
        }
    },
    computed:{
        Hobby(){
            return this.hobby;
        },
        Images(){
            return this.images;
        },
        Loading(){
            return this.load;
        },
        User(){
            return this.$route.params.user;
        }
    },
 async mounted(){
    this.load=true;
    this.hobby = this.$route.params.hobby;
    this.images=await this.$store.dispatch('user/userImage',{'hobby':this.$route.params.hobby,'user':this.$route.params.user})
    this.load=false;
    
 }    
}
</script>

<style scoped>
#container{
    width:100vw;
    overflow-x: hidden;
}
#header{
    width:100vw;
    height:60px;
    background-image: linear-gradient(to right, #270715, #751f2f, #ca0335);
}
#header h2{
    color: white;
    line-height: 60px;
    font-size: 38px;
    font-family: 'Sofia';
    text-align:center;
}
.noRes{
    text-align: center;
    font-size:32px;
    font-weight: 350;
    font-family: Arial, Helvetica, sans-serif;
    margin-top:25vh;
    animation:animation ;
    animation-direction: alternate-reverse;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-play-state: running;
}
@keyframes animation{
    from{
        color:black
    }
    to{
        color:rgba(233, 8, 89, 1);

    }
}

</style>