<template>
    <div id="container">
        <cover-image/>
        <profile-image/>
        <div id="button" @click="forward">Next</div>
    </div>
</template>

<script>
import CoverImage from '/src/components/profile/cover-pic.vue'
import ProfileImage from '/src/components/profile/profile-pic.vue'
export default {
    components:{
        CoverImage,ProfileImage
    },
    methods:{
        forward(){
            this.$router.push({name:'Interests'})
        }
    }
}
</script>

<style scoped>
#button{
    position: absolute;
    top:370px;
    width:60vw;
    height:45px;
    margin-left:20vw;
    outline:none;
    border:none;
    color:white;
    background: rgb(211, 4, 73);
    border-radius: 10px;
    text-align: center;
    font-size:32px;
    font-weight:550;
    line-height: 45px;
    box-shadow:1px 1px 2px rgba(0, 0, 0, 0.69);
    margin-top:20px;
}
</style>