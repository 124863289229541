import { render, staticRenderFns } from "./group-comp.vue?vue&type=template&id=2aa63a87&scoped=true&"
import script from "./group-comp.vue?vue&type=script&lang=js&"
export * from "./group-comp.vue?vue&type=script&lang=js&"
import style0 from "./group-comp.vue?vue&type=style&index=0&id=2aa63a87&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aa63a87",
  null
  
)

export default component.exports