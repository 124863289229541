<template>
    <div id="create">
        <h4>Verify yourself first!</h4>
        <input type="password" id="password" v-model="password" :placeholder="Placeholder" :class="Wrong==true?'error':''">
        <div id="button" @click="verify">Verify</div>
    </div>
</template>

<script>
export default {
    data(){
        return{
          password:"",
          placeholder:'Enter password',
          wrong:false
        }
    },
    computed:{
        Placeholder(){
           return this.placeholder;
        },
        Wrong(){
            return this.wrong;
        }
    },
    methods:{
        // this function will verify the user first
        async verify(){
            let val = this.password;
           let result = await this.$store.dispatch('self/verify',{value:val});
           this.password = '';
           // if true
           if(result)
           this.$store.commit('self/toggleVerify')
           // if false will change the styling of input field
           else{
            this.placeholder='wrong password!'
            this.wrong=true;
           }
        }
    }
}
</script>


<style scoped>
#create{
    width:96vw;
    height:400px;
    margin-left:2vw;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.892);
    z-index: 1;
    position: absolute;
    top:150px;
    border-radius: 20px;
    background: white;
    animation: animation;
    animation-duration:0.8s;
    animation-fill-mode: forwards;
}
#create h4{
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    font-family: 'Sofia';
    line-height: 12px;
    margin-top:10px;
}
#title{
    margin-top:15px;
}
input,
input:focus,
input:default{
    outline: none;
    border: none;
    border-bottom:2px solid rgba(0, 0, 0, 0.542);
    width:80vw;
    margin-left:8vw;
    font-size:21px;
    color:black;
    margin-top:23px;
    font-family:'Sofia';
    font-weight: 400;
}
input::placeholder{
    font-size:21px;
    color:rgba(0, 0, 0, 0.489);
    margin-top:13px;
    font-family:'Sofia';
    font-weight: 400;
}
@keyframes animation{
    from{
        transform:scale(0.65,0.65)
    }
    to{
        transform: scale(1,1);
    }
}
#button{
    width:60vw;
    margin-left:18vw;
    color: white;
    font-family: 'Sofia';
    font-size:32px;
    font-weight: 500;
    height: 60px;
    background-image: linear-gradient(to right, #0f0c29, #302b63, #24243e);
    text-align: center;
    line-height: 60px;
    border-radius: 10px;
    margin-top:10px;
}
.error,
.error:focus,
.error:default{
    outline: none;
    border: none;
    border-bottom:2px solid rgba(223, 9, 9, 0.577);
    width:80vw;
    margin-left:8vw;
    font-size:21px;
    color:black;
    margin-top:23px;
    font-family:'Sofia';
    font-weight: 400;
    animation: shake ;
    animation-duration:1s ;
    animation-fill-mode: forwards;
}
.error::placeholder{
    font-size:21px;
    color:rgba(0, 0, 0, 0.489);
    margin-top:13px;
    font-family:'Sofia';
    font-weight: 400;
}
@keyframes shake {
    0% { transform: translate(1px, 1px) ; }
    10% { transform: translate(-1px, -2px) ; }
    20% { transform: translate(-3px, 0px) ; }
    30% { transform: translate(3px, 2px) ; }
    40% { transform: translate(1px, -1px) ; }
    50% { transform: translate(-1px, 2px) ; }
    60% { transform: translate(-3px, 1px); }
    70% { transform: translate(3px, 1px) ; }
    80% { transform: translate(-1px, -1px); }
    90% { transform: translate(1px, 2px) ; }
    100% { transform: translate(1px, -2px); }
  }
</style>