<template>
    <div id="container">
        <b-overlay
        id="overlay-background"
       class="overlay"
       :show="loading"
       variant="transparent"
       opcaity="0.50"
       blur="1"
       style="height:100vh">
       <b-alert :show="alertState" variant="dark" id="alert" style="background:rgba(0,0,0,0.8);font-weight:500;color:white;font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;margin:2%;">Something went wrong on our side</b-alert>
            <b-img :src="require('/src/assets/login-logo.png')" fluid center alt="app logo"></b-img>
        <h3 id="quote">A minimalist chat app</h3>
        <div id="form">
            <b-form @submit="login" @reset="formReset">
                 <b-form-input
          id="username"
          v-model="userName"
          :class="[errorStyle==0?'error':'']"
          class="form-control"
          placeholder="Username"
          required
        ></b-form-input>
         <b-form-input
         v-model="password"
         type="password"
          id="password"
          :class="[errorStyle==1?'error':'']"
          class="form-control"
          placeholder="Enter password"
          required
        ></b-form-input>

            </b-form>
           
        </div>
        <div >
            <h5 id="help" @click="help">get help with login</h5>
        </div>
       <b-button id="btn" class="shadow-none" @click="login">Login</b-button>
     <span><h4 id="signup">Don't have an account</h4> <h1 id="link" @click="signup">Signup</h1></span>
        </b-overlay>
    </div>
</template>

<script>
export default {
    
   data(){
    return{
        userName:'',
        password:'',
        error:-1
    }
   },
   computed:{
    errorStyle()
    {
        return this.error;
    },
    loading(){
   
   return this.$store.getters['auth/getLoader'];
},
alertState(){
    return this.$store.getters['auth/getLoginError']
},
   },
    methods:{
        async login(){
            this.$store.commit('auth/setLoader',{value:true})
            const email = await this.$store.dispatch('auth/getMail',{userName:this.userName})
            if(email.status === 'OK')
            {
                let res = await this.$store.dispatch('auth/login',{email:email.mail,
                password:this.password})
                if(res.status=="password")
                {
                    this.error=1;
                    this.password="wrong password";
                    this.$store.commit('auth/setLoader',{value:false})
                }
                else if(res.staus=="system")
                {  
                    this.$store.commit('auth/setLoginError',{value:true});
                    this.$store.commit('auth/setLoader',{value:false})
                    setTimeout(this.$store.commit('auth/setLoginError',{value:true}),2000);
                }
            else
            {
                this.userName='';
            this.password='';
            this.$store.commit('auth/setLoader',{value:false})
            }
            }
            else
            {
                this.error=0; // the username is wrong
                this.userName='No such user!'
                this.$store.commit('auth/setLoader',{value:false})
            }
        },
        help(){
            this.$router.push({name:'forgot'})
        },
        formReset(){
            console.log('form reset')
        },
        signup(){
            this.$router.push({name:'signup'});
        }
    }
       
}
</script>

<style scoped>
#quote{
    text-align: center;
    font-weight: 400;
    font-style:italic;
    color:rgba(0, 0, 0, 0.538);
    }
#form{
    margin-top:7vw;
}
.form-control:focus {
   border: 1px solid rgba(233, 8, 89, 0.89);
box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 13px;

}
.form-control{
    box-sizing: border-box;
    position: absolute;
    width: 361px;
    height: 53px;
    border: 1px solid #000000;
    border-radius: 13px;
    margin-left:6.87%;
}
.form-group {
    position: relative;
    margin-bottom: 1.5rem;
  }
  
  .form-control-placeholder {
    position: absolute;
    top: 0;
    padding: 7px 0 0 13px;
    transition: all 200ms;
    opacity: 0.5;
    color: red;
  }
  
  
  .form-control:focus + .form-control-placeholder,
  .form-control:valid + .form-control-placeholder {
    font-size: 75%;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
  }
#username{
    margin-top:40px;
    margin-left:4vw;
    width:92vw;
    font-size:24px;
    font-weight: 500;
    font-style: italic;
    font-family:'Sofia'
}
#password{
    margin-top:110px;
    margin-left:4vw;
    width:92vw;
    font-size:24px;
    font-weight: 500;
    font-style: italic;
    font-family: 'Sofia'
}
#help{
    margin-top:196px;
    color:rgba(233, 8, 89, 0.89);
    font-weight: 400;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-left:61%;
    font-size:16px;
}
#btn{
    background: rgba(233, 8, 89, 0.89);
    color:white;
    width:75%;
    margin-top:4.25%;
    margin-left:12.75%;
    font-size:24px;
    font-weight: 400;
}
.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.alternate{
    margin-top:3.55%;
    font-weight: 450;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
#signup{
position: absolute;
width: 246px;
height: 26px;
left: 42px;
margin-top: 20%;
font-family:'Times New Roman', Times, serif;
font-style: italic;
font-weight: 300;
font-size: 22px;
line-height: 26px;
/* identical to box height */

color: #000000;
}
#link{
    position: absolute;
width: 86px;
height: 33px;
left: 249px;
margin-top: 20%;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 34px;
line-height: 33px;
/* identical to box height */

float:left;
color: #E90859;
}
.error{
    border:2px solid red;
}
/*alert styling*/
#alert{
    animation: horizontal-shaking 0.5s;
}
@keyframes horizontal-shaking {
 0% { transform: translateX(0) }
 25% { transform: translateX(5px) }
 50% { transform: translateX(-5px) }
 75% { transform: translateX(5px) }
 100% { transform: translateX(0) }
}
</style>