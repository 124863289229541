<template>
    <div id="factors">
        <h3 class="headline">Calculation factors</h3>
        <ul>
           <li v-for="key in Keys" :key="key" class="factors1">{{ key }} <span class="info">{{ data[key].about }}</span> </li>
        </ul>
        <h4 style="text-align:center;font-weight:300;color:black;font-family:Sans">Pairs</h4>
        <div class="pairs" v-for="types,index in Types" :key=index>
         <div class="left">
            <h4>{{ types[0] }}</h4>
            <h5>{{ DOTA[types[0]] }}</h5>
         </div>
         <div class="right">
            <h4>{{ types[1] }}</h4>
            <h5>{{ DOTA[types[1]] }}</h5>
         </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['factors'],
    data(){
        return {
            keys:[],
            data:{},
            DOTA:{}
}
    },
    computed:{
        Keys(){
            return this.keys;
        },
        Types(){
            let arr=[]
            this.keys.forEach(key=>{
                arr.push(Object.keys(this.data[key].types))
                Object.keys(this.data[key].types).forEach(bache=>{
                  this.DOTA[bache]=this.data[key].types[bache]
                })
            })
            return arr;
        },
        Details(val){
            return this.types[val];
        }
    },
    mounted(){
        this.keys=Object.keys(this.factors);
        this.data=this.factors;
    }
}
</script>

<style scoped>
#factors{
    width:100vw;
    overflow-x: hidden;
    padding-left:1vw;
    padding-right:1vw;
    margin-top:16px;
}
.headline{
    font-size:27px;
    font-family: 'Sofia';
    font-weight: 350;
    margin-left:1vw;
    color:rgb(0, 132, 123);
    height:fit-content
}
.factors1{
    font-family: 'Sofia';
    font-size:25px;
    font-weight: 400;
    color:rgb(0, 132, 123);
}
.info{
    font-family: 'Sans';
    font-size:20px;
    font-weight: 350;
    color:black;
}
.pairs{
    width:100vw;
    display: grid;
    grid-template-columns: repeat(2,50vw);
}
.left,.right{
    width:50vw;
}
.left h4{
    color:black;
    font-family: 'Sofia';
    font-family: 29px;
    font-weight: 350;
    text-align: center;
}
.right h4{
    color:rgb(0, 132, 123);
    font-family: 'Sofia';
    font-family: 29px;
    font-weight: 350;
    text-align: center;
}
.left h5{
    color:rgb(0, 132, 123);;
    font-family: sans-serif;
    font-family: 20px;
    font-weight: 300;
    text-align: center;
    width:46vw;
    margin-right:2vw;
}
.right h5{
    color:white;
    background:rgb(0, 132, 123);;
    font-family: sans-serif;
    font-family: 20px;
    font-weight: 300;
    text-align: center;
    width:46vw;
    margin-left:2vw;
}
</style>