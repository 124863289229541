import { render, staticRenderFns } from "./head-comp.vue?vue&type=template&id=57fe3564&scoped=true&"
import script from "./head-comp.vue?vue&type=script&lang=js&"
export * from "./head-comp.vue?vue&type=script&lang=js&"
import style0 from "./head-comp.vue?vue&type=style&index=0&id=57fe3564&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57fe3564",
  null
  
)

export default component.exports