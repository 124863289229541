
import HomePage from '../views/landing/main-page.vue'
import NotesPage from '../views/landing/notes-page.vue'
import Notification from '../views/landing/notifications-page.vue'
import ChatBot from '../views/chat-bot/bot-page.vue'
const routes= [
        
    
    {
        name: 'home',
        path: '/home',
        component: HomePage

    },
   
    

{
    name:'notifications',
    path:'/notifications',
    component:Notification
},

{
    name:'notes',
    path:'/notes-page',
    component:NotesPage
},  
   {
    name:'botPage',
    path:'/bot-page',
    component:ChatBot
   }
    
]

export default routes