<template>
    <div id="container">
        <h3>{{ title }}</h3>
        <h4>{{ date }}</h4>
        <p>{{ message }}</p>
    </div>
</template>

<script>
export default {
    computed:{
        title(){
            return this.$store.getters['self/getTheMessage']['title']
        },
        date(){
            return this.$store.getters['self/getTheMessage']['date']
        },
        message(){
            return this.$store.getters['self/getTheMessage']['message']
        }
    }
}
</script>

<style scoped>
#container{
    width:100vw;
    height: 100vh;
    overflow-x:hidden ;
    background-image: linear-gradient(to right, #0f0c29, #302b63, #24243e);
overflow-y:auto ;
}
#container::-webkit-scrollbar{
    display: none;
}
h3{
    text-align: center;
    color: white;
    font-family: 'Sofia';
    font-size: 34px;
    font-weight: 450;
    line-height: 30px;
    margin-top:16px;
    margin-bottom:4px;
}
h4{
    text-align: center;
    color: rgba(255, 255, 255, 0.552);
    font-family: 'Sofia';
    font-size: 24px;
    font-weight: 350;
    line-height: 20px;
    margin-bottom:18px;
    margin-top:20px;
}
p{
    text-align: center;
    line-height:14px;
    color: rgba(255, 255, 255, 0.815);
    font-family: 'Sofia';
    font-size: 21px;
    font-weight: 400;
    line-height: 30px;
    margin-top:6px;

}
</style>