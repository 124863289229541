<template>
    <div id="msgs">
        <div class="message" v-for="message,index in Messages" :key="index">
            <h3>{{ message.title }}</h3>
            <h4>{{ message.date }}</h4>
            <p>{{ message.message.slice(0,150) }}</p>
            <div class=options>
                <b-icon class="icon" id="open" icon="info-lg" @click="details({'title':message.title,'date':message.date,'message':message.message})"></b-icon>
                <h3 class="icon" id="delete" @click="removeMessage(index)">X</h3>
            </div>
        </div>
    </div>
</template>


<script>
export default {
 computed:{
    Messages(){
        return this.$store.getters['self/getMessages']
    }
 },
 methods:{
    details(val){
        this.$store.commit('self/setTheMessage',{value:val})
        this.$router.push({name:'DetailedMessage'})
    },
    removeMessage(val){
        this.$store.dispatch('self/removeMessage',{value:val})
    }
 }   
}
</script>


<style scoped>
#msgs{
    width: 100vw;
    margin-top:10px;
}
.message{
    margin-bottom:14px;
    width: 96vw;
    height: 307px;
    margin-left:2vw;
    border-radius: 20px;
    background-image: linear-gradient(to right, #0f0c29, #302b63, #24243e);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.611);
}
.message h3{
    overflow: hidden;
    max-height: 70px;
    text-align: center;
    font-size:28px;
    color: white;
    font-weight: 450;
    font-family:'Sofia';
}
.message h4{
    overflow: hidden;
    height: 40px;
    text-align: center;
    font-size:23px;
    color: rgba(255, 255, 255, 0.682);
    font-weight: 450;
    font-family:'Sofia';

}
.message p{
    overflow: hidden;
    max-height: 160px;
    text-align: center;
    font-size:20px;
    color: white;
    font-weight: 200;
    font-family:'Sofia';
    width: 90vw;
    margin-left:3vw;

}
.options{
    width: 100%;
    height: 40px;
    overflow: hidden;
    padding-left:30vw;
    padding-right:30vw;
    margin-top:5px;
}
#open{
float: left;
}
#delete{
    float: right;
    color:rgb(186, 214, 3);
    }
.icon{
    font-weight: 300;
    font-size:28px;
    color:rgb(186, 214, 3);
}
</style>