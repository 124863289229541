<template>
    <div id="history">
        <h3 class="headline">History</h3>
        <p>{{ History }} <span @click="Change">{{ Text }}</span></p>
    </div>
    
</template>

<script>
export default {
    props:[
'history'
    ],
data(){
    return{
        hist:'',
        txt:'Show more..'
    }
},
computed:{
    History(){
        return this.hist;
    },
    Text(){
        return this.txt;
    }
},
methods:{
    Change(){
        if(this.txt=='Show more..')
        this.showMore();
        else
        this.showLess();
    },
    showMore(){
         this.hist=this.history;
         this.txt='Show less..'
    },
    showLess(){
        this.hist = this.history.slice(0,300)
         this.txt='Show more..'
    }
},
mounted(){
        this.hist=this.history
        this.showLess()
    }    
}
</script>

<style scoped>
#about{
    width:100vw;
    overflow-x: hidden;
    padding-left:1vw;
    padding-right:1vw;
    margin-top:16px;
}
.headline{
    font-size:27px;
    font-family: 'Sofia';
    font-weight: 350;
    margin-left:1vw;
    color:rgb(0, 132, 123);
    height:fit-content
}
p{
    width:96vw;
    margin-left:1vw;
    margin-right:1vw;
    font-size:19px;
    font-weight:450;
    font-family:sans-serif;
    line-height: 22px;;
    color:black
}
span{
    color:rgb(0, 132, 123);
}
</style>