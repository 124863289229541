import HobImages from '../views/user/hob-images.vue'
import UserImage from '../views/contacts/user-images.vue'
import EditDetails from '../views/user/edit-details.vue'
import ProfilePage from '../views/user/profile-page.vue'
import NewRequests from '../views/contacts/requests-page.vue'
import ProfileView from '../views/contacts/profile-view.vue'
import SearchResults from '../views/contacts/search_results.vue'
import ContactAnlysis from '../views/user/Contact-Analysis.vue'
import UserProfile from '../views/user/user-profile.vue'
const routes=[
    {
        name:'hobImages',
        path:'hob-images',
        component:HobImages
    },{
        name:'UserImage',
        path:'/user-image/:hobby/:user',
        component:UserImage
    },{
        name:'editDetails',
        path:'/edit-details',
        component:EditDetails
    },
    {
        name: 'profile',
        path: '/profile',
        component: ProfilePage,
        
    },
    
    {
        name: 'requests',
        path: '/requests',
        component: NewRequests
    },
    {
        name:'ProfileView',
        path:'/profile-view/:id',
        component:ProfileView,
        props:true
    },
    {
        name:'friendsRes',
        path:'/friends-res',
        component:SearchResults
    },{
        name:'contactAnalysis',
        path:'/contact-analysis',
        component:ContactAnlysis
    },{
        name:'userProfile',
        path:'/user-profile',
        component:UserProfile
    }
]

export default routes