<template>
    <div id="container">
        <div id="header">
            <h2>{{ Personality }}</h2>
            <h3>Why should I take this test?</h3>
            <p>
                Because it teaches empathy. The DISC assessment is the best resource for an individual to understand how to cater their behavior to the situation. That’s why we use DISC over other like-minded tests such as Myers-Briggs, The Color Code, or the myriad of options available.
                The Myers-Briggs test provides a general understanding of how a person operates without giving specific advice or information. That is an essential lesson to learn for self growth and understanding, but it does not tell you how you will react and how to act appropriately in those situations. The DISC framework gives us a more flexible, adaptive assessment and tools to understand and communicate more effectively as you go about your busy, hectic life.

To employ empathy is to be able to view another perspective in an authentic way.
The more you learn about the other DISC types, the better you can understand how to remain approachable, how to be more assertive, how to lower your guard, and how to attract others to get your ideas heard.

Our mission is to be your coach for every conversation. Crystal can do that even if you don't have any knowledge of DISC, but for those that want to go a little further, Crystal’s assessments can have an entirely new meaning. We really believe in DISC and employ these tactics at Crystal HQ. The information provided on the following pages should help you experience success with DISC, once you have your results and DISC profile from our assessment tool.

You can use this model to help team building and recruitment, improve performance, resolve conflict, and much more. Here are some of the benefits:

More time and energy for productive activity. When teams aren't working well, huge amounts of time and energy are taken up with resolving conflict, dealing with performance issues, and remedying poorly communicated expectations. You'll have a lot more time to spend on productive activity if your people learn to work alongside one another better.
Better fit between team members and roles. People become dissatisfied when they aren't well matched to their jobs. When you understand a person's natural preferences, it is easier to fit her with a job she'll like and will be good at. This helps improve performance and engagement.
Improved understanding of customers and other stakeholders. There's potential for conflict and miscommunication when your team comes into direct contact with customers or other external stakeholders. Knowing their own preferences will help your team members understand how to serve their different customers more effectively.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            personality:''
        }
    },
    computed:{
        Personality(){
            return this.personality;
        }
    },
    mounted(){
this.personality=localStorage.getItem('test');
    }
}
</script>

<style scoped>
#container{
    width:100vw;
    overflow-x: hidden;
    height: 100vh;
}
#header{
    width:100vw;
    height:60px;
    background-image: linear-gradient(to right, #102216, #156039, #03ca8b);
}
#header h2{
    color: white;
    line-height: 60px;
    font-size: 38px;
    font-family: 'Sofia';
    text-align:center;
}
h3{
    text-align: center;
    font-weight: 400;
}
p{
    padding-left:1vw;
    padding-right:1vw;
    font-size:20px
}
</style>