import ChatPage from '../views/chats/chat-page.vue'
const routes=[
    {
        name: 'chat',
        path: '/chat/:to',
        component: ChatPage,
        props: true
    },
]

export default routes