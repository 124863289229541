export default{
    getMessages(state){
         return state.messages;
    },
    getCreate(state){
        return state.createMessage;
    },
    getTheMessage(state){
        return state.theMessage;
    },
    getVerified(state){
        return state.verified;
    }
}