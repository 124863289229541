<template>
    <div id="container">
     <div id="header">
        <h2>Notifications</h2>
     </div>
     
     <div class="noRes" v-if="Notifications.length <=0 ">
        No Notifications
    </div>
     <div v-else>
        <div class="notification" v-for="notification in Notifications" :key="notification.text">
            <div id="leftbound" class="color1"></div>
            <div id="content">
                <div class="image">
                    <img :src="notification.image" alt="image">
                </div>
                <div class="text">
                    <h3>{{ notification.text }}</h3>
                    <h4>{{ notification.date.day }} {{ notification.date.month }}</h4>
                </div>
            </div>
            <div id="rightbound" class="color1"></div>
         </div>
     </div>
    </div>
</template>

<script>
export default {
    computed:{
      Notifications(){
        return this.$store.getters['user/getNotifications']
      }  
    },
    async mounted(){
        await this.$store.dispatch('user/getNotifications')
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Sofia');
#container{
    width: 100vw;
    height: 90vh;
    overflow-x:hidden;
    overflow-y:auto ;
}
#container::-webkit-scrollbar{
    display: none;
}
#header{
    width: 100vw;
    height: 50px;
    background: rgb(255, 1, 94);
}
#header h2{
    line-height: 50px;
    font-size: 28px;
    color: white;
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: center;
    font-weight: 350;
}
.notification{
width: 100vw;
height:80px;
display: grid;
grid-template-columns: 1.5fr 97fr 1.5fr;
box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.492);
margin-top:10px;
margin-bottom:10px;
}
#leftbound{
    width:1.5vw;
    height:80px;
    float: left;
    margin-left:0px;
}
#rightbound{
    width:1.5vw;
    height:80px;
    float: right;
    margin-right:0px;
}
.color1{
    background-color: rgb(219, 7, 96);
}
.color2{
    background-color: rgb(162, 4, 201);
}
#content{
    width: 97vw;
    height: 80px;
    display: grid;
    grid-template-columns: 60px 80fr;
}
.image{
    width:60px;
    height:80px;
}
.image img{
    margin-left:4px;
    margin-top:3px;
    width:60px;
    height:60px;
    border-radius: 60px;
}
.text{
    width:74vw;
    margin-left:12px;
    height: 80px;
}
.text h3{
    font-size:18px;
    font-family: 'Sofia';
    color: black;
    font-weight: 350;
    width:72vw;
    height:fit-content;
    max-height:72px;
}
.text h4{
    margin-top:3px;
    float:right;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.745);
    font-weight: 330;
    font-family: 'Sofia';
}
.noRes{
    text-align: center;
    font-size:32px;
    font-weight: 350;
    font-family: Arial, Helvetica, sans-serif;
    margin-top:25vh;
    animation:animation ;
    animation-direction: alternate-reverse;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-play-state: running;
}
@keyframes animation{
    from{
        color:black
    }
    to{
        color:rgba(233, 8, 89, 1);

    }
}
</style>