<template>
    <div id="container">
        <div id="header">
            <h2>{{ Personality }}</h2>
            <h3>Why should I take this test?</h3>
            <p>You may find yourself moving through life unaware of thoughts, perceptions, or behaviors that can make up your personality. Others may feel mindful of how their belief systems and attitudes shape their actions. There are numerous ways in which you can grow in self-awareness, such as taking a free personality test.

                While personality tests are not foolproof and should still be tested for accuracy, they can be helpful as a starting point to get to know yourself better. The Myers-Briggs test, for example, can be taken online for free through many different websites.
                
                By taking this personality test, you may discover new information about yourself that helps you understand why you are the way you are. You may use this information to make positive changes in your life, enhance communication with friends or in relationships, and even develop better ideas for team collaboration. Remember to have fun while exploring your personality and expect to learn something new about yourself.
            
            
            
                A Myers-Briggs test may bring new or valuable insight into your life. The Myers-Briggs test, one of many assessments available, assesses your personality to help you understand yourself more deeply.

                You can learn about your potential strengths, weaknesses, blind spots, and personality traits. By completing this test, you can increase your self-awareness in different areas of your life and use what you learn to flourish. The description it provides can serve as a starting point for generating ideas for personal growth or finding better ways to communicate your unique perspective to others.
                Actively caring for yourself can be essential. One way to practice self-care is to seek new knowledge about who you are. The Myers-Briggs personality test provides the psychological preferences of an individual, including how they interpret the world around them and how they make decisions based on their personality profile. To complete the test, answer a series of questions that describes various scenarios or preferences. The resulting description can offer valuable insights into your unique traits and tendencies.

                The Myers-Briggs Type Indicator (MBTI) personality inventory is based upon the theory of psychological types described by Swiss psychiatrist Carl G. Jung. The principle of the theory is that even though human behaviors appear to be random, much of the variation is logical and expected due to potential inconsistencies in how individuals use their perception and judgment.
                
                Since people may differ in how they perceive information and reach conclusions, it can make sense that they correspondingly have different interests, reactions, values, motivations, and skills.
                
                One intent of understanding more about personality type for yourself or others is to better understand and appreciate differences between people. As all types are considered equal, there is no ‘best’ type. 
                
                The MBTI instrument sorts for preferences among personality types but does not measure trait, ability, or character. If you want to learn about these things, you might choose to participate in other types of self-exploration or take other online personality tests.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            personality:''
        }
    },
    computed:{
        Personality(){
            return this.personality;
        }
    },
    mounted(){
this.personality=localStorage.getItem('test');
    }
}
</script>

<style scoped>
#container{
    width:100vw;
    overflow-x: hidden;
    height: 100vh;
}
#header{
    width:100vw;
    height:60px;
    background-image: linear-gradient(to right, #102216, #156039, #03ca8b);
}
#header h2{
    color: white;
    line-height: 60px;
    font-size: 38px;
    font-family: 'Sofia';
    text-align:center;
}
h3{
    text-align: center;
    font-weight: 400;
}
p{
    padding-left:1vw;
    padding-right:1vw;
    font-size:20px
}
</style>