<template>
<div id="loader">
    <img  class="loader-image" :src="require('/src/assets/trans/evil.png')" alt="loader-image" v-if="image == 'villain'">
    <img  class="loader-image" :src="require('/src/assets/trans/hero.png')" alt="loader-image" v-if="image == 'hero'">
    <img  class="loader-image" :src="require('/src/assets/trans/sigma.png')" alt="loader-image" v-if="image == 'sigma'">
    <img  class="loader-image" :src="require('/src/assets/trans/alphamale.png')" alt="loader-image" v-if="image == 'alpha'">
    <img  class="loader-image" :src="require('/src/assets/trans/motivation.png')" alt="loader-image" v-if="image == 'motivation'">
    <img  class="loader-image" :src="require('/src/assets/trans/peace.png')" alt="loader-image" v-if="image == 'peace'">
    <img  class="loader-image" :src="require('/src/assets/trans/cupid.png')" alt="loader-image" v-if="image == 'love'">
    <img  class="loader-image" :src="require('/src/assets/trans/adrenaline.png')" alt="loader-image" v-if="image == 'adrenaline'">
    <img  class="loader-image" :src="require('/src/assets/trans/stoned.png')" alt="loader-image" v-if="image == 'stoned'">
    <img  class="loader-image" :src="require('/src/assets/trans/uplift.png')" alt="loader-image" v-if="image == 'uplift'">
    <img  class="loader-image" :src="require('/src/assets/trans/sensous.png')" alt="loader-image" v-if="image == 'sexy'">
    <img  class="loader-image" :src="require('/src/assets/trans/vibes.png')" alt="loader-image" v-if="image == 'vibes'">

</div>    
</template>


<script>
export default {
    computed:{
        image(){
            return this.$store.getters['trans/getTrans'];
        }
    }
}
</script>

<style scoped>
#loader{
    width:100vw;
    height: 100vh;
}
.loader-image{
    width:90vw;
    margin-left:5vw;
    height:500px;
    margin-top:15vh;
    animation: animateHeart 1.2s infinite;
}
@keyframes animateHeart {
   
    0% {
      transform:  scale(0.8);
    }
    5% {
      transform:  scale(0.9);
    }
    10% {
      transform:  scale(0.8);
    }
    15% {
      transform:  scale(1);
    }
    50% {
      transform:  scale(0.8);
    }
    100% {
      transform: scale(0.8);
    }
  }
</style>