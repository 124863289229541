<template>
    <div id="container">
        <assistant-comp></assistant-comp>
        <message-comp></message-comp>
        <message-box></message-box>
    </div>
</template>

<script>
import AssistantComp from '/src/components/chat-bot/assistant-com.vue'
import MessageBox from '/src/components/chat-bot/message-box.vue'
import MessageComp from '/src/components/chat-bot/message-comp.vue'
export default {
    components:{AssistantComp,MessageBox,MessageComp}
}
</script>

<style scoped>
#container{
    width:100vw;
    overflow-x:hidden ;
}
</style>