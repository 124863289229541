<template>
    <div id="container">
        <h2 id="logo">Sandesha</h2>
        <div id="option">
            <div id="option1" class="text-font" :class="[optionState==0?'active':'']" @click="switchReceived">Received</div>
            <div id="option2" class="text-font" :class="[optionState==1?'active':'']" @click="swithcSent">Sent</div>
        </div>
        <div class="contacts" v-if="optionState == 0" >
            <div class="noRes" v-if="ReceivedReq.length <=0 ">
                No requests
            </div>
            <div v-else style="height:fit-content">
                <request-comp v-for="request in ReceivedReq" :key = "request.username"
                :username="request.username" :image="request.image" :bio="request.bio"/>
            </div>
       </div>
       <div class="contacts" v-else>
        <div class="noRes" v-if="SentReq.length <=0 ">
            No requests
        </div>
            <div v-else style="height:fit-content">
                <sent-comp v-for="request in SentReq" :key = "request.username"
                :username="request.username" :image="request.image" :bio="request.bio"
                status="Pending" />
            </div>
       </div>
    </div> 
</template>

<script>
import RequestComp from '/src/components/contacts/request-comp.vue'
import SentComp from '/src/components/contacts/req-sent.vue'
export default {
    components:{
      
        RequestComp,
        SentComp
    },
    data(){
       return{
        option:0
       }
        // 0 coressponds to received and 1 coressponds to sent
    },
    computed:{
        optionState(){
            return this.option;
        },
        SentReq(){
           
            return this.$store.getters['user/get_requests'].sent
        },
        ReceivedReq(){
            
            return  this.$store.getters['user/get_requests'].received
        }
    },
    methods:{
        swithcSent(){
            this.option=1;
        },
        switchReceived(){
            this.option=0;
        }
    },
    async beforeMount() {
        await this.$store.dispatch('user/getRequests')
        
    },
   
}
</script>

<style scoped>
#container{
    overflow-y:auto;
}
#container::-webkit-scrollbar{
    display: none;
    visibility: hidden;
}
@import url('https://fonts.googleapis.com/css?family=Sofia');
#logo{
    position: absolute;
width: 87px;
height: 33px;
left: 6px;
top: 7px;

font-family: 'Sofia';
font-style: normal;
font-weight: 400;
font-size: 21px;
line-height: 33px;
/* identical to box height */


background: linear-gradient(95.76deg, #1708C2 2.71%, rgba(147, 12, 174, 0.94825) 54.21%, rgba(233, 8, 89, 0.89) 112.18%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
#friends{
    position: absolute;
width: 69px;
height: 25px;
left: 14px;
top: 55px;
font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 21px;
line-height: 25px;
/* identical to box height */


color: #000000;
}
.contacts{
    margin-top:25px;
}
#option{
    margin-top:14vw;
display: grid;
grid-template-columns: 30vw 30vw;

}
.text-font{
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
font-weight:450;
font-size:26px;
}
#option1{
    margin-left:20vw;
}
#option2{

    margin-left:28vw;
}
.active{
animation: switchAnimate 0.15s forwards
}
/*some animation styling*/
@keyframes switchAnimate{
    from{
        font-weight:450;
font-size:26px;
color: black;
    }
    to{
        color: rgba(233, 8, 89, 0.89);
font-weight:550;
font-size:28px;
    }
}
.noRes{
    text-align: center;
    font-size:32px;
    font-weight: 350;
    font-family: Arial, Helvetica, sans-serif;
    margin-top:25vh;
    animation:animation ;
    animation-direction: alternate-reverse;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-play-state: running;
}
@keyframes animation{
    from{
        color:black
    }
    to{
        color:rgba(233, 8, 89, 1);

    }
}
</style>