<template>
    <div id="container">
        <div id="header">
            <h2>{{ Personality }}</h2>
            <h3>Why should I take this test?</h3>
            <p>
                Learning more about one’s personality type can be advantageous. This can help individuals understand why they act the way they do. Personality tests can also be beneficial for mental health professionals.

According to Dr. Lee Phillips, “We must know how patients cope with their mental health issues of concern prior to starting psychotherapy. Facilitating personality tests provides psychotherapists with the framework for treatment planning and selecting a treatment modality that will benefit the patient.” 

Like certain other personality inventories, the HEXACO Personality Inventory is simple to administer and can be completed in relatively little time. 
Furthermore, the HEXACO Personality Inventory has been correlated to predict certain behaviors.

In one study, researchers found that the personality test helped determine differences in personality traits between various academic majors.3

Business/commerce majors, for instance, scored higher in Extraversion compared to their Physical Sciences/Math counterparts, while Arts, Humanities, and Social Sciences students exhibited more Openness to Experiences than other majors. 

One meta-analysis also found that those who scored higher in Openness to Experience and Honesty and Humility were more likely to exhibit pro-environmental behavior.4 
Personality Test Don't Consider Cultural Differences
“Personality tests will overlook any factors that are not being measured on the test.” For instance, “A person raised in Japan might act more deferential in an office setting than most Americans would, and might get judged as ‘more agreeable’ or as having ‘low confidence’ by an American test. But that does not reflect how they see themselves, or how most Japanese people would interpret their actions.”

Finally, the HEXACO Personality can “box” people into certain categories, according to Dr. Michele Goldman of the Hope for Depression Research Foundation. She also says, “People tend to hold onto the notion that their results are fixed and conclusive, but our personalities change and evolve over time.” 
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            personality:''
        }
    },
    computed:{
        Personality(){
            return this.personality;
        }
    },
    mounted(){
this.personality=localStorage.getItem('test');
    }
}
</script>

<style scoped>
#container{
    width:100vw;
    overflow-x: hidden;
    height: 100vh;
}
#header{
    width:100vw;
    height:60px;
    background-image: linear-gradient(to right, #102216, #156039, #03ca8b);
}
#header h2{
    color: white;
    line-height: 60px;
    font-size: 38px;
    font-family: 'Sofia';
    text-align:center;
}
h3{
    text-align: center;
    font-weight: 400;
}
p{
    padding-left:1vw;
    padding-right:1vw;
    font-size:20px
}
</style>