<template>
    <div id="create">
        <h4>Do you want to delete this image?</h4>
        <div id="buttons">
            <div class="button" id="btn1" @click="del">Delete</div>
            <div class="button" id="btn2" @click="cancel">Cancel</div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
          password:"",
          placeholder:'Enter password',
          wrong:false
        }
    },
    computed:{
        Placeholder(){
           return this.placeholder;
        },
        Wrong(){
            return this.wrong;
        }
    },
    methods:{
        // this function will verify the user first
        cancel(){
            this.$store.commit('user/setDelImage',{value:false})
            this.$store.commit('user/setImgDel',{value:''})
        },
        async del(){
            await this.$store.dispatch('user/removeImage')
            this.$store.commit('user/setDelImage',{value:false})
            this.$store.commit('user/setImgDel',{value:''})
        }
    }
}
</script>


<style scoped>
#create{
    width:96vw;
    height:200px;
    margin-left:2vw;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.892);
    z-index: 1;
    top:190px;
    border-radius: 20px;
    background: white;
    animation: animation;
    animation-duration:0.8s;
    animation-fill-mode: forwards;
    padding-top:10px;
    position:fixed;
    top:30vh;
}
#create h4{
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    font-family: 'Sofia';
    line-height: 18px;
    margin-top:10px;
}
#title{
    margin-top:15px;
}
#buttons{
    width:100vw;
margin-top:80px;
}
.button{
    background: rgb(251, 1, 76);
    width:120px;
    height:40px;
    border:20px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.64);
    color:white;
    font-weight: 350;
    font-size:28px;
    text-align: center;
    line-height: 40px;
    font-family: 'Sofia';
    float: left;
}
#btn1{
    margin-left:14vw;
}
#btn2{
    margin-left:4vw;
}
</style>