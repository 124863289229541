import { db } from '/src/firebase.js'

//import { collection, doc} from "firebase/firestore";
export default{
    async getMessages(){
        try{
             let msgs = localStorage.getItem('bot-chats')
             if(msgs){
                console.log(msgs.split(','))
             }
        }
        catch(err){
            let user = localStorage.getItem('user')
        let msgs = await db.collection('bot').doc(user).get();
        console.log(msgs.data())
        }
       
    },

}