export default{
   setSuggestions(state,val){
         state.suggestions.push(val.value)
     
   },
removeUser(state,payload){
  state.suggestions = state.suggestions.filter(val=>{
      return val.username != payload.value
   })
},
setRequests(state,payload){
   if(payload.type == 'sent'){
      state.requests['sent'].push(payload.value)
   }
   else if(payload.type == 'received'){
      state.requests['received'].push(payload.value)
   }
},
removeRequests(state,payload){
   if(payload.type == 'sent'){
      state.requests['sent'] = state.requests['sent'].filter(val=>{
         return val.username != payload.value
      })
   }
   else if(payload.type == 'received'){
      state.requests['received'] = state.requests['received'].filter(val=>{
         return val.username != payload.value
      })
   }
},
setFriends(state,payload){
   state.friends.push(payload.value);
},
removeFriend(state,payload){
   state.friends = state.friends.filter(val=>{
      return val.username != payload.value
   })
},
setNotifications(state,payload){
   state.notifications.unshift(payload.value)
},
setLoader(state,payload){
   state.loader = payload.value
},
setfriendsend(state,payload){
   state.friends_end = payload.value
},
setlastFriend(state,payload){
   state.last_friend=payload.value;
},
setHobby(state,payload){
   state.selectedHobby = payload.value;
   console.log(state.selectedHobby)
},
setHobImages(state,payload){
   state.hobImages.push(payload.value);
   console.log(state.hobImages)
},
emptyHobImages(state){
   state.hobImages = []
},
setDelImage(state,payload){
state.delImage=payload.value;
},
setImgDel(state,payload){
   state.imgDelete=payload.value;
},
updateImg(state,payload){
   state.hobImages=state.hobImages.filter(val=>{
      return val!=payload.value;
   })
},
setFriendsRes(state,payload){
   state.friends_res.push(payload.value);
},
clearFriendRes(state){
   state.friends_res=[];
},
set_gender_eval(state,payload){
   state.gender_eval=payload.value;
},
set_hobby_eval(state,payload){
   state.hobby_eval = payload.value;
}
}