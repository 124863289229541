<template>
    <div id="messages"></div>
</template>

<script>
export default {
    async mounted() {
        await this.$store.dispatch('chatbot/getMessages')
    },
}
</script>