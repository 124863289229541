import authStore from './auth/index'
import userStore from './user/index'
import transStore from './trans/index'
import selfMessageStore from './self-message/index'
import personality from './personality/index'
import chatbot from './chat-bot/index'
import group from './group/index'
import { Store } from 'vuex';
const store = new Store({
    modules: {
        auth:authStore,
        user:userStore,
        trans:transStore,
        self:selfMessageStore,
        personality:personality,
        chatbot:chatbot,
        group:group
    },
})
export default store;