<template>
    <div id="create">
        <h4>Add a message for your self</h4>
        <input type="text" id="title" v-model="title" placeholder="Title of message">
        <textarea name="messaage" id="message" cols="30" rows="10" style="resize:none" placeholder="Types your message" v-model="message"></textarea>
        <div id="button" @click="createMessage">Create Message</div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            title:'',
            message:''
        }
    },
    methods:{
        createMessage(){
            this.$store.dispatch('self/createMessage',{'title':this.title,'message':this.message})
            this.title=''
            this.message=''
            this.$store.commit('self/setCreate')
        }
    }
}
</script>


<style scoped>
#create{
    width:96vw;
    height:400px;
    margin-left:2vw;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.892);
    z-index: 1;
    position: absolute;
    top:150px;
    border-radius: 20px;
    background: white;
    animation: animation;
    animation-duration:0.8s;
    animation-fill-mode: forwards;
}
#create h4{
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    font-family: 'Sofia';
    line-height: 12px;
    margin-top:10px;
}
#title{
    margin-top:15px;
}
input,
input:focus,
input:default{
    outline: none;
    border: none;
    border-bottom:2px solid rgba(0, 0, 0, 0.542);
    width:80vw;
    margin-left:8vw;
    font-size:21px;
    color:black;
    margin-top:23px;
    font-family:'Sofia';
    font-weight: 400;
}
input::placeholder{
    font-size:21px;
    color:rgba(0, 0, 0, 0.489);
    margin-top:13px;
    font-family:'Sofia';
    font-weight: 400;
}
textarea,
textarea:focus,
textarea:default{
    width:90vw;
    margin-left:3vw;
    margin-top:15px;
    height: 210px;
    border-radius: 10px;
    font-size:21px;
    color:black;
    margin-top:23px;
    font-family:'Sofia';
    font-weight: 400;
}
textarea::placeholder{
    font-size:21px;
    color:rgba(0, 0, 0, 0.571);
    margin-top:21px;
    font-family:'Sofia';
    font-weight: 400;
    margin-left:10px;
}
@keyframes animation{
    from{
        transform:scale(0.65,0.65)
    }
    to{
        transform: scale(1,1);
    }
}
#button{
    width:60vw;
    margin-left:18vw;
    color: white;
    font-family: 'Sofia';
    font-size:32px;
    font-weight: 500;
    height: 60px;
    background-image: linear-gradient(to right, #0f0c29, #302b63, #24243e);
    text-align: center;
    line-height: 60px;
    border-radius: 10px;
    margin-top:10px;
}
</style>