import { db } from '/src/firebase.js'

import { collection, query, where, getDocs,orderBy,doc, getDoc,updateDoc, arrayUnion,documentId,arrayRemove} from "firebase/firestore";
export default{
    async getFriends({commit,dispatch}){
      if(localStorage.getItem('contactData')){
        let cache = JSON.parse(localStorage.getItem('contactData'))
        if(cache.length>0){
      return cache;
      }}
          try{
            if(localStorage.getItem('contacts') == '')
            return []
            let contacts = []
            let user_id = localStorage.getItem('user')
            const q = doc(db,'friends',user_id);
            let user = await getDoc(q)
            let friends = user.data().list
            friends.forEach(async(friend)=>{
             let user =  await dispatch('getUserData',{value:friend})
             if(user)
          commit('setFriends',{value:{username:user.username,image:user.profileImage,id:friend}})
          contacts.push({username:user.username,image:user.profileImage,id:friend})
          localStorage.setItem('contactData',JSON.stringify(contacts))
           })
           return 'ola'
          }
          catch(err){
            return ''
          }
    },
    async searchFriends({getters,commit},payload){

      //search operations
      commit('clearFriendRes')
      let friends = await getters['getFriends']
      friends.forEach(friend=>{
        if(friend.username==payload.value)
        commit('setFriendsRes',{value:friend})
      })
      
    },
    async removeFriend({commit,dispatch},payload){
      let user_name = payload.value;
      commit('removeFriend',{value:user_name})
      let receiver = localStorage.getItem('user')
      const q = query(collection(db,'users'),where('username', '==', user_name))
      let ans = await getDocs(q)
      let sender = ans.docs[0].id;
      let response = await dispatch('remove_friend_collection',{'sender':sender,'receiver':receiver})
      if(response){
        //sending notification
        await dispatch('push_notification',{user:localStorage.getItem('userName'),text:`${localStorage.getItem('userName')} removed you from connection`,image:localStorage.getItem('image')})
      }
    },
    async remove_friend_collection({dispatch},payload){
      try{
        let sender = payload.sender;
  let receiver = payload.receiver;
  await db.collection('friends').doc(sender).update({
    list:arrayRemove(receiver)
  })
  await db.collection('friends').doc(receiver).update({
    list:arrayRemove(sender)
  })
  let notif = `${localStorage.getItem('userName')} removed you from their connection`

    let up3 = await dispatch('push_notification',{user:receiver,text:notif,image:localStorage.getItem('profile')})
    if(!up3){return false;}
    return true;
      }
      catch(err){
        return false
      }
    },
    async getSearch(){

    },
    async newGroup(){

    },
    async getProfile(context,payload){
        try{
            let user = await db.collection('users').doc(payload.value).get()
            if(user)
            return user.data()
            else
            return ''
        }
        catch(err){
            return ''
        }
    },

    // need to modify this function later on when we have sufficient users
    async getSuggestions({commit,getters}){
      try{
        const q = query(collection(db,"users"),orderBy('username'))
          let users = await getDocs(q);
         if(users.docs.length>getters['get_suggestions']){
           users.forEach(user=>{
                  commit('setSuggestions',{value:{
                    'image':user.data().profileImage,
                    'username':user.data().username,
                  }})
          })
         }
        }
        catch(err){
         console.log(err)
         return ''
        }
    },
//
async sendRequest({dispatch},payload){
  try{
    // these are the things that we need
  // fetch the IDs of sender and receiver
  let senderId = localStorage.getItem('user')
  const q = query(collection(db,'users'),where('username', '==' ,payload.value))
  let user = await getDocs(q);
  let receiver = user.docs[0].id;
  
  
  // adding the request sent to the sender side
  const sender_ref = doc(db, "requests", senderId);
  await updateDoc(sender_ref, {
    sent: arrayUnion(receiver)
});
 // adding request received to receiver side
const receiver_ref = doc(db, "requests", receiver);
  await updateDoc(receiver_ref, {
    received: arrayUnion(senderId)
});
let notif = `${localStorage.getItem('userName')} sent you connection request`

    let up3 = await dispatch('push_notification',{user:receiver,text:notif,image:localStorage.getItem('profile')})
    if(!up3){return false;}
    return true;

  }
catch(err){
    return false;
}
},
async getID(context,payload){
  let name = payload.value
  const q = query(collection(db, "requests"), where("user", "==", name));
            let user = await getDocs(q);
            if (user.docs.length == 0)
                return ''
            else
               return user.docs[0].id;
},
//in prod remove from suggested as well
async removeUser(context,payload){
    let user_name = payload.value;
    context.commit('removeUser',{value:user_name})
},


async getRequests({commit,dispatch}){
  try{
    let user = localStorage.getItem('user')
  let req_ref = doc(db,'requests',user)
  let result = await getDoc(req_ref)
  let sent = result.data().sent
  let received = result.data().received
  
  sent.forEach(async(element) => {
   let user =  await dispatch('getUserData',{value:element})
   if(user)
   commit('setRequests',{value:{username:user.username,image:user.profileImage,
    bio:user.bio,},type:'sent'})
  });
  received.forEach(async(element) => {
    let user =  await dispatch('getUserData',{value:element})
    if(user)
    commit('setRequests',{value:{username:user.username,image:user.profileImage,
    bio:user.bio},type:'received'})
   });
  return 'ola!'
  }
  catch(err){
    return ''
  }
},
async getUserData(context,payload){
  try{
    const q = query(collection(db,'users'),where(documentId(), '==' , payload.value ))
  let user = await getDocs(q)
  return user.docs[0].data();
  }
  catch(err){
    return ''
  }
},
async declineRequest({commit,dispatch},payload){
  const q = query(collection(db, "users"), where("username", "==", payload.value));
    let user = await getDocs(q);
    let sender_details = user.docs[0].data()
  // update the request state
  commit('removeRequests',{type:'received',value:sender_details.username})
  // update requests collection of both users
  let user_id = localStorage.getItem('user')
  let sender = user.docs[0].id
  let up2 = await dispatch('updateRequests',{sender:sender,receiver:user_id})
    if(!up2){return false}
  // send a notification
  let notif = `${localStorage.getItem('userName')} declined your connection request`
  let up3 = await dispatch('push_notification',{user:sender,text:notif,image:localStorage.getItem('profile')})
    if(!up3){return false;}
    return true;
},

//later remove data from suggestions as well
async acceptRequest({dispatch},payload){
    
    // get the ids of both the users
     let user_id = localStorage.getItem('user')
    const q = query(collection(db,'users'),where('username', '==' ,payload.value))
    let user = await getDocs(q);
    let sender = user.docs[0].id;

    // update friends doc for both the users
    let up1 = await dispatch('updateFriends',{sender:sender,receiver:user_id})
    if(!up1){return false;}
    // // remove entries from request collection of both users
    let up2 = await dispatch('updateRequests',{sender:sender,receiver:user_id})
    if(!up2){return false}
    // notify the sender about this with time stamp
    let notif = `${localStorage.getItem('userName')} accepeted your connection request`

    let up3 = await dispatch('push_notification',{user:sender,text:notif,image:localStorage.getItem('profile')})
    if(!up3){return false;}
    return true;
},
async updateFriends(context,payload){
  try{
    let sender = payload.sender;
  let receiver = payload.receiver;
  await db.collection('friends').doc(sender).update({
    list:arrayUnion(receiver)
  })
  await db.collection('friends').doc(receiver).update({
    list:arrayUnion(sender)
  })
return true;
  }
  catch(err){
    console.log(err)
    return false;
  }
  

},
async updateRequests(context,payload){
  try{
    let sender = payload.sender;
  let receiver = payload.receiver;
  //removing the receiver's id from sent in sender collection
  await db.collection('requests').doc(sender).update({
    "sent":arrayRemove(receiver)
  })
  //removing the id of sender from the received in receiver collection
  await db.collection('requests').doc(receiver).update({
    "received":arrayRemove(sender)
  })
  // updating docs for viceversa case in case  both parties sent the request
  await db.collection('requests').doc(sender).update({
    "received":arrayRemove(sender)
  })
  await db.collection('requests').doc(receiver).update({
    "sent":arrayRemove(receiver)
  })
return true;
  }
  catch(err){
    console.log(err)
    return false;
  }
},
// while converting to pwa add a pop mechanism as well
async push_notification({dispatch},payload){
  try{
    let sender = payload.user;
  let date = await dispatch('getDate')
  await db.collection('notifications').doc(sender).update({
    "connections":arrayUnion({'image':payload.image,'text':payload.text,'date':{'day':date.day,'month':date.month}})
  })
  return true;
  }
  catch(err){
    console.log(err)
    return false;
  }
 
},

async unsend({commit,dispatch},payload){
  let sender = localStorage.getItem('user')
  const q = query(collection(db, "users"), where("username", "==", payload.value));
  let user = await getDocs(q);
  let receiver = user.docs[0].id;
  let receiver_details = user.docs[0].data();
  // removing from the local state
  commit('removeRequests',{type:'sent',value:receiver_details.username})
  // updating the database
  let up2 = await dispatch('updateRequests',{sender:sender,receiver:receiver})
    if(!up2){return false}
  // sending the notification
  let notif = `${localStorage.getItem('userName')} unsended connection request`
  let up3 = await dispatch('push_notification',{user:receiver,text:notif,image:localStorage.getItem('profile')})
    if(!up3){return false;}
    return true;
},
async getNotifications({commit,dispatch,getters}){
  try{
    let user = localStorage.getItem('user')
  const q = doc(db,'notifications',user)
  let notifs = await getDoc(q)
  let notifications = notifs.data().connections
  if(notifications.length>getters['getNotifications'].length){
    notifications.forEach(notif=>{
      commit('setNotifications',{value:notif})
  })
  }
  if(notifications.length>60)
  await dispatch('removeNotifications',{value:notifications,id:notifs.docs[0].id})
  return true;
  }
  catch(err){
    return false
  }
},
// this function will work if we have more then 50 norifications in the list
async removeNotifications(context,payload){
 try{
  let notifications = payload.value;
  notifications = notifications.splice(1,20); // will remove first 20 notifications
  // updating the document
  let docId = payload.id;
  await db.collection('notifications').doc(docId).update({
   "connections":notifications
 })


 }
 catch(err){
  console.log(err)
 }
},
async getDate(){
  let today = new Date()
  let month = today.getMonth();
  let day = today.getDate();

  let months = {
    1:'January',
    2:'February',
    3:'March',
    4:'April',
    5:'May',
    6:'June',
    7:'July',
    8:'August',
    9:'September',
    10:'October',
    11:'November',
    12:'December',

  }
  return {'day':day,'month':months[month]}
},
async showPopUP(context,payload){
    console.log(payload.value)
},
async getHobby({commit},payload){
  commit('setLoader',{value:true})
  let user = localStorage.getItem('user')
        let HobImage = await db.collection('hobbies').doc(user).get();
        // there are no keys present so upate the hobby field
        if(Object.keys(HobImage.data()).length==0){
          let Obj = {}
          Obj[payload]=[]
          await db.collection('hobbies').doc(user).update(Obj);
        }
        // there is some data
        else{
          // hobby field is present or not
          localStorage.setItem('Hobby',payload);
          commit('setHobby',{value:payload})
          try{
            localStorage.setItem('img-hobbies',HobImage.data()[payload])
            let images = HobImage.data()[payload]
            images.forEach(img=>{
              commit('setHobImages',{value:img})
            })
          }
          catch(err){
            console.log(err)
            localStorage.setItem('img-hobbies',[])
            let Obj = {}
          Obj[payload]=[]
          await db.collection('hobbies').doc(user).update(Obj);
            // in this state the coressponding image will be automatically empty
          }
        }
        commit('setLoader',{value:false})
},

async addImage(){
  let user = localStorage.getItem('user')
  let hobs = await db.collection('hobbies').doc(user).get()
  let images  = hobs.data()[localStorage.getItem('Hobby')]
  images.push({image:localStorage.getItem('latest-image'),likeCount:0,likes:[]})

let obj ={}
obj[localStorage.getItem('Hobby')]=images;
await db.collection('hobbies').doc(user).update(obj)
  localStorage.removeItem('latest-image')
},

async userImage(context,payload){
try{
  let imgs = await db.collection('hobbies').doc(payload.user).get()
  return imgs.data()[payload.hobby];
}
catch(err){
  console.log(err)
  return [];
  // in this state the coressponding image will be automatically empty
}},
async removeImage({getters,commit}){
  let image = getters['getImgDell']
  let images = await db.collection('hobbies').doc(localStorage.getItem('user')).get()
 images=images.data()[localStorage.getItem('Hobby')]
 images = images.filter(val=>{
  return val!=image;
 })
 let obj = {}
 obj[localStorage.getItem('Hobby')]=images;
 await db.collection('hobbies').doc(localStorage.getItem('user')).update(obj)
 commit('updateImg',{value:image})
},
async likeImage({dispatch},payload){
 try{
  let data = payload.value;
  let liked = payload.action;
  let user = payload.user;
  let hobby = localStorage.getItem('Hobby');
  let images = await db.collection('hobbies').doc(user).get()
  images = images.data()[hobby];
   let image = images.filter(val=>{
    return val.image = data.image;
   })
   image = image[0]
   let final_images =images.filter(val=>{
    return val.image != data.image;
   })
   let post_image = image.image;
   let post_likes = image.likeCount;
   // if the image is like by user
   if(liked == false){
    post_likes = post_likes > 0?post_likes-1:0;
    image.likes = image.likes.filter(val=>{
      return val != localStorage.getItem('user');
    })
final_images.push({image:post_image,likeCount:post_likes,likes:image.likes})

   }
   else{
    post_likes = post_likes+1;
    let likes = image.likes
    likes.push(localStorage.getItem('user'))
   final_images.push({image:post_image,likeCount:post_likes,'likes':likes})
   }
   let obj ={}
obj[localStorage.getItem('Hobby')]=final_images;
await db.collection('hobbies').doc(user).update(obj)
// sending the notification
let notif = `${localStorage.getItem('userName')} liked you image`
await dispatch('push_notification',{user:user,text:notif,image:post_image})
 }
 catch(err){
  console.log(err)
 }
},
async contactAnalysis({commit}){
  
  
 let genders = {'female':0,'male':0,'lesbian':0,'gay':0,'bi':0,'asexual':0}
 let Hobbies = {'Antiquing':0,'Aquarium Keeping':0,'Archery':0,
  'Asrtology':0,'Astronomy':0,'Baking':0,'Basketball':0, 'Beekeeping':0, 'Biking':0,
  'Billiards':0,'Bird watching':0,'Blogging':0,'Bodybuilding':0, 'Bowling':0, 'Brewing beer':0,
  'Cake decoration':0,'Calligraphy':0,'Camping':0,'Candle Making':0, 'Canyoneering':0, 'Cricket':0,
  'Gambling':0,'Chess':0,'Adult Coloring':0,'Cooking':0, 'Dancing':0, 'Drawing':0,'Fishing':0,
  'Football':0,'Fitness':0,'Gokarting':0, 'Golf':0, 'Graffiti Art':0,'Hiking':0,'Interior Design':0,
  'Ice Skating':0,'Jewelry making':0, 'Kayaking':0, 'Knitting':0,'Magic':0,'Martial Arts':0,
  'Meditation':0,'Mixology':0, 'Mountaineering':0, 'Music':0,'Networking':0,'Origami':0,
  'Painting':0 ,'Shooting':0, 'Photography':0, 'Trading':0,'Pottery':0,'Rappelling':0,
  'Rapping':0 ,'Reading':0, 'Beatboxing':0, 'Rock climbing':0,'Running':0,'Sailing':0,
  'Scuba Diving':0,'Self care':0, 'Sking':0, 'Skateboarding':0,'Standup':0,'Surfing':0,
  'Swimming':0,'Tennis':0, 'Anime&Manga':0, 'Travelling':0,'UFC':0,'Video Gaming':0,
  'Netflix&Chill':0,'Wine tasting':0, 'Writing':0, 'Yoga':0}
  let user_id = localStorage.getItem('user')
  const q = doc(db,'friends',user_id);
  let user = await getDoc(q)
  let friends = user.data().list
 for(let i=0;i<friends.length;i++){
  let friend = friends[i]
  let data = await db.collection('users').doc(friend).get()
     let dat = data.data()
     genders[dat.gender]=genders[dat.gender]+1;
     dat.hobbies.forEach(hobby=>{
      Hobbies[hobby]=Hobbies[hobby]+1;
     })
}
commit('set_gender_eval',{value:genders})
commit('set_hobby_eval',{value:Hobbies})
}
}