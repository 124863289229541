<template>
    <div id="container">
        <div id="header">
            <h2>{{ Personality }}</h2>
        </div>
        <about-comp :about="about"/>
<history-comp :history="history"></history-comp>
<factor-comp :factors="factors"></factor-comp>
    </div>
</template>


<script>
import AboutComp from '/src/components/personality/about-comp.vue'
import HistoryComp  from '/src/components/personality/history-comp.vue'
import FactorComp from '/src/components/personality/factors-comp.vue'
export default {
    components:{AboutComp,HistoryComp,FactorComp},
    data(){
        return{
            personality:'',
            about:`The NEO Personality Inventory test (NEO-PI) is a psychometric assessment tool widely used by employers as a key part of their recruitment process and increasingly as an on-job assessment tool.

Also known as the Big Five, OCEAN or CANOE, the personality model used by the NEO-PI test measures five aspects of the personality and has versions for adults, adolescents and children.
The Big Five evaluates personality by measuring—as the name suggests—five personality traits: openness to experience, conscientiousness, extraversion, agreeableness, and neuroticism, each on a continuous scale. Studies have shown it that it effectively predicts behavior, and the test is often used in academic psychological personality research. People who score higher in conscientiousness tend to work harder, for example, while more neurotic personalities are more prone to anxiety and depression.

Despite its scientific validity, and even with the contemporary fascination with personality tests, the Big Five is relatively unpopular outside of academia. A recent FiveThirtyEight article on the subject suggested that personality scientists haven’t effectively marketed the one credible personality test.`,
            history:`The development of NEO-PI began in the 1970s with psychologists Paul Costa and Robert McCrae’s investigation into how personality changed with age.

Using the recognized personality traits of neuroticism (N) and extraversion (E), they discovered a third personality trait, openness (O).

In 1978, they published the original version of the test based on these three personality traits, NEO-PI.

Further investigation by Costa and McCrae discovered two more personality traits, agreeableness (A) and conscientiousness (C).

In 1985, they published a manual for the test which included all five traits.

By 1992, Costa and McCrae had developed six facets for each of the five traits, totaling 30 facets in all.

Subsequently, they published the Revised NEO Personality Inventory (NEO-PI-R).

In 2002, the NEO-PI-R was tested on over 1,900 high school pupils.

The results of this investigation, and a further testing sample of adolescents, adults and middle school age pupils, led to the development of the NEO-PI-3.

Published in 2005, NEO-PI-3 is accompanied by a glossary of terms to make the test easier for candidates to understand.

This glossary and improvements made to the test mean that NEO-PI-3 is suitable for not only adults but also young people and individuals with disabilities.

While developing NEO-PI-3, Costa and McCrae also created a short version of NEO-PI-R, which they called NEO-FFI (Five-Factor Inventory) and is now known as the NEO-FFI-R.

Subsequent independent investigations by McCrae and other psychologists have generally agreed that the five-factor model works across all cultures, even where English is not their first language.

More history about the development of personality testing can be found here.

As part of the recruitment process, you will generally face the NEO-PI-3 or the NEO-PI-FFI versions of the test.

`
, factors:{
        'Opennes':{
            'about':'Openness (also referred to as openness to experience) emphasizes imagination and insight the most out of all five personality traits.1 People who are high in openness tend to have a broad range of interests. They are curious about the world and other people and are eager to learn new things and enjoy new experiences.People who are high in this personality trait also tend to be more adventurous and creative. Conversely, people low in this personality trait are often much more traditional and may struggle with abstract thinking.',
        'types':{'Opennes High':'Very creative,Open to trying new things,Focused on tackling new challenges,Happy to think about abstract concepts',
        'Opennes Low':'Dislikes change Does not enjoy new things Resists new ideas Not very imaginative Dislikes abstract or theoretical concepts'}}
    ,
    
        'Conscientiousness':{about:'Among each of the personality traits, conscientiousness is one defined by high levels of thoughtfulness, good impulse control, and goal-directed behaviors.1Highly conscientious people tend to be organized and mindful of details. They plan ahead, think about how their behavior affects others, and are mindful of deadlines.Someone scoring lower in this primary personality trait is less structured and less organized. They may procrastinate to get things done, sometimes missing deadlines completely.',
        types:{
            'Conscientiousness High':'Spends time preparing,Finishes important tasks right away,Pays attention to detail,Enjoys having a set schedule',
        'Conscientiousness Low':'Dislikes structure and schedules,Makes messes and doesnt take care of things,Fails to return things or put them back where they belong,Procrastinates important tasks,Fails to complete necessary or assigned tasks'
        }}
    ,
    
        'Extraversion':{about:'Extraversion (or extroversion) is a personality trait characterized by excitability, sociability, talkativeness, assertiveness, and high amounts of emotional expressiveness.1 People high in extraversion are outgoing and tend to gain energy in social situations. Being around others helps them feel energized and excited.People who are low in this personality trait or introverted tend to be more reserved. They have less energy to expend in social settings and social events can feel draining. Introverts often require a period of solitude and quiet in order to "recharge."',
        types:{
            'Extraversion High':'Enjoys being the center of attention,Likes to start conversations,Enjoys meeting new people,Has a wide social circle of friends and acquaintances,Finds it easy to make new friends,Feels energized when around other people,Say things before thinking about them',
        'Extraversion Low':'Prefers solitude,Feels exhausted when having to socialize a lot,Finds it difficult to start conversations,Dislikes making small talk,Carefully thinks things through before speaking,Dislikes being the center of attention'
        }}
    ,
    
        'Agreeableness':{about:'This personality trait includes attributes such as trust, altruism, kindness, affection, and other prosocial behaviors.1 People who are high in agreeableness tend to be more cooperative while those low in this personality trait tend to be more competitive and sometimes even manipulative.',
    types:{
        'Agreeableness High':'Has a great deal of interest in other people,Cares about others,Feels empathy and concern for other people,Enjoys helping and contributing to the happiness of other people,Assists others who are in need of help',
        'Agreeableness Low':'Takes little interest in others,Doesnt care about how other people feel,Has little interest in other peoples problems,Insults and belittles others,Manipulates others to get what they want'
    }
},
'Neuroticism':{about:'Neuroticism is a personality trait characterized by sadness, moodiness, and emotional instability.1Individuals who are high in neuroticism tend to experience mood swings, anxiety, irritability, and sadness. Those low in this personality trait tend to be more stable and emotionally resilient.',
    types:{
        'Neuroticism High':'Experiences a lot of stress,Worries about many different things,Gets upset easily,Experiences dramatic shifts in mood,Feels anxious,Struggles to bounce back after stressful events',
        'Neuroticism Low':'Emotionally stable,Deals well with stress,Rarely feels sad or depressed,Doesnt worry much,Is very relaxed'
    }
},
}        
}
    },
    computed:{
        Personality(){
            return this.personality;
        }
    },
    mounted(){
this.personality=localStorage.getItem('test');
    }
}
</script>

<style scoped>
#container{
    width:100vw;
    overflow-x: hidden;
    height: 100vh;
}
#header{
    width:100vw;
    height:60px;
    background-image: linear-gradient(to right, #102216, #156039, #03ca8b);
}
#header h2{
    color: white;
    line-height: 60px;
    font-size: 38px;
    font-family: 'Sofia';
    text-align:center;
}
</style>