<template>
    <div id="container">
        <div id="header">
            <h2>{{ Personality }}</h2>
            <h3>Why should I take this test?</h3>
            <p>
                So can knowing your personality type on the MBTI and other personality assessments really help? What good can these personality measures really do? Here's an overview of how knowing your personality type can benefit you.

Better Understand Other People
After taking the MBTI and seeing your results, you might have a better understanding of all the different reactions and perceptions that other people might have to the same situations. We all have a different way of seeing and interacting with the world.

No personality type is "better" than any other—just different. And each perspective brings something new and interesting to the table. 

People often fall into the trap of mistakenly believing that most other people share the same views, opinions, attitudes, and traits that they do.Having your own personal preferences highlighted and being able to glimpse at some of the traits that other people possess can be an eye-opener for many. 

Understanding some of your core personality traits as well as those of the people you are close to is also helpful in relationships. If, for example, you are an extrovert but your spouse is more of an introvert, you will be better able to spot the signs that your partner is getting exhausted and needs to take a break from socializing. By better knowing each other's personality traits, you can better respond to the needs of your loved ones and build stronger partnerships.

Identify Your Likes and Dislikes
Maybe you have always hated talking on the phone but never really understood why. Or perhaps you've always needed a little extra time to think about a problem before making a decision.

By learning more about where you lie on the extroversion/introversion and thinking/feeling continuums, you might be better able to understand why you prefer certain things and dislike others. This can come in handy when you are trying to make important decisions that might have an impact on the course of your life, such as choosing a college major.

Selecting a major and profession that is well-aligned with your personal preferences might mean that you end up being happier and more satisfied with your choice and your work in the long run.

Know Which Situations Are Ideal for You
Learning more about your personality type can also help you discover new ways to approach problems. If you discover that you tend to be high on introversion, you might take care in the future to give yourself plenty of time to become comfortable in a situation before you introduce yourself to a new coworker, for example.

Knowing what might work best for your type can give you new ideas on how to solve problems, deal with stress, cope with conflict, and manage your work habits.

Recognize Your Strengths and Weaknesses
Knowing what you are good at can be important in a wide variety of situations, whether you are picking a college major or thinking about running for a seat on your local school board. For example, if you know that you are an ISTJ (introverted, sensing, thinking, and judging) on the MBTI, you might recognize that certain aspects of your personality might qualify as strengths in some situations and weaknesses in others. While your strong organizational skills and detail-oriented personality can be a major strength in your work, it can sometimes trip you up in situations where you need to let other people take the reins.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            personality:''
        }
    },
    computed:{
        Personality(){
            return this.personality;
        }
    },
    mounted(){
this.personality=localStorage.getItem('test');
    }
}
</script>

<style scoped>
#container{
    width:100vw;
    overflow-x: hidden;
    height: 100vh;
}
#header{
    width:100vw;
    height:60px;
    background-image: linear-gradient(to right, #102216, #156039, #03ca8b);
}
#header h2{
    color: white;
    line-height: 60px;
    font-size: 38px;
    font-family: 'Sofia';
    text-align:center;
}
h3{
    text-align: center;
    font-weight: 400;
}
p{
    padding-left:1vw;
    padding-right:1vw;
    font-size:20px
}
</style>