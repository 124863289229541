<template>
    <div id="types">
        <h3 class="headline">Personality Types</h3>
        <div v-for="key,index in keys" :key="index" :class="{'box1':index%2==0,'box2':index%2!=0}">
            <h2 class="per-heading">{{ key.toUpperCase() }}</h2>
            <ul :class="{'lev1':index%2==0,'lev2':index%2!=0}">
                <li v-for="pap,index in catg[key]" :key="index">{{ pap.toUpperCase() }}  <br> <span class="about">{{ dat[pap].about }}</span><span class="value" style="margin-left:10px;">{{ dat[pap].values }}</span></li>
            </ul>
        </div>
    </div>
</template>


<script>
export default {
    props:['types'],
    data(){
        return{
            keys:[],
            personalities:[],
            dat:{},
            catg:{}
        }
    },
    mounted(){
        this.keys=Object.keys(this.types);
        this.keys.forEach(Ka=>{
            this.catg[Ka]=Object.keys(this.types[Ka])
            let val = Object.keys(this.types[Ka])
            val.forEach(value=>{
                this.personalities.push(value)
                this.dat[value]=this.types[Ka][value]
            })
        })
    }
}
</script>

<style scoped>
#types{
    width:100vw;
    overflow-x: hidden;
    padding-left:1vw;
    padding-right:1vw;
    margin-top:16px;
}
.headline{
    font-size:27px;
    font-family: 'Sofia';
    font-weight: 350;
    margin-left:1vw;
    color:rgb(0, 132, 123);
    height:fit-content
}
.box1{
    width:98vw;
    margin-right:0.5vw;
    padding-right:0.5vw;
    background: rgb(0, 132, 123);;
    box-shadow:2px 2px 4px rgba(0, 0, 0, 0.745);
    border-radius:15px;
    padding-top:8px;
}
.box1 h2{
    text-align: center;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color:white;
    font-size:28px;
    font-weight: 250;
}
.lev1 li{
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;;
    color:white;
    font-size:20px;
    font-weight: 200;

}
.box2{
    width:98vw;
    margin-right:0.5vw;
    padding-right:0.5vw;
    box-shadow:2px 2px 4px rgba(0, 0, 0, 0.745);
    border-radius:15px;
    padding-top:8px;
}
.box2 h2{
    text-align: center;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color:black;
    font-size:28px;
    font-weight: 250;
}
.lev2 li{
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;;
    color:black;
    font-size:20px;
    font-weight: 200;

}
.lev1 .about{
    font-size:18px;
    color:white;
    font-family: sans-serif;
    font-weight: 200;
line-break: 10px;;
}
.lev1 .value{
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color:white;
    font-size:24px;
    font-weight: 300;

}
.lev2 .about{
    font-size:18px;
    color:black;
    font-family: sans-serif;
    font-weight: 200;
line-break: 10px;;
}
.lev2 .value{
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color:black;
    font-size:24px;
    font-weight: 300;

}
</style>