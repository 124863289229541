import MoodLifter from '../views/trans/mood-lifter.vue'
import TransPage from '../views/trans/trans-page.vue'
import TransPlayer from '../views/trans/trans-player.vue'

const routes=[
    {
        name:'mood',
        path:'mood-lifter',
        component:MoodLifter
   },
   {
    name:'trans-page',
    path:'/trans-page',
    component:TransPage
},
{
    name:'trans-player',
    path:'/trans-player',
    component:TransPlayer
},
]

export default routes;