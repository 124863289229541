<template>
    <div id="container">
        <group-header :grpid="groupId"></group-header>
        <chat-comp></chat-comp>
        <chat-box></chat-box>
    </div>
</template>

<script>
import GroupHeader from '/src/components/groups/header-comp.vue'
import ChatComp from '/src/components/groups/chat-comp.vue'
import ChatBox from '/src/components/groups/chat-box.vue'
export default {
    components:{GroupHeader,ChatComp,ChatBox},
    computed:{
        groupId(){
            return this.$route.params.groupId;
        }
    },
    async mounted(){
        // will get the group name from route params and then will fetch data for this group and store in the local storage.
        let name = this.$route.params.groupId;
        await this.$store.dispatch('group/getGroup',{value:name})

        // fetch the grp ,data;
    }
}
</script>

<style scoped>

</style>