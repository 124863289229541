export default{
    setMessage(state,payload){
       state.messages.push(payload.value)
    },
    setCreate(state){
        state.createMessage = ! state.createMessage;
    },
    setTheMessage(state,payload){
        state.theMessage = payload.value
    },
    removeMsg(state,payload){
        state.messages = state.messages.filter(val=>{
            return val.message != state.messages[payload.value].message;
         })
    },
    toggleVerify(state){
        state.verified = !state.verified;
    }
}