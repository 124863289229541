import NewGroup from '../views/group/new-group.vue'

import GroupPage from '../views/group/group-page.vue'

const routes=[
    {
        name: 'new-group',
        path: '/new-group',
        component: NewGroup
    },
   
    
    {
        name: 'group',
        path: '/group/:groupId',
        component: GroupPage,
        props: true
    },
]
export default routes;