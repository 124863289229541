<template>
    <div id="sub-menu">
        <b-icon icon="three-dots-vertical"  font-scale="3" id="menu"  @click="toggleMenu"></b-icon>
<div id="content1" v-if="state">

<div class="elem" @click="forward('requests')">
<b-icon class="icon" icon="person-plus-fill" style="width: 30px; height: 30px;top:18px;"></b-icon>
<h2 class="text">Requests</h2>
</div>
<div class="elem" @click="forward('new-group')">
    <b-icon class="icon" icon="people-fill" style="width: 30px; height: 30px;top:18px;"></b-icon>
    <h2 class="text">New Group</h2>
</div>
<div class="elem" @click="forward('new-group')">
    <b-icon class="icon" icon="gear-wide-connected" style="width: 30px; height: 30px;top:18px;"></b-icon>
    <h2 class="text">Chat Settings</h2>
</div>
<div class="elem" @click="forward('mood')">
    <b-icon class="icon" icon="earbuds" style="width: 30px; height: 30px;top:18px;"></b-icon>
    <h2 class="text">Trans</h2>
    </div>
<div class="elem" @click="forward('personality')">
<b-icon class="icon" icon="person-check-fill" style="width: 30px; height: 30px;top:18px;" ></b-icon>
<h2 class="text">Personality</h2>
</div>


<div class="elem" @click="logoutHandler">
<b-icon class="icon" icon="power" style="width: 30px; height: 30px;top:18px;"></b-icon>
<h2 class="text">Logout</h2>
</div>
</div>

</div>
   
</template>


<script>
export default {
    data(){
        return{
            toggle:false
        }
    },
    computed:{
        state(){
            return this.toggle;
        }
    },
    methods:{
        toggleMenu(){
            this.toggle=!this.toggle;
        },
        forward(to){
            this.$router.push({name:to})
        },
        logoutHandler(){
            this.$store.dispatch('auth/logout');
            this.$router.push({name:'welcome'})
        }
        
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Sofia');
#menu{
position: fixed;
margin-left:90vw;
top:2px;
width:7vw;
font-weight: 600;
color:black;
}
#sub-menu{
    z-index: 1;
    height:fit-content;
    
}
#content1{
    margin-left:47vw;
    margin-top:1vh;
    height: fit-content !important;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.518);
    background: white;
    padding-top:20px;
    z-index: 1;
    animation:expand;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}
.elem{
    position: relative;
width: 48vw;
height:65px;
margin-left:2vw;
background: #FFFFFF;
}
.icon{
left: 8.33vw;
right: 8.33vw;
top: 8.33vw;
bottom: 8.33vw;
float: left;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.text{
max-width: 124px;
height: 23px;
margin-left: 5px;
margin-top:5px;
float: left;

font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 20px;
line-height: 23px;

color: #000000;
}
.elem:hover .text{
    color:#E90859;
}
.elem:hover .icon{
    animation: shake;
    animation-delay: .1s;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
}
@keyframes expand{
    from{
        width: 0px;
        height: 0px;
    }
    to{
        width: 50vw;
        height: 326px;
    }
}
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
</style>